<template>
	<div id="pos-root" class="pos-root">
		<orders-list-component
				ref="orders-list-component"
				:selected-order="selectedOrder"
				@updateOrder="updateOrder"
				:modal-opened="modalOpened"
		></orders-list-component>
		<menu-component
				:selectedOrder="selectedOrder"
		></menu-component>
		<selected-order-component
				ref="selected-order-component"
				:temp-order="selectedOrder ? selectedOrder : null"
				@updateOrder="updateOrder"
				section="POS"
		></selected-order-component>
	</div>
</template>

<script>
import SelectedOrderComponent from "@/components/pos/SelectedOrder";
import OrdersListComponent from "@/components/pos/OrdersList";
import MenuComponent from "@/components/pos/Menu";
import {settingsService} from "@/_api/settings.api";
import store from "@/store";
import getPosHelper from "@/_helpers/get-pos-helper";

export default {
	name: 'PosRootComponent',
	props: {},
	components: {
		MenuComponent,
		OrdersListComponent,
		SelectedOrderComponent
	},
	data() {
		return {
			selectedOrder: null,
			modalOpened: false,
			modalsOpened: [],
		}
	},
	computed: {
		authData() {
			return this.$store.state.auth
		},
	},
	created() {},
	mounted() {
		this.getSettings();
		getPosHelper(this.authData.posId);
		
		this.emmiter.on('toggleModals', (data) => {
			console.log(data);
			if (data.opened) {
				this.modalsOpened.push(data.type);
			} else {
				const index = this.modalsOpened.findIndex((i) => i === data.type);
				if (index !== -1) {
					this.modalsOpened.splice(index, 1);
				}
			}
			console.log(this.modalsOpened);
			this.modalOpened = data.opened;
		});
	},
	beforeUnmount() {
		this.emmiter.off('toggleModals');
	},
	watch: {},
	methods: {
		getSettings() {
			settingsService.getSettings().then((res) => {
				store.dispatch('setLegalAge', res.data);
			})
		},
		updateOrder(data) {
			this.selectedOrder = data;
		},
	}
}
</script>

<style lang="less" scoped>
#pos-root {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: nowrap;
	padding: 10px 10px 10px 0;
	height: 100%;
	overflow-y: hidden;
	:deep {
		#orders-list {
			height: 100%;
			width: 15%;
			// min-width: 258px;
		}
		#menu {
			height: 100%;
			width: 58%;
			// min-width: 1053px;
		}
		
		#selected-order {
			height: 100%;
			width: 27%;
			//min-width: 486px;
		}
	}
}

@media (max-width: 1850px) {
	#pos-root {
		:deep {
			#orders-list {
				width: 15%;
			}
			#menu {
				width: 54%;
			}
			#selected-order {
				width: 31%;
			}
		}
	}
}
@media (max-width: 1700px) {
	#pos-root {
		:deep {
			#orders-list {
				width: 15%;
			}
			#menu {
				width: 50%;
			}
			#selected-order {
				width: 35%;
			}
		}
	}
}
@media (max-width: 1500px) {
	#pos-root {
		:deep {
			#orders-list {
				width: 15%;
			}
			#menu {
				width: 45%;
			}
			#selected-order {
				width: 40%;
			}
		}
	}
}
@media (max-width: 1350px) {
	#pos-root {
		:deep {
			#orders-list {
				width: 20%;
			}
			#menu {
				width: 30%;
			}
			#selected-order {
				width: 50%;
			}
		}
	}
}
</style>
