const showImages = JSON.parse(localStorage.getItem('showImages'))
const businessDay = JSON.parse(localStorage.getItem('businessDay'))
const legalAge = JSON.parse(localStorage.getItem('legalAge'))
const initialState = {
	showImages: showImages, 
	businessDay: businessDay,
	legalAge: legalAge,
}

export const settings = {
	namespace: true,
	state: initialState,
	actions: {
		setShowImages({commit}, data) {
			localStorage.setItem('showImages', JSON.stringify(data));
			commit('SET_SHOW_IMAGES', data);
		},
		setBusinessDay({commit}, data) {
			localStorage.setItem('businessDay', JSON.stringify(data));
			commit('SET_BUSINESS_DAY', data);
		},
		setLegalAge({commit}, data) {
			commit('SET_LEGAL_AGE', data);
		}
	},
	mutations: {
		SET_SHOW_IMAGES(state, data) {
			state.showImages = data;
		},
		SET_BUSINESS_DAY(state, data) {
			state.businessDay = data;
		},
		SET_LEGAL_AGE(state, data) {
			let la = {
				ADDITION_LEGAL_AGE_TABACCO: 0, 
				ADDITION_LEGAL_AGE_ALCOHOL: 0,
			};
			data.forEach((o) => {
				la[o.key] = +o.value;
			});
			state.legalAge = la;
			localStorage.setItem('legalAge', JSON.stringify(state.legalAge));
		}
	}
}
