<template>
    <div id="modal-wrapper">
	    <modal-component ref="modal" @onModalClose="onModalClose">
		    <div id="cancel-order-modal" class="modal-container">
			    <div class="modal-header">
				    <div class="text">Order is not empty!</div>
				    <div class="text">Delete the order?</div>
			    </div>
			    <div class="modal-controls x2">
						<base-button text="no" classes="def-btn secondary-action-btn uppercase medium border-2" @onClick="close"/>
						<base-button text="yes" classes="def-btn delete-action-btn uppercase medium border-2" @onClick="cancelOrder"/>
			    </div>
		    </div>
	    </modal-component>
		<order-cancellation-notice-modal
			ref="order-cancellation-notice-modal"
			:selectedOrder="selectedOrder"
			@cancelOrder="cancelOrderCallback"
		>
		</order-cancellation-notice-modal>
    </div>

</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import OrderCancellationNoticeModal from "@/components/_modals/OrderCancellationNoticeModal";
import BaseButton from "@/components/_shared/Button.vue";

export default {
    name: "CancelOrderModal",
	components: {ModalComponent, OrderCancellationNoticeModal, BaseButton},
	props:{
		selectedOrder: {
			type: Object,
			required: true
		},
    },
	data() {
		return {}
	},
	emits: ['submitCancelOrder', 'onModalClose', 'cancelOrder'],
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.emit('toggleModals', {opened: false, type: 'CancelOrderModal'});
	},
    methods: {
		onModalClose() {
			this.$emit('onModalClose');
		},
        openModal() {
	        this.emmiter.emit('toggleModals', {opened: true, type: 'CancelOrderModal'});
	        this.$refs['modal'].show();
        },
        close() {
            this.$refs['modal'].close();
	        this.emmiter.emit('toggleModals', {opened: false, type: 'CancelOrderModal'});
        },
	    cancelOrderCallback(data) {
		    this.$emit('cancelOrder', {...this.selectedOrder, text: data.text});
	    },
		cancelOrder() {
			if (this.selectedOrder.items.some(el => el.status !== 1)) {
				this.$refs['order-cancellation-notice-modal'].openModal();
			} else {
				this.emmiter.emit('cancelOrder', this.selectedOrder);
				// this.$emit('cancelOrder', this.selectedOrder);
			}
		}
    }
}
</script>

<style scoped lang="less">
#modal-wrapper {
	#cancel-order-modal {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		height: 100%;
	}
}
</style>
