<template>
    <div id="menu" class="menu">
        <div class="controls-wrapper">
            <div class="section-title">
	            <div v-for="(i, index) of breadcrumbs"
	                 :class="{'active-breadcrumb': index === breadcrumbs.length - 1}" class="breadcrumbs">
		            <span class="breadcrumb-name">{{ i.name }}</span>
		            <span v-if="(breadcrumbs.length > 1 && index !== breadcrumbs.length - 1)" class="breadcrumb-separator">></span>
	            </div>
            </div>
	        <div class="controls">
						<base-button v-if="breadcrumbs.length === 1" :text="!showCategoryItems ? 'CATEGORIES' : 'MENU'" :classes="showCategoryItems ? 'def-btn main-action-btn uppercase medium border-2' : 'def-btn secondary-action-btn uppercase medium border-2'" @onClick="toggleShowedCategoryItems(undefined, true)"/>
		    
						<base-button v-if="!showImagesValue || (showImagesValue && showCategoryItems)" :imgSrc="listView ? 'menuGrid' : 'menuList'" :customStyles="'min-width: 60px; width: 60px; height: 60px; border: none'" classes="def-btn secondary-action-btn icon one square-btn" @onClick="listView = !listView"/>

						<!-- <div v-if="!showImagesValue || (showImagesValue && showCategoryItems)" class="square-btn" @click="listView = !listView">
			        <img :src="listView ? require('../../assets/img/menu_view_g.svg') : require('../../assets/img/menu_view_l.svg')" alt="list-type-change"/>
		        </div> -->
		        <refresh-btn-component :close="false" class="refresh-btn" @click="refreshData()" :customStyles="'border: none;'"></refresh-btn-component>
	        </div>
        </div>
        <search-bar-component
            @onInput="onInput"
            :placeholder="'Code, name, category, etc.'"
            :clearInput="clearInput"
            @onClear="onInputClear"
        >
        </search-bar-component>
	    <div :class="showImagesValue ? 'all-menu with-images' : 'all-menu no-images'">
		    <!---->
		    <div v-if="breadcrumbs.length > 1" class="category-changer" @click="getPrevCategory()">
			    <div class="category-changer-icon">
						<icon-component iconName="arrowLeft" />
				    <!--<img v-if="design === 'ecruise'" :src="showImagesValue ? require('../../assets/img/catgr_icon.svg') : require('../../assets/img/catgr_icon.svg')" alt="dinner"
				         class="square-btn-img"/>
				    <img v-if="design === 'margaritaville'" :src="showImagesValue ? require('../../assets/img/72category.svg') : require('../../assets/img/catgr_icon.svg')" alt="dinner"
				         class="square-btn-img"/>-->
				    <div class="description">{{
						    breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 1].name :
								    'All categories'
					    }}
				    </div>
			    </div>
		    </div>
		    <div class="loading" v-if="menuLoading">
					<icon-component iconName="loader" />
		    </div>
		    <div v-else :class="listView ? 'list' : 'list list_sq'" @scroll="menuListScrollHandle">
			    
			    <div v-for="(i, index) in (search.length ? filteredItems : showCategoryItems ? categories : menuItems)" :key="index" class="order"
			         @click="showCategoryItems ? getSingleCategory(i) : selectItem(i)">
				    <div v-if="showCategoryItems" class="category-item">
							<icon-component iconName="arrowRight" class="arrow-right" :style="i.all_children && i.all_children.length ? 'visibility:show':'visibility:hidden'" />

							<icon-component iconName="menuCategory" class="square-btn-img" />		
					    <hr v-if="!listView">
					    <div class="description">{{ i.name }}</div>
				    </div>
				    
				    <div v-else class="item">
					    <div v-if="i.price" class="price">${{ i.price.toFixed(2) }}</div>
					    <div :style="itemImage(i)"
					         :class="itemImage(i).backgroundImage.includes('base64') ? 'square-btn-img' : 'img'"></div>
					    <hr v-if="!listView">
					    <div class="description">{{ i.name }}</div>
				    </div>
			    </div>
		    </div>
        </div>
    </div>
	
	<add-edit-item-modal
		v-if="addEditItemModalRef"
		ref="addEditItemModalRef"
		@addEditItem="addItem"
		:selectedItem="selectedItem"
		:selected-order="selectedOrder"
		@onModalClose="onModalClose('addEditItemModalRef')"
	/>
	<confirmation-modal-component ref="confirmation-modal-component" :has-cancel-btn="false"/>
</template>

<script>
import SearchBarComponent from "@/components/_shared/SearchBar";
import ModalComponent from "@/components/_shared/Modal";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import RefreshBtnComponent from "@/components/_shared/RefreshButton";
import {menuService} from "@/_api/menu.api";
import AddEditItemModal from "@/components/_modals/AddEditItemModal";
import {usersService} from "@/_api/users.api";
import {settingsService} from "@/_api/settings.api";
import {formatDataService} from "@/_helpers/format-data";
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'MenuComponent',
	props: {
		selectedOrder: {
			type: Object,
			required: false
		}
	},
	emits: ['addItem'],
	components: {RefreshBtnComponent, ConfirmationModalComponent, ModalComponent, SearchBarComponent, AddEditItemModal, BaseButton, iconComponent},
	data() {
		return {
			menuLoading: false,
			selectedItem: null,
			selectedCategory: null,
			addEditItemModalRef: false,
			step: 1,
			breadcrumbs: [{
				id: null,
				name: 'All categories'
			}],
            menuItems: [],
            categories: [],
            items: [],
            search: '',
            filteredItems: [],
            isDeptCategory: false,
            depthCategory: [],
            clearInput: false,
            listView: false,
            showCategoryItems: true,
			// ADDITION_LEGAL_AGE_ALCOHOL: false,
			// ADDITION_LEGAL_AGE_TABACCO: false
        }
    },
    computed: {
			showImagesValue() {
			return this.$store.state.settings.showImages;
		},
	    design() {
		    return this.$store.state.UI;
	    },
	    posId() {
		    return this.$store.state.auth.posId
	    },
			legalAge() {
				return this.$store.state.settings.legalAge;
			},
    },
    created() {
		if (this.showImagesValue) {
			this.listView = false;
		}
    },
    mounted() {
	    this.menuItems = [];
	    this.apiMethodsWrapper();
    },
    beforeUnmount() {
    },
    methods: {
	    itemImage(i) {
		    return {
			    backgroundImage: `url(${!this.showImagesValue ? (!i.category.combo ?
						require('@/assets/img/global/menu_meal.svg') : require('@/assets/img/global/menu_item_combo.svg')) :
						(i.photo_url ||
						(this.design === 'ecruise' ? require('@/assets/img/global/menu_meal.svg') : require('@/assets/img/global/margaritaville__menu_category.svg')))})`
		    }
	    },
	    toggleShowedCategoryItems(changeTo, refresh) {
		    this.showCategoryItems = changeTo !== undefined ? changeTo : !this.showCategoryItems;
			
				this.search = '';
				this.filteredItems = [];
				this.clearInput = true;
				
				if (!this.showCategoryItems && this.showImagesValue) {
					this.listView = false;
				}
				
				if (refresh) {
					if (this.breadcrumbs.length === 1) {
						if (this.showCategoryItems) {
							this.getMenuCategory();
						} else {
							this.menuItems = [];
							this.getMenuItems();
							this.getComboMenuItems();
						}
					}
				}
	    },
	    onModalClose(ref) {
		    this[ref] = false;
	    },
	    handlePayload(item, isCombo) {
		    return {
			    ...item,
			    from_menu: true,
			    count: 1,
			    seat: null,
				status: 1,
				combo: isCombo,
				condiments_parsed: formatDataService.formatItemCondiments(item, true, isCombo),
				modifiers_parsed: formatDataService.formatItemModifiers(item, true, isCombo),
				orderable_id: item.id,
				orderable_type: isCombo ? 'App\\Models\\MenuCombo' : 'App\\Models\\MenuItem'
			}
			},
			openConfirmationToxicModal() {
				this.$refs['confirmation-modal-component'].show({
			    title: 'Restricted',
			    message: 'Payers has intoxication state.',
			    okButton: 'OK',
		    })
	    },
	    openConfirmationModal() {
		    this.$refs['confirmation-modal-component'].show({
			    title: 'Restricted',
			    message: 'Ordering this menu item has age restrictions.',
			    okButton: 'OK',
		    })
	    },
			addItem(data) {
				this.emmiter.emit('addItem', data);
				// this.$emit('addItem', data);
			},
			selectItem(i) {
				if (this.selectedOrder) {
					let tt = (i.legal_age_class || (i.items && i.items.length && i.items.some((o) => {
						return o.legal_age_class
					})));
					if (!this.selectedOrder.payers.length && tt) {
						this.openConfirmationModal();
						return
					}
					if ((i.legal_age_class === 1 && this.selectedOrder.payers[0].age < this.legalAge.ADDITION_LEGAL_AGE_ALCOHOL)
							|| (i.legal_age_class === 2 && this.selectedOrder.payers[0].age <
									this.legalAge.ADDITION_LEGAL_AGE_TABACCO)) {
						this.openConfirmationModal();
						return
					}
					if (this.selectedOrder.payers.length && i.legal_age_class === 1) {
						usersService.getSinglePosUser(this.selectedOrder.payers[0].id).then((res) => {
							if (res) {
								if (res.intoxication_state) {
									this.openConfirmationToxicModal();
								} else {
									this.selectedItem = i;
									this.openAddNewOrderModal();
								}
							}
						})
					} else {
						this.selectedItem = i;
						this.openAddNewOrderModal();
					}
				}
			},
	    onInputClear() {
		    this.search = '';
		    this.clearInput = true;
	    },
			onInput(e) {
				this.debounceSearch(e);
			},
			debounceSearch(e) {
				let targetArr = this.showCategoryItems ? this.categories : this.menuItems;
				this.search = e.target.value;
				this.filteredItems = targetArr.filter(el => el.name.toLowerCase().includes(this.search.toLowerCase()));
			},
			getMenuItems() {
				this.menuLoading = true;
					return new Promise((resolve, reject) => {
						menuService.getMenuItems({
							page: 1,
							per_page: 1000,
							search: this.search
						}).then(res => {
							if (res.data) {
								res.data.forEach(el => {
									this.menuItems.push(this.handlePayload(el, false));
								});
							}
						}).catch((err) => {
						}).finally(() => {
							resolve();
							this.menuLoading = false;
						})
					})
			},
	    getComboMenuItems() {
		    this.menuLoading = true;
            return new Promise((resolve, reject) => {
	            menuService.getSingleComboCategory({
		            page: 1,
		            per_page: 1000,
		            search: this.search
	            }).then(res => {
		            if (res.data) {
			            res.data.forEach(el => {
				            this.menuItems.unshift(this.handlePayload(el, true));
			            });
		            }
	            }).catch((err) => {
		           
	            }).finally(() => {
		            resolve();
		            this.menuLoading = false;
	            })
            })
	    },
	    getPrevCategory() {
		    if (this.breadcrumbs.length > 2) {
			    this.breadcrumbs.pop();
			    this.getSingleCategory(this.breadcrumbs[this.breadcrumbs.length - 1]);
		    } else {
			    if (this.breadcrumbs.length > 1) {
				    this.breadcrumbs.pop();
			    }
			    this.getMenuCategory(true);
			    this.toggleShowedCategoryItems(true);
		    }
	    },
	    getMenuCategory(refresh) {
		    this.menuLoading = true;
		    return new Promise((resolve, reject) => {
			    this.categories = [];
			    if (refresh) {
				    this.search = '';
				    this.clearInput = true;
			    }
			    menuService.getMenuCategories({
				    page: 1,
				    per_page: 1000,
				    search: this.search
			    }).then(res => {
				    if (res.data) {
					    res.data.forEach(el => {
						    this.categories.push(el)
					    });
				    }
			    }).catch((err) => {
				   
			    }).finally(() => {
				    resolve();
				    this.menuLoading = false;
			    })
		    })
	    },
			getSingleCategory(data, refresh) {
				this.menuLoading = true;
				this.selectedCategory = data;
				this.clearInput = true;
				this.categories = [];
				this.depthCategory = [];
				this.isDeptCategory = false;
				this.items = [];
				this.menuItems = [];
				if (data.combo) {
					menuService.getSingleComboCategory({
						menu_category: data.id,
						search: '',
						order_by: '',
						sort: '',
						page: 1,
						per_page: 1000
					}).then((res) => {
						res.data.forEach(el => {
							this.menuItems.push(this.handlePayload(el, true));
						})
						this.toggleShowedCategoryItems(false);
						if (!refresh) {
							if (!this.breadcrumbs.some(el => el.id === data.id)) {
								this.breadcrumbs.push({
									id: data.id,
									name: data.name,
									combo: data.combo
								})
							}
							
						}
					}).catch((err) => {
					
					}).finally(() => {
						this.menuLoading = false;
					})
				} else {
					menuService.getSingleCategory(data.id).then(res => {
						if (res.items_count === 0) {
							res.all_children.forEach(el => {
								this.categories.push(el);
							});
							this.toggleShowedCategoryItems(true)
						} else {
							res.items.forEach(el => {
								this.menuItems.push(this.handlePayload(el, false));
							})
							this.toggleShowedCategoryItems(false);
						}
						if (!refresh) {
													if (!this.breadcrumbs.some(el => el.id === res.id)) {
															this.breadcrumbs.push({
																	id: res.id,
																	name: res.name
															})
													}
							
						}
					}).catch((err) => {
					
					}).finally(() => {
						this.menuLoading = false;
					})
				}
			},
	    refreshData() {
		    if (this.breadcrumbs.length === 1) {
			    if (this.showCategoryItems) {
				    this.getMenuCategory(true);
			    } else {
				    this.menuItems = [];
				    this.getMenuItems();
				    this.getComboMenuItems();
			    }
		    } else {
			    this.getSingleCategory(this.breadcrumbs[this.breadcrumbs.length - 1]);
		    }
	    },
	    openAddNewOrderModal() {
		    this.addEditItemModalRef = true;
		    this.$nextTick(() => {
			    this.$refs['addEditItemModalRef'].openModal();
		    })
	    },
	    apiMethodsWrapper() {
			Promise.all([this.getMenuItems(), this.getComboMenuItems(), this.getMenuCategory()]).then(() => {
				this.menuLoading = false;
			})
	    }
    },
	watch: {}
}
</script>

<style lang="less" scoped>
#menu {
    /*height: 100%;
    // width: 50%;
	width: 100%;
    min-width: 1053px;*/
    .controls-wrapper {
        min-height: 60px;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
	    box-sizing: border-box;
	    padding: 0 10px 0 5px;
        // .left {
        //     display: flex;
        //     flex-wrap: nowrap;
        //     justify-content: space-between;
        //     align-items: center;
        // }

        .controls {
            display: flex;
            flex-direction: row;
            gap: 12px;

            .def-btn {
                height: 60px;
            }
        }

        .section-title {
            // font-size: 14px;
            // line-height: 19px;
            // color: #424242;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .breadcrumbs {
                font-size: 14px;
                color: #424242;
                font-weight: 400;
                cursor: pointer;
                display: flex;

                &.active-breadcrumb {
                    font-weight: 700;
                }

                // &:first-child {
                //     text-transform: uppercase;
                // }

                .breadcrumb-separator {
                    padding: 0 5px;
                }
            }

            /*button {
                border: none;
                margin: -3px;
                background-color: transparent;
            }*/
        }
    }
	:deep(#search-bar) {
		margin-bottom: 10px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 10px 0 5px;
	}
	.all-menu {
		&.with-images {
			.list {
				&.list_sq {
					grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
					.order {
						height: 132px;
						min-width: 144px;
						padding: 0;
						border-radius: 4px;
						.category-item {
							flex-wrap: wrap;
							height: 100%;
							.price {}
							.arrow-right {
								order: 0;
								position: initial;
								margin: 0 0 0 auto;
							}
							.square-btn-img {
								width: 100%;
								height: 72px;
								position: initial;
								background-size: contain;
							}
							hr {
								display: none;
							}
							.description {
								margin: 0;
								width: 100%;
								padding: 0 5px;
							}
						}
						.item {
							flex-wrap: wrap;
							.price {
								all: unset;
								width: 100%;
							}
							.img {
								width: 100%;
								height: 72px;
								position: initial;
								background-size: contain;
								background-position: center;
							}
							.square-btn-img {
								width: 100%;
								height: 72px;
								position: initial;
								background-size: contain;
								background-position: center;
							}
							hr {
								display: none;
							}
							.description {
								margin: 0;
								width: 100%;
								padding: 0 5px;
							}
						}
					}
				}
			}
		}
		.category-changer {
			border-radius: 4px;
			background-color: #fff;
			box-shadow: 0 1px 3px -1px #000;
			//height: 110px;
			width: calc(100% - 15px);
			height: 40px;
			display: flex;
			cursor: pointer;
			margin-bottom: 10px;
			justify-content: flex-start;
			align-items: center;
			position: relative;
			overflow: hidden;
			padding: 0 10px 0 10px;
			margin-left: 5px;
			.category-changer-icon {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;
				
				.square-btn-img {
					width: 24px;
					height: 24px;
				}
				
				.description {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					color: #1C282D;
				}
			}
			&:before {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
				content: '';
				background-color: #3f8ec5;
				width: 4px;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 1;
				transition: opacity 500ms;
			}
		}
		.list {
			overflow-y: scroll;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-start;
			align-content: flex-start;
			height: calc(100vh - 240px);
			padding: 0 10px 0 5px;
			box-sizing: border-box;
			&::-webkit-scrollbar {
				display: none;
			}
			&.list_sq {
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));
				grid-gap: 6px;
				.order {
					position: relative;
					margin: 0;
					//width: 22%;
					border-radius: 4px;
					min-width: 118px;
					height: 84px;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: center;
					justify-content: space-between;
					gap: 8px;
					padding: 0 12px;
					box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.1), 0 1.6px 3.6px rgba(0, 0, 0, 0.14);
					.category-item, .item {
						.square-btn-img, .img {
							position: absolute;
							top: 5px;
							left: 5px;
						}
						.arrow-right, .price {
							position: absolute;
							top: 5px;
							right: 5px;
						}
						.description {
							text-align: center;
							padding: 0;
							margin-top: 30px;
						}
						
					}
				}
			}
			
			.order {
				margin: 0 0 6px 0;
				width: 100%;
				height: 40px;
				display: flex;
				background-color: #fff;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				cursor: pointer;
				gap: 8px;
				border-radius: 4px;
				padding: 0 12px;
				box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.1), 0 1.6px 3.6px rgba(0, 0, 0, 0.14);
				.category-item, .item {
					hr {
						position: absolute;
						top: 25px;
						left: 0;
						width: 100%;
						height: 2px;
						background-color: #ECF4F9;
						border: none;
					}
					.square-btn-img, .img {
						order: 0;
						width: 24px;
						height: 24px;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
					}
					.description {
						order: 1;
						width: 100%;
						text-align: left;
						white-space: nowrap;
						overflow: hidden !important;
						text-overflow: ellipsis;
						padding: 0 10px;
						font-size: 14px;
						color: #1C282D;
					}
				}
				.category-item {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: nowrap;
					.square-btn-img {
						// width: 100%;
					}
					.arrow-right {
						order: 2;
						width: 24px;
						height: 24px;
						// transform: rotate(180deg);
					}
				}
				.item {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: nowrap;
					.price {
						width: max-content;
						order: 2;
						text-align: right;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						color: #1C282D;
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	#menu {
		//min-width: 350px;
	}
}
@media (max-width: 850px) {
	#menu {
		//min-width: 480px;
	}
}
</style>

