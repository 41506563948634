<template>
	<div id="modal-wrapper">
		<modal-component
			ref="modal"
			:has-keyboard="true"
            :show-keyboard="true"
            @clickOnKey="setNoticeText"
		>
			<div id="order-cancellation-notice-modal" class="modal-container">
				<div class="modal-header">
					Cancel order
				</div>
				<h2 class="sub-title">
					you must add a commment because the order is processed already
				</h2>
				<div class="content">
					<textarea class="input" v-model="noticeText" placeholder="Input your comment here..."></textarea>
				</div>
				<div class="modal-controls x2">
					<base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
					<base-button text="ok" :isDisabled="!noticeText.length" classes="def-btn main-action-btn uppercase medium border-2" @onClick="cancelOrder"/>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import BaseButton from "@/components/_shared/Button.vue";

export default {
	name: 'OrderCancellationNoticeModal',
	components: {ModalComponent, BaseButton},
	props: {
		selectedOrder: {
			type: Object,
			required: true
		},
	},
	emits: ['cancelOrder'],
	data() {
		return {
			noticeText: ''
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	methods: {
        openModal() {
            this.$refs['modal'].show();
        },
		close() {
			this.noticeText = '';
			this.$refs['modal'].close();
		},
		cancelOrder() {
			if (this.noticeText.length) {
				let data = {
					id: this.selectedOrder.id,
					text: this.noticeText
				}
				this.emmiter.emit('cancelOrder', data);
				this.$emit('cancelOrder', data);
			}
		},
		setNoticeText(e) {
			if (e === undefined) {
				this.noticeText = this.noticeText.substring(0, this.noticeText.length - 1);
			} else {
				this.noticeText += e;
			}
		}
	}
}
</script>

<style lang="less">
#modal-wrapper {
	#order-cancellation-notice-modal {
		width: 400px;
		.sub-title {
			font-weight: 600;
			color: #1C282D;
			font-size: 12px;
			margin-bottom: 30px;
		}
		.content {
			margin-bottom: 20px;
			.input {
				width: 100%;
				border: 1px solid gainsboro;
				resize: none;
				height: 120px;
				border-radius: 4px;
				font-size: 16px;
				color: #202020;
				box-sizing: border-box;
				padding: 10px;
				&:focus {
					outline: none;
				}
			}
		}
	}
}
</style>
