<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" @onModalClose="this.$emit('onModalClose')">
			<div id="discount-details-modal" class="modal-container">
				<div class="modal-header">
					Discount details
				</div>
				<div class="discounts-table">
					<table>
						<tr>
							<th>ITEM</th>
							<th>AMOUNT</th>
						</tr>
						<tr v-for="i in this.selectedOrder.items">
							<td><span>{{i.name}}</span></td>
							<td>{{'$' + itemDiscount(i) }}</td>
						</tr>
					</table>
					<div class="total">
						TOTAL DISCOUNT: <span>${{ totalDiscount }}</span>
					</div>
				</div>
				<div class="modal-controls x1">
					<base-button text="ok" classes="def-btn main-action-btn uppercase medium border-2" @onClick="closeModal()"/>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import BaseButton from "@/components/_shared/Button.vue";

export default {
	name: 'DiscountDetailsModalComponent',
	props: {
		selectedOrder: {
			default: null,
			required: true
		}
	},
	components: {ModalComponent, BaseButton},
	data() {
		return {}
	},
	computed: {
		design() {
			return this.$store.state.UI;
		},
		itemsWithDiscount() {
			return this.selectedOrder.items.filter((o) => {return o.discount});
		},
		totalDiscount() {
			let totalDiscount = this.selectedOrder.subtotal - this.selectedOrder.total;
			return totalDiscount.toFixed(2);
		},
	},
	created() {},
	mounted() {},
	beforeUnmount() {
		this.emmiter.emit('toggleModals', {opened: false, type: 'DiscountDetailsModal'});
	},
	methods: {
		itemDiscount(item) {
			let condimentsDiscount = 0;
			if (item.condiments && item.condiments.length) {
				item.condiments.forEach((o) => {
					if (o.count > 0) {
						condimentsDiscount += o.discount;
					}
				})
			} else {
				if (item.items && item.items.length) {
					item.items.forEach((o) => {
						if (o.condiments && o.condiments.length) {
							o.condiments.forEach((oo) => {
								if (oo.count > 0) {
									condimentsDiscount += oo.discount;
								}
							});
						}
					});
				}
			}
			return (item.total_amount - item.total_amount_with_discount + condimentsDiscount).toFixed(2);
		},
		openModal() {
			this.emmiter.emit('toggleModals', {opened: true, type: 'DiscountDetailsModal'});
			this.$refs['modal'].show();
		},
		closeModal() {
			this.emmiter.emit('toggleModals', {opened: false, type: 'DiscountDetailsModal'});
			this.$refs['modal'].close();
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
#modal-wrapper {
	#discount-details-modal {
		width: 600px;
		.discounts-table {
			width: 100%;
			padding: 0 10%;
			box-sizing: border-box;
			margin-bottom: 40px;
			max-height: 400px;
			overflow-x: auto;
			table {
				width: 100%;
				table-layout: fixed;
				margin: 0;
				border-collapse: collapse;
				box-sizing: border-box;
				position: relative;
				margin-bottom: 20px;
				tr {
					&:first-child {
						border-top: 1px solid #e9ecef;
					}
					&:not(:last-child) {
						border-bottom: 1px solid #e9ecef;
					}
					&:last-child {
						border-bottom: 3px solid #e9ecef;
					}
					th {
						font-weight: 500;
						padding: 10px 0;
						&:nth-child(1) {
							width: 70%!important;
							text-align: left;
						}
						&:nth-child(2) {
							width: 30%!important;
							text-align: right;
						}
					}
					td {
						padding: 10px 0;
						&:nth-child(1) {
							width: 70%;
							text-align: left;
							span {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 3;
								line-clamp: 3;
								-webkit-box-orient: vertical;
							}
						}
						&:nth-child(2) {
							width: 30%!important;
							text-align: right;
						}
					}
				}
			}
			.total {
				text-align: right;
				font-weight: bold;
				text-transform: uppercase;
				span {
					margin-left: 20px;
				}
			}
		}
	}
}
</style>
