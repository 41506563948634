<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" :has-keyboard="true" :show-keyboard="!showCommentModal && !detailsMode" @clickOnKey="click" @onModalClose="this.$emit('onModalClose')">
			<div id="waiter-modal" class="modal-container">
				<div class="modal-header">{{ modalTitleText }}</div>
				<div class="selected-user" v-if="!selectedUser">
					<div class="img"><img :src="require('@/assets/img/bgs/nophoto.svg')" alt="user-image"></div>
					<div class="not-selected-user-text">Not specified yet</div>
				</div>
				<div class="selected-user" v-else>
					<div class="img"><img :src="selectedUser.photo ? 'data:image/png;base64,' + selectedUser.photo : require('@/assets/img/bgs/nophoto.svg')" alt="user-image"></div>
					<div class="info">
						<div class="label">Name</div>
						<div class="name">{{setWaiter ? selectedUser.name : `${selectedUser.first_name ? selectedUser.first_name : ''} ${selectedUser.last_name}`}}</div>
						<div class="label">Cabin</div>
						<div class="cabin">{{calcCabin}}</div>
					</div>
					<button v-if="!detailsMode && detailsList && detailsList.length" @click="toggleUserIntoxicationDetails()" class="show-details-btn">details</button>
					<button v-if="!detailsMode" @click="clearSelectedUser()" class="clear-btn-ltl">CLEAR</button>
				</div>
				<div v-if="!detailsMode" class="search">
					<div class="search-header">
						<div class="search-type">Search the crew member by name or code</div>
						<div v-if="showScannerButton" class="or">-- or --</div>
						<div v-if="showScannerButton" class="scan" @click="openBarcodeScannerModal">
							<icon-component iconName="barcode"/>
							<div class="scan-text">scan Crew card</div>
						</div>
					</div>
					<div class="search-input">
						<!--@input="filterPersons"-->
						<input v-model="searchInput" class="search-person" placeholder="Name, Cabin number, etc.">
						<icon-component v-if="searchInput.length >= 1" iconName="clear" class="clear-field" @click="clearSearchInput" />
						<label>
							<button @click="searchUsers">
								<icon-component iconName="search"/>
								<span>Search</span>
							</button>
						</label>
					</div>
				</div>
				<div v-if="!detailsMode" class="pas-list">
					<div class="nores" v-if="!usersList.length">No search results</div>
					<div class="users-wrapper" v-else>
						<div class="user users-list-header bold">
							<div class="cabin" @click="sortBy('cabin')">id</div>
							<div class="name" @click="sortBy('name')">name</div>
							<div class="date" @click="sortBy('date')" v-if="!setWaiter"></div>
						</div>
						<div class="scroll-wrapper" @scroll="infiniteScrollHandle">
							<div class="user user-block" v-for="i in usersList" @click="selectPerson(i)" :class="{'is-selected': selectedUser !== null && (i.id === selectedUser.id)}">
								<div class="cabin">{{ i.id }}</div>
								<div class="name">{{ setWaiter ? i.name : `${i.first_name ? i.first_name : ''} ${i.last_name}` }}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="detailsMode" class="details-list">
					<div class="details-wrapper">
						<div class="details-listing details-list-header bold">
							<div class="date">date</div>
							<div class="state">state</div>
							<div class="department">department</div>
							<div class="waiter">waiter</div>
						</div>
						<div class="scroll-wrapper" @scroll="infiniteScrollHandle">
							<div class="details-listing details-block" v-for="i in detailsList">
								<div class="date">{{ i.date }}</div>
								<div class="state">{{ i.state ? 'ON' : 'OFF' }}</div>
								<div class="department">{{ i.department }}</div>
								<div class="waiter">{{ i.causer }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-controls">
					<base-button v-if="!detailsMode" text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
					<base-button v-if="!detailsMode && !intoxicationModal" :text="modalSubmitBtnText" :isDisabled="submitBtnDisabledCalc" classes="def-btn main-action-btn uppercase medium border-2" @onClick="submit"/>
				</div>
			</div>
		</modal-component>
		
		<comment-modal v-if="showCommentModal" ref="comment-modal" @submitComment="markUserIntoxication" @onModalClose="onCommentModalClose" :title="!selectedUser.intoxication_state ? 'Comment' : 'Unmark the intoxication'" :sub-title="!selectedUser.intoxication_state ? 'you can add a commment to the intoxication mark' : 'you can add a commment to the cancellation the intoxication mark'" :sub-title-info="'(not required)'" :submit-btn-text="!selectedUser.intoxication_state ? 'Proceed' : 'unmark'"/>
		
		<confirmation-modal-component ref="confirmation-modal" :has-cancel-btn="false"/>
		
		<intoxication-mark-info-modal-component ref="intoxication-mark-info-modal-component" v-if="detailsList && detailsList.length" :intoxication-mark-info="detailsList[0]"></intoxication-mark-info-modal-component>
		
		<barcode-scanner-modal v-if="showBarcodeScannerModal" ref="barcode-scanner-modal" @submitBarcode="handleBarcode" @onModalClose="onBarcodeScannerModalHidden"></barcode-scanner-modal>
		<barcode-scan-interceptor
				@submitBarcode="handleBarcode"
				:openedFromLogin="false"/>
		<!--<barcode-scan-interceptor @submitBarcode="handleBarcode" :openedFromLogin="false"></barcode-scan-interceptor>-->
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {orderService} from "@/_api/order.api";
import {usersService} from "@/_api/users.api";
import {settingsService} from "@/_api/settings.api";
import Keyboard from "@/components/_shared/Keyboard";
import BarcodeScannerModal from "@/components/_modals/BarcodeScannerModal";
import CommentModal from "@/components/_modals/CommentModal";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import IntoxicationMarkInfoModalComponent from "@/components/_modals/IntoxicationMarkInfoModal";
import {format, parseISO, isToday} from 'date-fns'
import BarcodeScanInterceptor from "@/components/_shared/BarcodeScanInterceptor";
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'WaiterModal',
	components: {
		BarcodeScanInterceptor,
		IntoxicationMarkInfoModalComponent,
		ConfirmationModalComponent,
		CommentModal,
		Keyboard,
		ModalComponent,
		BarcodeScannerModal,
		BaseButton,
		iconComponent
	},
	props: {
		intoxicationModal: {
			type: Boolean,
			required: false,
			default: false
		},
		newOrderCreation: {
			type: Boolean,
			required: false,
			default: false
		},
		setWaiter: {
			type: Boolean,
			required: false,
			default: true
		},
		selectedOrder: {
			required: false,
			type: Object,
			default: null
		},
		fromReport: {
			type: Boolean,
			required: false,
			default: false
		},
	},
	data() {
		return {
			format,
			parseISO,
			isToday,
			modalTitleText: '',
			modalSubmitBtnText: '',
			usersList: [],
			selectedUser: null,
			page: 0,
			per_page: 50,
			sort: 'desc',
			order_by: 'name',
			search: '',
			searchInput:'',
			showBarcodeScannerModal: false,
			showCommentModal: false,
			detailsMode: false,
			detailsList: [],
			showScannerButton: false
		}
	},
	emits: ['onModalClose', 'setWaiter', 'setWaiterReport'],
	computed: {
		design() {
			return this.$store.state.UI
		},
		calcCabin() {
			if (this.selectedUser.cabin) {
				return this.selectedUser.cabin
			} else {
				return '-'
			}
		},
		intoxicationModalTitle() {
			return this.detailsMode ? 'Intoxication history' : 'Mark intoxication'
		},
		intoxicationModalSubmitBtn() {
			return this.selectedUser ? (!this.selectedUser.intoxication_state ? 'mark' : 'unmark') : 'mark'
		},
		hasAgeRestrictedItems() {
			return this.selectedOrder.items.some((o) => {return o.legal_age_class})
		},
		submitBtnDisabledCalc() {
			if (!this.selectedUser) {
				return true
			}
			if (this.setWaiter) {
				return false
			}
			if (this.newOrderCreation && this.selectedUser.posting_allowed) {
				return false
			}
			if (!this.setWaiter && (!this.selectedUser.posting_allowed || (this.selectedUser.intoxication_state && (this.selectedOrder.items.length && this.selectedOrder.items.some((o) => {return o.legal_age_class === 1}))))) {
				return true
			}
		}
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		});
		this.emmiter.on('barcodeScannedHID', (data) => {
			this.handleBarcode(data.barcode);
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.off('barcodeScannedHID');
		this.emmiter.emit('toggleModals', {opened: false, type: 'WaiterModal'});
		
	},
	methods: {
		getSettings() {
			if (navigator.mediaDevices === undefined) {
				this.showScannerButton = false;
			} else {
				navigator.mediaDevices.getUserMedia({video: true})
				.then((stream) => {
					this.showScannerButton = true;
					stream.getTracks().forEach( (track) => {
						track.stop();
					});
				}, (err) => {
					this.showScannerButton = false;
				})
			}
		},
		infiniteScrollHandle(e) {
			if (!this.detailsMode) {
				if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
					this.getUsers(false, false);
				}
			}
		},
		openModal(data) {
			this.getSettings();
			if (!this.intoxicationModal) {
				this.selectedUser = null;
				if (!this.newOrderCreation) {
					if (this.selectedOrder) {
						this.selectPerson(this.selectedOrder.opened_by);
					}
				}
			}
			this.getUsers(true, false);
			this.$refs['modal'].show();
			if (data) {
				this.modalTitleText = data.modalTitleText;
				this.modalSubmitBtnText = data.modalSubmitBtnText;
			}
			this.emmiter.emit('toggleModals', {opened: true, type: 'WaiterModal'});
		},
		close() {
			if (this.$refs['modal']) {
				this.showCommentModal = false;
				this.$refs['modal'].close();
				this.usersList = [];
				this.selectedUser = null;
				this.page = 0;
				this.per_page = 50;
				this.sort = 'desc';
				this.order_by = 'name';
				this.search = '';
				this.searchInput ='';
				this.showBarcodeScannerModal = false;
				this.detailsMode = false;
				this.showScannerButton = false;
				this.emmiter.emit('toggleModals', {opened: false, type: 'WaiterModal'});
			}
		},
		selectPerson(data) {
			if (this.setWaiter) {
				this.selectedUser = data;
			} else {
				usersService.getSinglePosUser(data.id).then((res) => {
					this.selectedUser = res;
					this.detailsList = res.intoxication_log;
					if (this.detailsList && this.detailsList.length) {
						this.detailsList.sort((a,b) => {return b.id - a.id})
					}
				})
			}
		},
		clearSelectedUser() {
			this.selectedUser = null;
		},
		searchUsers() {
			this.search = this.searchInput;
			this.getUsers(true, false);
		},
		clearSearchInput() {
			this.search = '';
			this.searchInput = '';
			this.getUsers(true, false);
		},
		sortBy() {
			this.getUsers(true, false);
		},
		click(e) {
			if (e === undefined) {
				this.searchInput = this.searchInput.substring(0, this.searchInput.length - 1);
				if (!this.searchInput.length) {
					this.search = '';
					this.getUsers(true, false);
				}
			} else {
				this.searchInput += e;
			}
		},
		getUsers(reset, reset_user) {
			let method = this.setWaiter ? orderService.getPosUsers : orderService.getUsers;
			if (reset) {
				this.page = 0;
				this.usersList = [];
			}
			if (reset_user) {
				this.selectedUser = null;
			}
			method({
				search: this.search,
				order_by: this.order_by,
				sort: this.sort,
				page: !this.page ? this.page += 1 : this.page,
				per_page: this.per_page
			}).then((res) => {
				res.data.forEach((obj) => {
					this.usersList.push(obj);
				});
				if (res.data.length > 0) {
					this.page += 1;
				}
			})
		},
		openBarcodeScannerModal() {
			this.showBarcodeScannerModal = true;
			this.$nextTick(() => {
				this.$refs['barcode-scanner-modal'].openModal();
			})
		},
		onBarcodeScannerModalHidden() {
			this.showBarcodeScannerModal = true;
		},
		handleBarcode(data) {
			console.log(data);
			this.searchInput = data;
			this.search = data;
			this.getUsers(true, true);
		},
		toggleUserIntoxicationDetails() {
			this.detailsMode = !this.detailsMode;
		},
		openCommentModal() {
			if (this.selectedUser) {
				this.showCommentModal = true;
				this.$nextTick(() => {
					this.$refs['comment-modal'].openModal();
				})
			}
		},
		submit() {
			if (this.fromReport) {
				this.$emit('setWaiterReport', this.selectedUser);
				this.close();
			} else {
				let df = () => {
					this.emmiter.emit('setWaiter', this.selectedUser);
					this.$emit('setWaiter', this.selectedUser)
				}
				
				if (this.intoxicationModal) {
					this.openCommentModal();
				} else {
					// if ((!this.setWaiter && !this.selectedUser.posting_allowed) || (!this.setWaiter && this.selectedUser.intoxication_state)) {
					if (this.newOrderCreation && this.selectedUser.posting_allowed) {
						df();
						return
					}
					if (!this.setWaiter && (!this.selectedUser.posting_allowed || (this.selectedUser.intoxication_state && (this.selectedOrder.items.length && this.selectedOrder.items.some((o) => {return o.legal_age_class === 1}))))) {
					
					} else {
						df()
					}
				}
			}
		},
		onCommentModalClose() {
			// this.showCommentModal = false;
		},
		// markUserIntoxication(comment) {
		// 	usersService.toggleIntoxication({
		// 		state: !this.selectedUser.intoxication_state,
		// 		comment: comment
		// 	}, this.selectedUser.id).then((res) => {
		// 		if (res) {
		// 			this.selectPerson(res);
		// 			// this.emmiter.emit('closeModal');
		// 			if (this.selectedOrder.payers && this.selectedOrder.payers.length && this.selectedOrder.payers.filter((o) => {return o.id === res.id}).length) {
		// 				this.emmiter.emit('updateUserDataInOrder', res);
		// 			}
		
		// 			if (this.intoxicationModal) {
		// 				this.$refs['confirmation-modal'].show({
		// 					message: `Intoxication has been ${res.intoxication_state ? 'marked' : 'unmarked'} successfully`,
		// 					okButton: 'OK'
		// 				});
		// 				this.close();
		// 			} else {
		// 				this.selectedUser = res;
		// 				this.$refs['confirmation-modal'].show({
		// 					message: `Intoxication has been ${res.intoxication_state ? 'marked' : 'unmarked'} successfully`,
		// 					okButton: 'OK'
		// 				});
		// 				this.$refs['comment-modal'].close();
		// 			}
		// 		}
		// 	})
		// },
		// showIntoxicationMarkInfo() {
		// 	this.$refs['intoxication-mark-info-modal-component'].openModal();
		// }
	}
}
</script>

<style lang="less" scoped>
.keyboard {
	z-index: 99999;
	bottom: 0;
	margin: 0 auto;
	left: 0;
	right: 0;
}

#modal-wrapper {
	:deep(#modal) {
		.c-modal {
			align-items: flex-start;
		}
	}
}


#modal-wrapper {
	#waiter-modal {
		width: 550px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;

		.modal-header {
			font-style: normal;
			font-weight: 300;
			font-size: 42px;
			line-height: 56px;
			text-align: center;
			color: #3F8EC5;
		}
		.header {
			margin-bottom: 10px;
			width: 100%;
			&-title{
				font-size: 42px;
				font-weight: normal;
				line-height: 56px;
				// color: var(--theme_color);
				margin: 0;
			}
		}
		.selected-user {
			// height: 160px;
			display: flex;
			align-items: flex-start;
			width: 100%;
			position: relative;
			margin-bottom: 10px;
			.not-selected-user-text {
				margin-top: 50px;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 32px;
				color: #8DC7E1;
				margin-left: 18px;
			}
			img{
				width: 110px;
				height: 140px;
			}
			.info{
				// width: 240px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: self-start;
				margin-left: 18px;
				width: 100%;
				div {
					text-align: left;
					&.label {
						font-weight: 600;
						font-size: 14px;
						line-height: 16px;
						color: #1C282D;
						margin-bottom: 10px;
					}
					&.cabin, &.name {
						margin-left: 30px;
						font-weight: normal;
						font-size: 18px;
						line-height: 24px;
						color: #1C282D;
						text-transform: uppercase;
						margin-bottom: 10px;
					}
				}
				.not-a-payer, .age-restrictions {
					color: red;
					font-size: 12px;
					margin-bottom: 0;
					font-weight: 600;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
				}
				.intoxication-indicator-wrapper {
					display: flex;
					justify-content: space-between;
					align-content: center;
					flex-wrap: nowrap;
					width: 100%;
					.intoxication-indicator {
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						img {
							width: 14px;
							height: 14px;
							margin-right: 5px;
						}
						.highlighted {
							color: red;
							margin-right: 10px;
						}
						font-size: 12px;
						margin-bottom: 0;
						font-weight: 600;
					}
					.unmark-intoxication {
						position: relative;
					}
				}
			}
			.clear-btn-ltl, .show-details-btn, .unmark-intoxication {
				width: 84px;
				height: 23px;
				background: #FFFFFF;
				box-sizing: border-box;
				border-radius: 8px;
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				text-transform: uppercase;
				position: absolute;
				right: 0;
				top: 0;
				cursor: pointer;
			}

			.clear-btn-ltl {
				border: 1px solid #EB262B;
				// border-color: #EB262B;
				color: #EB262B;
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
			}
			.show-details-btn {
				right: 90px;
			}
		}
		.search {
			border-top: 1px solid #3F8EC5;
			margin-bottom: 20px;
			padding-top: 12px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			&-header{
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				min-height: 35px;
				.search-type{
					font-weight: 600;
					font-size: 12px;
					line-height: 16px;
					color: #1C282D;
				}
				.or{
					font-weight: 600;
					font-size: 12px;
					line-height: 16px;
					color: #777E81;
					margin: 0 34px;
				}
				.scan {
					display: flex;
					align-items: center;
					justify-content: space-between;
					// border: 2px solid;
					border-radius: 8px;
					// padding: 5px 10px;
					cursor: pointer;
					&-text{
						margin-left: 15px;
						font-weight: 600;
						font-size: 12px;
						line-height: 16px;
						color: #1C282D;
					}
				}
			}
			&-input{
				display: flex;
				margin-top: 18px;
				width: 100%;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: nowrap;
				position: relative;
				.search-person{
					border: 1px solid #7B809A;
					box-sizing: border-box;
					border-radius: 4px 0 0 4px;
					font-weight: normal;
					font-size: 16px;
					align-items: center;
					color: #777E81;
					width: 100%;
					height: 40px;
					padding-left: 13px;
				}
				.clear-field {
					position: absolute;
					right: 130px;
					cursor: pointer;
				}
				label{
					button{
						cursor: pointer;
						border: none;
						width: 120px;
						height: 40px;
						background: var(--theme_color);
						border-radius: 0 8px 8px 0;
						font-weight: 600;
						font-size: 16px;
						line-height: 21px;
						display: flex;
						align-items: center;
						text-align: center;
						text-transform: uppercase;
						color: #FFFFFF;
						img{
							width: 24px;
							height: 24px;
							color: white;
							margin:0 8px;
						}
					}
				}
			}
		}
		.pas-list{
			height: 190px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0 0 20px 0;
			width: 100%;
			.nores{
				font-weight: normal;
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				color: #8DC7E1;
			}
			.users-wrapper{
				width: 100%;
				.users-list-header {
					div {
						cursor: pointer;
					}
				}
				.scroll-wrapper{
					overflow: scroll;
					border: none;
					height: 150px;
					&::-webkit-scrollbar {
						display: none;
					}
				}
				.bold{
					font-weight: bold;
					font-size: 12px;
					line-height: 148%;
					text-transform: uppercase;
				}
				.user{
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					border-top:  1px solid rgba(53, 131, 197,.2);
					min-height: 40px;
					cursor: pointer;
					transition: all 250ms;
					&.is-selected {
						background-color: #efefef;
					}
					&:last-child{
						border-bottom: 1px solid rgba(53, 131, 197,.2);
					}
					// &:active{
					// 	// background: #8DC7E1;
					// }
					.cabin {
						width: 20%;
						text-align: left;
						padding: 0 5px;
					}
					.date {
						width: 25%;
						text-align: right;
						padding: 0 10px;
					}
					.name {
						text-transform: uppercase;
						padding: 0 5px;
						width: 55%;
						text-align: left;
					}
				}
				
				.printer-block{
					cursor: pointer;
				}
			}
		}
		.details-list {
			// height: 290px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0 0 20px 0;
			width: 100%;
			.details-wrapper{
				width: 100%;
				.details-list-header {
					div {
						cursor: pointer;
						font-weight: bold;
						font-size: 12px;
						text-transform: uppercase;
					}
				}
				.scroll-wrapper{
					overflow: scroll;
					border: none;
					height: 300px;
					&::-webkit-scrollbar {
						display: none;
					}
				}
				.details-listing {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					border-top:  1px solid rgba(53, 131, 197,.2);
					min-height: 40px;
					cursor: pointer;
					transition: all 250ms;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 400;
					color: black;
					text-align: left;
					// padding: 0 5px;
					&>div {
						padding: 0 5px;
					}
					&:last-child {
						border-bottom: 1px solid rgba(53, 131, 197,.2);
					}
					.date {
						width: 26%;
					}
					.state {
						width: 10%;
					}
					.department {
						width: 35%;
					}
					.waiter {
						width: 29%;
					}
				}
			}
		}

		.modal-controls {
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 12px;
			button {
				width: 181px;
				height: 48px;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 21px;
				display: flex;
				align-items: center;
				text-align: center;
				text-transform: uppercase;
				// color: #FFFFFF;
			}
		}
	}
}

@media (max-width: 1200px) {
	#modal-wrapper {
		#users-modal {
			.search {
				&-input {
					label {
						button {
							font-size: 12px;
							img{
								width: 18px;
								height: 18px;
								color: white;
								margin:0 8px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
