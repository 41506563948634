<template>
	<div id="selected-order" class="selected-order" v-if="section === 'POS'">
		<div class="loading" v-if="orderLoading">
			<icon-component iconName="loader" />
		</div>
		<div class="order" v-if="order && !orderLoading">
			<div class="order-details">
				<div class="col waiter" @click="openModal('waiterModalRef')">
					<div class="title">
						Waiter
					</div>
					<div class="col-data payer-data" :class="{'not-specified': !order.opened_by, 'disabled': isPayerFuncDisabled}">
						<span>{{ order.opened_by ? order.opened_by.name : 'Not specified yet' }}</span>
						<icon-component iconName="dropdown" class='dropdown-icon' />
					</div>
				</div>
				<div class="col table" @click="openModal('newOrderModalRef')">
					<div class="title">
						Table
					</div>
					<div class="col-data" :class="{'not-specified': !order.table}">
						<span>{{ order.table ? order.table : 'N/A' }}</span>
						<icon-component iconName="dropdown" class='dropdown-icon' />
					</div>
				</div>
				<div class="col seat">
					<div class="title">
						Seat
					</div>
					<dropdown
							ref="seat-dd"
							:options="calcSeats"
							:has-input="false"
							:has-preselected-value="true"
							:placeholder="'N/A'"
							@onItemClick="seatFilterSelect"
					>
					</dropdown>
				</div>
				<div class="col payers" @click="openModal('payersModalRef', {
							modalTitleText: 'Set the payer',
							modalSubmitBtnText: 'OK'
						})">
					<div class="title">
						Payer
					</div>
					<div class="col-data" :class="{'not-specified': !order.payers.length}">
						<span>{{ order.payers.length ? `${order.payers[0].first_name ? order.payers[0].first_name : ''} ${order.payers[0].last_name}` : 'N/A' }}</span>
						<icon-component iconName="dropdown" class='dropdown-icon' />
					</div>
				</div>
				<div class="col payer-cabin">
					<div class="title">
						Cabin
					</div>
					<div class="col-data disabled" :class="{'not-specified': !order.buyer_profile || !order.buyer_profile.cabin}">
						<span>{{ (order.payers.length ? (order.payers[0].cabin ? order.payers[0].cabin : 'N/A') : 'N/A') }}</span>
					</div>
				</div>
				<div class="col col_more">
					<base-button v-if="order.id" :isDisabled="order.id === tempID" @onClick="toggleOrderDetails(true)" text="MORE..." classes="def-btn secondary-action-btn uppercase medium border-2 more_btn" />
				</div>
			</div>
			
			<div class="items-list">
				<table>
					<thead>
					<tr>
						<th>Item</th>
						<th>Price</th>
						<th>QTY</th>
						<th>Disc.</th>
						<th>Total</th>
						<th>Seat</th>
						<th>St.</th>
						<th>DEL</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(i,index) in ((filterBySeats !== 'All') ? filterList : order.items)" :key="index">
						<td @click="openModal('addEditItemModalRef', index)"><span>{{ i.name }}</span></td>
						<td>{{ calcItemPrice(i) }}</td>
						<td class="qty-td" style="margin-left: -20px;">
							<div>
								<icon-component v-show="i.status === 1" iconName="minusBg" @click="changeQty(i, 'minus')" />
								<span>{{ i.count }}</span>
								<icon-component v-show="i.status === 1" iconName="plusBg" @click="changeQty(i, 'plus')" />
							</div>
						</td>
						<td class="item-discount"><span>{{ calcItemDiscount(i) }}</span></td>
						<td>{{ calcItemTotal(i) }}</td>
						<td>{{ (order.table) ? (i.seat ? i.seat : '1') : 'N/A' }}</td>
						<td>
							<!--@click="openModal('changeItemStateModalRef', i)"-->
							<div class="state">
								<div :class="`circle status status-${i.status}`"></div>
							</div>
						</td>
						<td class="del">
							<button v-if="((filterBySeats !== 'All') ? filterList.length > 1 : order.items.length > 1)" @click="deleteItem(index)">
								<icon-component iconName="delete" />
								<!-- <icon-component v-if="design === 'margaritaville'" iconName="itemEdit" /> -->
							</button>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			
			<div class="order-calculations">
				<div class="calc-wrapper">
					<div class="row">
						<div class="title title_first">subtotal</div>
						<div class="amount amount_first">
							{{ order.items.length > 0 ? order.subtotal.toFixed(2) : '0.00' }}
						</div>
					</div>
					<div class="row">
						<!-- <Switch
						  id="toggleDiscount"
							:onLabel="'Included'"
							:offLabel="'Excerpted'"
							:val="order.with_discount"
							@toggled="toggleDiscountBL"
						/> -->
						<div class="title">discount</div>
						<div class="amount">{{ order.with_discount ? calcDiscount : '0.00' }}</div>
					</div>
					<div class="row">
						<Switch
							id="toggleTax"
							:onLabel="'Included'"
							:offLabel="'Excerpted'"
							:val="tax"
							@toggled="toggleTaxBL"
						/>
						<div class="title">tax</div>
						<div class="amount">0.00</div>
					</div>
					<div class="row">
						<Switch
							id="toggleGratuity"
							:onLabel="'Included'"
							:offLabel="'Excerpted'"
							:val="autoGratuity"
							@toggled="toggleGratuityBL"
						/>
						<div class="title">autoGratuity</div>
						<div class="amount autoGratuity">0.00</div>
					</div>
					<div class="row total">
						<div class="title">total</div>
						<div class="amount">{{
								order.total ? order.total.toFixed(2) : order.subtotal.toFixed(2)
							}}
						</div>
					</div>
				</div>
			</div>
			<div class="controls" v-once>
				<div class="row x3">
					<base-button text="edit discount"
						classes="def-btn secondary-action-btn uppercase medium border-2" @onClick="openModal('setDiscountGratuityModalRef', {title: 'discounts'})"/>
					<base-button text="add gratuity" classes="def-btn secondary-action-btn uppercase medium border-2"
						@onClick="openModal('setDiscountGratuityModalRef', {title: 'gratuity'})"/>
					<base-button text="add round" classes="def-btn secondary-action-btn uppercase medium border-2" @onClick="addRound"/>
				</div>
				<div class="row x2">
					<base-button text="print runner chit"
						classes="def-btn secondary-action-btn uppercase medium border-2 icon left" @onClick="printMethodsBL({type: 'runner-chit'})" :img-src="'printFoodRunner'"/>
					<base-button text="print check" classes="def-btn main-action-btn uppercase medium border-2 icon left" @onClick="openModal('printCheckModalRef')" :img-src="'checksWh'"/>
				</div>
				<div class="row x2">
					<base-button text="cancel order" @onClick="openModal('cancelOrderModalRef')"
						classes="def-btn cancel-action-btn uppercase medium border-2" :is-disabled="isWaiterAssist"/>
					<base-button text="close order" @onClick="openModal('closeOrderModalRef')"
						classes="def-btn main-action-btn uppercase medium border-2 icon left" :is-disabled="isWaiterAssist"
						img-src="closeOrderWh" custom-styles="background-color: #EB262B; border-color: #EB262B;"/>
				</div>
				<!--<cancel-close-order :selectedOrder="order" :openFrom="'selectedOrder'"/>-->
			</div>
		</div>
		<div class="no-order-selected" v-if="!order && !orderLoading">
			No orders selected
		</div>
	</div>
	<div v-if="showMoreDetails && order" id="check-details-wrapper">
		<CheckDetailsComponent
			:selectedOrder="order"
			:section="section"
			:orderLoading="orderLoading"
			@backToPos="toggleOrderDetails"
			@changeTable="openModal('newOrderModalRef')"
			@changeServing="openModal('waiterModalRef')"
			@toggleDiscount="toggleDiscountBL"
			@editDiscounts="openModal('setDiscountGratuityModalRef', {title: 'discounts'})"
			@addGratuity="openModal('setDiscountGratuityModalRef', {title: 'gratuity'})"
			@printCheck="openModal('printCheckModalRef')"
			@cancelOrder="openModal('cancelOrderModalRef')"
			@closeOrder="openModal('closeOrderModalRef')"
			@setItemDiscount="openModal"
			@showDiscountDetails="openModal('discountDetailsModalRef')"
			@deletePayer="deletePayerBl"
			@reopenOrder="reopenOrderBL"
			@resetOrder="resetOrderBL"
			@addPayer="openModal"
			@toggleCPT="toggleCPTBL"
			@houseAccountInputChange="houseAccountInputChange"
		/>
	</div>
	
	<cancel-order-modal
			:selectedOrder="order"
			ref="cancelOrderModalRef"
			v-if="cancelOrderModalRef"
			@onModalClose="onModalClose('cancelOrderModalRef')"
			@cancelOrder="cancelOrderBL"
	/>
	<close-order-modal
			:selectedOrder="order"
			ref="closeOrderModalRef"
			v-if="closeOrderModalRef"
			@toggleCPT="toggleCPTBL"
			@onModalClose="onModalClose('closeOrderModalRef')"
			@houseAccountInputChange="houseAccountInputChange"
	/>
	<print-check-modal
			v-if="printCheckModalRef"
			:selectedOrder="order"
			ref="printCheckModalRef"
			@printMethods="printMethodsBL"
			@onModalClose="onModalClose('printCheckModalRef')"
	></print-check-modal>
	<new-order-modal-component
			v-if="newOrderModalRef"
			:is-edit="true"
			ref="newOrderModalRef"
			:selected-order="order"
			@changeOrderTable="changeOrderTableBL"
			@onModalClose="onModalClose('newOrderModalRef')"
	></new-order-modal-component>
	<add-edit-item-modal
			v-if="addEditItemModalRef"
			@addEditItem="editItemBL"
			@deleteItem="deleteItemBL"
			:action="'edit'"
			:selectedItem="selectedItem"
			:selectedOrder="order"
			ref="addEditItemModalRef"
			@onModalClose="onModalClose('addEditItemModalRef')"
	/>
	<select-the-printer-modal
			v-if="selectThePrinterModalRef"
			ref="selectThePrinterModalRef"
			@printFoodRunner="printMethodsBL"
			@selectedPrinter="selectPrinter"
			@onModalClose="onModalClose('selectThePrinterModalRef')"
	/>
	<users-modal
			v-if="payersModalRef"
			ref="payersModalRef"
			:selectedOrder="order"
			:set-waiter="false"
			@addPayer="setPayerBL"
			@onModalClose="onModalClose('payersModalRef')"
	/>
	<waiter-modal
			v-if="waiterModalRef"
			ref="waiterModalRef"
			:selectedOrder="order"
			:set-waiter="true"
			@setWaiter="setWaiterBL"
			@onModalClose="onModalClose('waiterModalRef')"
	/>
	<change-item-state-modal
			v-if="changeItemStateModalRef"
			ref="changeItemStateModalRef"
			:selectedOrder="order"
			@change-item-status="changeItemStatusBL"
			@onModalClose="onModalClose('changeItemStateModalRef')"
	/>
	<discount-details-modal-component
			v-if="discountDetailsModalRef"
			ref="discountDetailsModalRef"
			:selectedOrder="order"
			@onModalClose="onModalClose('discountDetailsModalRef')"
	/>
	<confirmation-modal-component ref="confirmationModalRef" :has-cancel-btn="false"/>
	<set-discount-gratuity-modal-component
			v-if="setDiscountGratuityModalRef"
			ref="setDiscountGratuityModalRef"
			:selectedOrder="order"
			@onModalClose="onModalClose('setDiscountGratuityModalRef')"
	></set-discount-gratuity-modal-component>
	<item-deletion-notice-modal
			v-if="itemDeletionNoticeModalRef"
			ref="itemDeletionNoticeModalRef"
			:selected-order="order"
			:selectedItem="selectedItem"
			@deleteItem="deleteItemBL"
	></item-deletion-notice-modal>
</template>

<script>
import CheckDetailsComponent from "@/views/checks/CheckDetails";
import Dropdown from "@/components/_shared/Dropdown";
import ModalComponent from "@/components/_shared/Modal";
import PrintCheckModal from "@/components/_modals/PrintCheckModal";
import NewOrderModalComponent from "@/components/_modals/NewOrderModal";
import CancelCloseOrder from "@/components/_modals/CancelCloseOrder";
import AddEditItemModal from "@/components/_modals/AddEditItemModal";
import SelectThePrinterModal from "@/components/_modals/SelectThePrinterModal";
import FoodRunnerModal from "@/components/_modals/FoodRunnerModal";
import ChangeItemStateModal from "@/components/_modals/ChangeItemStateModal";
import SplitOrderModal from "@/components/_modals/SplitOrderModal";
import DiscountDetailsModalComponent from "@/components/_modals/DiscountDetailsModal";
import UsersModal from "@/components/_modals/UsersModal";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import {usersService} from "@/_api/users.api";
import WaiterModal from "@/components/_modals/WaiterModal";
import SetDiscountGratuityModalComponent from "@/components/_modals/SetDiscountGratuityModal";
import ItemDeletionNoticeModal from "@/components/_modals/ItemDeletionNoticeModal";
import {orderService} from "@/_api/order.api";
import {printersService} from "@/_api/printers.api";
import {formatDataService} from "@/_helpers/format-data";
import {constants} from "@/constants/constants";
import BaseButton from "@/components/_shared/Button.vue";
import CancelOrderModal from "@/components/_modals/CancelOrderModal.vue";
import CloseOrderModal from "@/components/_modals/CloseOrderModal.vue";
import Switch from "@/components/_shared/Switch.vue";
import store from "@/store";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'SelectedOrderComponent',
	props: {
		tempOrder: {
			require: false,
			type: Object
		},
		section: {
			required: true,
			type: String,
			default: 'POS' //POS/CHECKS
		}
	},
	emits: ['addEditItem', 'selectedPrinter', 'print', 'updateOrder', 'singleOrderLoading'],
	components: {
		CloseOrderModal, CancelOrderModal,
		BaseButton,
		UsersModal,
		WaiterModal,
		DiscountDetailsModalComponent,
		SplitOrderModal,
		ChangeItemStateModal,
		FoodRunnerModal,
		SelectThePrinterModal,
		CancelCloseOrder,
		AddEditItemModal,
		ModalComponent,
		PrintCheckModal,
		Dropdown,
		NewOrderModalComponent,
		ConfirmationModalComponent,
		CheckDetailsComponent,
		SetDiscountGratuityModalComponent,
		ItemDeletionNoticeModal,
		Switch,
		iconComponent
	},
	data() {
		return {
			order: null,
			orderLoading: false,
			showMoreDetails: false,

			tempID: '',
			
			payersModalRef: false,
			waiterModalRef: false,
			selectThePrinterModalRef: false,
			printCheckModalRef: false,
			addEditItemModalRef: false,
			newOrderModalRef: false,
			changeItemStateModalRef: false,
			discountDetailsModalRef: false,
			setDiscountGratuityModalRef: false,
			itemDeletionNoticeModalRef: false,
			closeOrderModalRef: false,
			cancelOrderModalRef: false,
			
			selectedPayer: null,
			tax: false,
			autoGratuity: false,
			discount: false,
			stateColor: {
				1: '#EB262B',
				2: '#FFCB05',
				3: '#82B941',
			},
			waitersList: [
				{
					id: 1,
					name: 'Pedro gonsalez'
				}, {
					id: 2,
					name: 'Pedro gonsalez 2'
				}, {
					id: 3,
					name: 'Pedro gonsalez 3'
				},
			],
			payersList: [],
			tablesList: [],
			seatsList: [],
			selectedItem: {},
			selectedPrinter: [],
			filterBySeats: 'All'
		}
	},
	computed: {
		authUser() {
			return this.$store.state.auth.user
		},
		design() {
			return this.$store.state.UI
		},
		calcSeats() {
			let t = [...new Set(this.order.items.map((o) => {return o.seat}))];
			let tt = t.map((o) => {
				return {
					name: o
				}
			});
			tt.unshift({name: 'All'});
			if (this.order.table) {
				return tt
			} else {
				return [];
			}
		},
		filterList() {
			return this.order.items.filter((o) => {return o.seat === this.filterBySeats})
		},
		getUser() {
			return this.$store.state.auth.user
		},
		isManager() {
			return this.$store.state.auth.user.user.role === 'manager'
		},
		isWaiterAssist() {
			return this.$store.state.auth.user.user.role === 'waiter assist'
		},
		calcDiscount() {
			let totalDiscount = this.order.subtotal - this.order.total;
			return totalDiscount.toFixed(2);
		},
		isPayerFuncDisabled() {
			if (this.getUser && this.getUser.user.role === 'waiter' && this.order.opened_by.id !==
					this.getUser.user.id) {
				return true
			} else if (this.getUser && this.getUser.user.role === 'waiter assist') {
				return true
			} else {
				return false
			}
		},
		calcTotal() {
			let total = 0;
			this.order.items.forEach(t => {
				t.condiments_parsed.forEach((o) => {
					if (o.type === 1) {
						o.items.forEach(oo => {
							if (oo.checked) {
								total += oo.count * o.count * parseFloat(oo.price).toFixed(2)
							}
						})
					}
				});
			})
			return parseFloat(this.order.subtotal + total).toFixed(2)
		},
		calcFullTotal() {
			let total = 0;
			this.order.items.forEach(t => {
				t.condiments_parsed.forEach((o) => {
					if (o.type === 1) {
						o.items.forEach(oo => {
							if (oo.checked) {
								total += oo.count * o.count * parseFloat(oo.price).toFixed(2)
							}
						})
					}
				});
			})
			if (this.order.discount !== null) {
				return parseFloat(this.order.total + total).toFixed(2)
			} else {
				return parseFloat(this.order.subtotal + total).toFixed(2)
			}
		},
	},
	created() {},
	mounted() {
		this.tempID = constants.tempOrderID;
		if (this.section === 'CHECKS') {
			this.getOrder(this.$route.params.id)
			this.toggleOrderDetails(true);
		}
		this.emmiter.on('addItem', (data) => {
			this.addItemBL(data);
		});
		this.emmiter.on('closeOrder', (data) => {
			this.closeOrderBL(data);
		});
		this.emmiter.on('cancelOrder', (data) => {
			this.cancelOrderBL(data);
		});
	},
	beforeUnmount() {
		this.emmiter.off('addItem');
		this.emmiter.off('closeOrder');
		this.emmiter.off('cancelOrder');
	},
	methods: {
		getOrder(data) {
			this.orderLoading = true;
			this.emmiter.emit('singleOrderLoading', this.orderLoading);
			orderService.getSingleOrder(data).then((res) => {
				const t0 = performance.now();
				this.order = res;
				this.order.items = formatDataService.handleCondimentsModifiers(this.order.items);
				const t1 = performance.now();
				console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);
			}).catch((err) => {
			}).finally(() => {
				this.orderLoading = false;
				this.emmiter.emit('singleOrderLoading', this.orderLoading);
			})
		},
		handleAPIResponse(data, closeModal, updateOrderInList, updateOrderInListAction, removedItem) {
			if (closeModal) {
				this.emmiter.emit('closeModal');
				if (this.$refs['closeOrderModalRef']) {
					this.$refs['closeOrderModalRef'].close();
				}
				
				// need to be triggered like this because emmiter doesnt work for some reason
			}
			if (data) {
				this.order = data;
				this.order.items = formatDataService.handleCondimentsModifiers(this.order.items);
				if (updateOrderInListAction === 'replace') {
					this.$emit('updateOrder', {...this.order, active: true})
				}
			}
			if (updateOrderInListAction === 'remove') {
				this.$emit('updateOrder', null)
			}
			if (updateOrderInList) {
				this.emmiter.emit('updateOrderInList', {
					data: data,
					action: updateOrderInListAction,
					removedItem: removedItem
				});
			}
		},
		openModal(type, data) {
			switch (type) {
				case 'cancelOrderModalRef':
					if (this.isWaiterAssist) {} else {
						if (!this.order.items.length) {
							this.emmiter.emit('cancelOrder', this.order);
						} else {
							this.cancelOrderModalRef = true;
							this.$nextTick(() => {
								this.$refs['cancelOrderModalRef'].openModal(this.order);
							})
						}
					}
					break;
				case 'closeOrderModalRef':
					if (this.isWaiterAssist) {} else {
						if (this.order.items.length > 0) {
							this.closeOrderModalRef = true;
							this.$nextTick(() => {
								this.$refs['closeOrderModalRef'].openModal();
							})
						}
					}
					break;
				case 'setDiscountGratuityModalRef':
					if (data.title === 'discounts') {
						if (this.order.items.length > 0) {
							this.setDiscountGratuityModalRef = true;
							this.$nextTick(() => {
								this.$refs['setDiscountGratuityModalRef'].openModal({title: 'All discounts'});
							});
						}
						break;
					}
					if (data.title === 'gratuity') {
						if (this.order.items.length > 0) {
							this.setDiscountGratuityModalRef = true;
							this.$nextTick(() => {
								this.$refs['setDiscountGratuityModalRef'].openModal({title: 'Gratuity'});
							});
						}
						break;
					}
					if (data.title === 'Item discount') {
						if (this.order.items.length > 0) {
							this.setDiscountGratuityModalRef = true;
							this.$nextTick(() => {
								this.$refs['setDiscountGratuityModalRef'].openModal({title: 'Item discount', index:
									data.index});
							});
						}
						break;
					}
					break;
				case 'discountDetailsModalRef':
					if (this.order.items.length > 0) {
						this.discountDetailsModalRef = true;
						this.$nextTick(() => {
							this.$refs['discountDetailsModalRef'].openModal();
						});
					}
					break;
				case 'addEditItemModalRef':
					this.selectedItem = this.order.items[data];
					this.addEditItemModalRef = true;
					this.$nextTick(() => {
						this.$refs['addEditItemModalRef'].openModal();
					})
					break;
				case 'printCheckModalRef':
					if (this.order.items.length > 0) {
						this.printCheckModalRef = true;
						this.$nextTick(() => {
							this.$refs['printCheckModalRef'].openModal(this.order);
						});
					}
					break;
				case 'newOrderModalRef':
					this.newOrderModalRef = true;
					this.$nextTick(() => {
						this.$refs['newOrderModalRef'].openModal(this.order);
					})
					break;
				case 'payersModalRef':
					this.payersModalRef = true;
					this.$nextTick(() => {
						this.$refs['payersModalRef'].openModal(data);
					})
					break;
				case 'waiterModalRef':
					if (this.getUser.user.role === 'manager' || (this.getUser.user.role === 'waiter' && this.order.opened_by.id === this.getUser.user.id)) {
						this.waiterModalRef = true;
						this.$nextTick(() => {
							this.$refs['waiterModalRef'].openModal({
								modalTitleText: 'Set the waiter',
								modalSubmitBtnText: 'CONFIRM'
							});
						})
					}
					break;
				case 'changeItemStateModalRef':
					if (data.status === 1) {
						this.changeItemStateModalRef = true;
						this.$nextTick(() => {
							this.$refs['changeItemStateModalRef'].openModal(data);
						})
					} else if (data.status === 2) {
						this.changeItemStateModalRef = true;
						this.$nextTick(() => {
							this.$refs['changeItemStateModalRef'].openModal(data, true, 0);
						})
					}
					break;
				case 'confirmationModalRef':
					if (data === 'intoxicated_payer') {
						this.$refs['confirmationModalRef'].show({
							title: 'Restricted',
							message: 'Payers has intoxication state.',
							okButton: 'OK',
						});
						break;
					}
					if (data === 'add_round_intoxicated') {
						this.$refs['confirmationModalRef'].show({
							title: 'Attention',
							message: 'The round added partially because of intoxicated state of the payer',
							okButton: 'OK',
						})
						break;
					}
					if (data === 'user_already_added') {
						this.$refs['confirmationModalRef'].show({
							title: 'Attention',
							message: 'User is already in the list',
							okButton: 'OK',
						})
						break;
					}
					break;
				case 'itemDeletionNoticeModalRef':
					this.itemDeletionNoticeModalRef = true;
					this.$nextTick(() => {
						this.$refs['itemDeletionNoticeModalRef'].openModal();
					})
					break;
			}
		},
		onModalClose(ref) {
			this[ref] = false;
		},
		toggleOrderDetails(data) {
			this.showMoreDetails = data;
			this.emmiter.emit('toggleModals', {opened: this.showMoreDetails, type: 'SelectedOrderMore'});
		},
		calcItemPrice(data) {
			let totalCondiments = 0;
			if (data.condiments && data.condiments.length) {
				data.condiments.forEach((o) => {
					if (o.count > 0) {
						totalCondiments += (o.count / data.count) * o.price
					}
				});
				return (totalCondiments + data.price).toFixed(2);
			}
			
			return (totalCondiments + data.price).toFixed(2);
		},
		calcItemDiscount(data) {
			let totalCondiments = 0;
			if (data.condiments && data.condiments.length) {
				data.condiments.forEach((o) => {
					if (o.count > 0) {
						totalCondiments += o.discount;
					}
				});
			} else {
				if (data.items && data.items.length) {
					data.items.forEach((o) => {
						if (o.condiments && o.condiments.length) {
							o.condiments.forEach((oo) => {
								if (oo.count > 0) {
									totalCondiments += oo.discount;
								}
							});
						}
					});
				}
			}
			if (this.order.with_discount) {
				return (totalCondiments + (data.subtotal - data.total)).toFixed(2);
			} else {
				return '0.00';
			}
		},
		calcItemTotal(data) {
			let totalCondimentsWithDisc = 0;
			let totalCondiments = 0;
			if (data.condiments && data.condiments.length) {
				data.condiments.forEach((o) => {
					if (o.count > 0) {
						totalCondimentsWithDisc += (o.total_amount - o.total_amount_with_discount);
						totalCondiments += o.total_amount;
					}
				});
			} else {
				if (data.items && data.items.length) {
					data.items.forEach((o) => {
						if (o.condiments && o.condiments.length) {
							o.condiments.forEach((oo) => {
								if (oo.count > 0) {
									totalCondimentsWithDisc += (oo.subtotal - oo.discount);
									totalCondiments += oo.subtotal;
								}
							});
						}
					});
				}
			}
			if (this.order.with_discount) {
				return (totalCondimentsWithDisc + data.total_amount_with_discount).toFixed(2);
			} else {
				return (totalCondiments + data.total_amount).toFixed(2);
			}
		},
		changeQty(i, type) {
			if (type === 'plus') {
				if (i.legal_age_class === 1 && this.order.payers.length) {
					usersService.getSinglePosUser(this.order.payers[0].id).then((res) => {
						if (res) {
							if (res.intoxication_state) {
								this.openModal('confirmationModalRef', 'intoxicated_payer');
							} else {
								this.changeItemQtyBL(i, type);
							}
						}
					})
				} else {
					this.changeItemQtyBL(i, type);
				}
			} else {
				this.changeItemQtyBL(i, type);
			}
		},
		selectPrinter(el) {
			this.selectedPrinter = [];
			this.selectedPrinter.push(el);
		},
		seatFilterSelect(data) {
			this.filterBySeats = data;
		},
		addRound() {
			if (this.order.payers.length) {
				usersService.getSinglePosUser(this.order.payers[0].id).then((res) => {
					if (res) {
						if (res.intoxication_state) {
							this.openModal('confirmationModalRef', 'add_round_intoxicated');
						}
						this.emmiter.emit('updatePayerData', res);
						this.order.payers[0] = res;
						this.addRoundBL()
					}
				});
			} else {
				this.addRoundBL()
			}
		},
		deleteItem(index) {
			const dc = JSON.parse(JSON.stringify(this.order));
			this.selectedItem = dc.items[index];
			if (this.selectedItem.status !== 1) {
				this.openModal('itemDeletionNoticeModalRef')
			} else {
				this.deleteItemBL({
					type: 'update',
					id: this.selectedItem.id,
					orderId: this.order.id
				})
			}
		},
		houseAccountInputChange(e) {
			this.order['payment_house_account_number'] = e;
		},
		toggleDiscountBL(value) {
			if (this.order.id !== constants.tempOrderID) {
				console.log(value);
				console.log(this.order.id);
				console.log(constants.tempOrderID);
				const dc = JSON.parse(JSON.stringify(this.order));
				dc.with_discount = value;
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					this.handleAPIResponse(res.data, false);
				});
			}
		},
		toggleTaxBL(value) {
			const dc = JSON.parse(JSON.stringify(this.order));

		},
		toggleGratuityBL(value) {
			const dc = JSON.parse(JSON.stringify(this.order));

		},
		setPayerBL(data, type) {
			const dc = JSON.parse(JSON.stringify(this.order));
			let index = dc['payers'].findIndex((i) => {return i.id === data.id});
			if (index === -1) {
				if (type === 'add') {
					dc['payers'].push(data);
				} else {
					dc['payers'][0] = data;
					dc['payment_type'] = 1;
				};
				if (dc.id === constants.tempOrderID) {
					let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
					let index = tempOrders.findIndex((o) => {
						return o.tempId === dc.tempId
					});
					tempOrders.forEach((o) => {
						o.active = false;
					})
					if (index !== -1) {
						tempOrders[index] = dc;
						localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
						this.handleAPIResponse(dc, true,  true);
					}
				} else {
					orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
						if (res) {
							this.handleAPIResponse(res.data, true, true);
							this.$emit('updateOrder', res.data)
						}
					})
				}
			} else {
				this.openModal('confirmationModalRef', 'user_already_added');
				/*this.$refs['confirmationModalRef'].show({
					title: 'Restricted',
					message: 'Payers has intoxication state.',
					okButton: 'OK',
				});*/
				// this.handleAPIResponse(dc, false, true, false, false);
			}
		},
		deletePayerBl(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
			dc.payers.splice(data, 1);
			// if (!dc.payers.length) {
			// 	this.order.payment_type = 0;
			// };
			if (dc.id === constants.tempOrderID) {
				let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
				let index = tempOrders.findIndex((o) => {
					return o.tempId === dc.tempId
				});
				tempOrders.forEach((o) => {
					o.active = false;
				})
				if (index !== -1) {
					tempOrders[index] = dc;
					localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
					this.handleAPIResponse(dc, true, true);
				}
			} else {
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					if (res) {
						if (!res.data.payers.length) {
							res.data.payment_type = 0;
						};
						this.handleAPIResponse(res.data, true, true);
					}
				})
			}
			// this.check.payers = this.check.payers.filter((el) => el.id !== data.id);
		},
		toggleCPTBL(data) {
			this.order.payment_type = data;
			const dc = JSON.parse(JSON.stringify(this.order));
		},
		setWaiterBL(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
			dc['opened_by'] = data;
			if (dc.id === constants.tempOrderID) {
				let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
				let index = tempOrders.findIndex((o) => {
					return o.tempId === dc.tempId
				});
				if (index !== -1) {
					tempOrders[index] = dc;
					localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
					this.handleAPIResponse(dc, true, true);
				}
			} else {
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					if (res) {
						this.handleAPIResponse(res.data, true, true);
					}
				})
			}
		},
		cancelOrderBL(data) {
			if (data.id === constants.tempOrderID) {
				let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
				let index = tempOrders.findIndex((o) => {return o.tempId === this.order.tempId});
				if (index !== -1) {
					tempOrders.splice(index, 1);
					localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
					this.handleAPIResponse(data, true, true, 'remove');
				}
			} else {
				orderService.cancelOrder(data).then((res) => {
					this.handleAPIResponse(data, true, true, 'remove');
				})
			}
		},
		changeItemQtyBL(i, type) {
			const dc = JSON.parse(JSON.stringify(this.order));
			let index = dc.items.findIndex((obj) => {return obj.id === i.id});
			if (type === 'minus') {
				if (dc.items[index].count > 1) {
					dc.items[index].count -= 1;
				}
			} else {
				dc.items[index].count += 1;
			}
			if (index !== -1) {
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					this.handleAPIResponse(res.data, false, );
				})
			}
		},
		editItemBL(item) {
			const id = this.order.items.findIndex(el => el.id === item.id);
			const dc = JSON.parse(JSON.stringify(this.order));
			dc.items[id] = item;
			let hasCondiments = item.condiments_parsed.filter((i) => {return i.type === 2 && (i.checked ? i : false)});
			hasCondiments.forEach((o) => {
				let index1 = dc.items.findIndex((i) => {return (i.orderable_id === o.menu_item.id && i.orderable_type === o.menu_item.orderable_type) && i.seat === item.seat && i.status === 1});
				if (index1 !== -1) {
					dc.items[index1].count += o.count;
				} else {
					dc.items.push({
						orderable_id: o.menu_item.id,
						orderable_type: o.menu_item.orderable_type,
						from_menu: true,
						seat: item.seat,
						status: 1,
						count: o.count,
						condiments_parsed: []
					});
				}
				o.checked = false;
			});
			orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
				this.handleAPIResponse(res.data, true, );
			})
		},
		addItemBL(el) {
			const dc = JSON.parse(JSON.stringify(this.order));
			let index = dc.items.findIndex((i) => {return (i.orderable_id === el.orderable_id && i.orderable_type === el.orderable_type) && i.seat === el.seat && i.status === 1});
			let requiredCondiments = el.condiments_parsed.filter((i) => {return i.type === 1 ? i : false});
			let optionalCondiments = el.condiments_parsed.filter((i) => {return i.type === 2 && i.checked ? i : false});
			if (index !== -1) {
				if (requiredCondiments.length || el.modifiers_parsed.length) {
					el['remove_id'] = true;
					dc.items.push(el);
				} else {
					dc.items[index].count += el.count;
				}
			} else {
				el['remove_id'] = true;
				dc.items.push(el);
			}
			optionalCondiments.forEach((o) => { // IF OPTIONAL CONDIMENT IS CHECKED WE NEED TO FIND THIS CONDIMENT IN LIST OF ALREADY ADDED ITEMS, IF IT EXISTS THERE WITH STATUS=1 WE SIMPLY ADD 1 TO THE COUNT OF THIS ITEM, ELSE WE CREATE AND ADD THIS OPTIONAL CONDIMENT AS SEPARATE ITEM TO THE ORDER
				let index1 = dc.items.findIndex((i) => {return (i.orderable_id === o.menu_item.id && i.orderable_type === o.menu_item.orderable_type) /*&& i.seat === el.seat*/ && i.status === 1});
				if (index1 !== -1) {
					dc.items[index1].count += o.count;
				} else {
					dc.items.push({
						orderable_id: o.menu_item.id,
						orderable_type: o.menu_item.orderable_type,
						from_menu: true,
						seat: el.seat,
						status: 1,
						count: o.count,
						condiments_parsed: []
					});
				}
				o.checked = false;
			});
			if (dc.id === constants.tempOrderID) {
				orderService.createNewOrder(dc.id, formatDataService.formatItemsForAPI(dc.items, true), dc).then((res) => {
					if (res) {
						let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
						let index = tempOrders.findIndex((o) => {
							return o.tempId === dc.tempId
						});
						if (index !== -1) {
							let removedItem = tempOrders.splice(index, 1);
							localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
							/*this.getOrders({resetList: true, resetSelected: false}, res.data.id);*/
							this.handleAPIResponse(res.data, false, true, 'replace', removedItem);
						}
					}
				})
			} else {
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					this.handleAPIResponse(res.data, false);
				})
			}
		},
		deleteItemBL(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
			if (data.type === 'delete') {
				orderService.deleteItem({count: data.number, reason: data.text}, data.id, data.orderId).then((res) => {
					this.handleAPIResponse(res, true);
				})
			} else {
				let index = dc.items.findIndex((i) => {return i.id === data.id});
				dc.items.splice(index, 1);
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					this.handleAPIResponse(res.data, true);
				})
			}
		},
		addRoundBL() {
			const dc = JSON.parse(JSON.stringify(this.order));
			let payerState = null;
			if (dc.payers.length) {
				payerState = dc.payers[0].intoxication_state;
			}
			
			if (dc.items.length > 0) {
				let indexesToCopy = [];
				dc.items.forEach((o, index) => {
					if (o.status === 1) {
						if (!dc.payers.length) {
							o.count += 1;
						} else {
							if (!payerState || (payerState && o.legal_age_class !== 1)) {
								o.count += 1;
							}
						}
					} else {
						indexesToCopy.push({
							...o,
							id: null,
							status: 1,
							count: 1
						});
					}
				});
				if (indexesToCopy.length) {
					dc.items = [...this.order.items, ...indexesToCopy];
				}
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					this.handleAPIResponse(res.data,  false);
				})
			}
		},
		printMethodsBL(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
			if (dc.items.length > 0) {
				const method = data.type === 'runner-chit' ? printersService.printRunnerChit : printersService.printCheck
				method(dc.id).then((res) => {
					if (res) {
						orderService.getSingleOrder(dc.id).then(res => {
							if (res) {
								this.handleAPIResponse(res,  true);
							}
						})
					}
				})
			}
		},
		closeOrderBL(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
			if (data.payment_type === 2) {
				orderService.closeOrder(data).then((res) => {
					this.emmiter.emit('stopLoading');
					if (res) {
						this.handleAPIResponse(null,  true);
					}
				}, err => {
					this.emmiter.emit('stopLoading');
				})
			} else {
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					if (res) {
						orderService.closeOrder(data).then((res1) => {
							this.emmiter.emit('stopLoading');
							this.handleAPIResponse(dc,true, true, 'remove');
						}, err1 => {
							this.emmiter.emit('stopLoading');
						})
					}
				}, err => {
					this.emmiter.emit('stopLoading');
				})
			}
		},
		changeItemStatusBL(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
			let index = dc.items.findIndex((o) => {return o.id === data.id});
			if (index !== -1) {
				dc.items[index] = data;
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc).then((res) => {
					this.handleAPIResponse(res.data,  true);
				})
			}
		},
		changeOrderTableBL(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
			const prevTable = dc.tempId;
			dc.table = parseInt(data.table);
			dc.seat = data.seat;
			this.$forceUpdate();
			if (dc.id === constants.tempOrderID) {
				let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
				let index = tempOrders.findIndex((o) => {
					return o.tempId === prevTable
				});
				tempOrders[index] = dc;
				localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
				this.handleAPIResponse(dc,  true, true);
			} else {
				orderService.updateOrder(formatDataService.formatItemsForAPI(dc.items), dc, true).then((res) => {
					if (res) {
						// this.$forceUpdate();
						this.handleAPIResponse(res.data, true, true);
					}
				})
			}
			
		},
		reopenOrderBL(data) {
			if (this.$store.state.settings.businessDay.status !== 'rolled') {
				store.dispatch('alert/error', 'Business day is not in operation yet');
				return;
			}
			const dc = JSON.parse(JSON.stringify(this.order));
		},
		resetOrderBL(data) {
			const dc = JSON.parse(JSON.stringify(this.order));
		},
	},
	watch: {
		'tempOrder': {
			handler: function (data) {
				if (data === null) {
					this.order = null;
					return
				}
				if (data.id === constants.tempOrderID) {
					this.order = this.tempOrder;
				} else {
					if ((this.order && data.id !== this.order.id) || !this.order) {
						this.getOrder(data.id);
						if (this.$refs['seat-dd']) {
							this.filterBySeats = 'All';
							this.$refs['seat-dd'].refresh();
						}
					}
				}
			},
			deep: true
		}
	}
}
</script>

<style lang="less" scoped>

// SAFARI SUPPORT ONLY!
@supports (-webkit-hyphens:none) {
	.calc-wrapper {
		&:after {
			right: -2px !important;
		}
	}
}

#selected-order {
	
	// width: 35%;
	// width: 486px;
	// min-width: 500px;
	border-radius: 10px;
	box-shadow: 0 1px 3px -1px #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	// margin-right: 10px;
	.no-order-selected {
		font-weight: 400;
		font-size: 24px;
		line-height: 32px;
		// color: #e86d32;
		text-transform: uppercase;
	}
	
	.order {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: column;
		
		.order-details {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			flex: 1;
			box-sizing: border-box;
			padding: 10px;
			
			.col {
				width: 25%;
				/*&:not(:last-child) {
					margin-right: 10px;
				}*/
				
				&:first-child {
					width: 50%;
					margin-right: 3%;
					margin-bottom: 10px;
				}
				
				&:nth-child(2) {
					width: 22%;
					margin-right: 3%;
					margin-bottom: 10px;
				}
				
				&:nth-child(3) {
					width: 22%;
					margin-right: 0;
					margin-bottom: 10px;
				}
				
				&:nth-child(4) {
					margin-right: 3%;
					width: 50%;
				}
				
				&:nth-child(5) {
					width: 22%;
				}
				
				.title {
					color: #1c282d;
					font-size: 10px;
					font-weight: 600;
					text-align: left;
				}
				
				:deep(.custom-select) {
					width: auto;
					height: auto;
					line-height: 30px;
					
					.selected {
						border-color: lightgray !important;
						
						&.no-items {
							.selected-output {
								font-size: 10px;
							}
						}
						
						.selected-output {
							font-size: 18px;
							color: #1c282d;
							font-weight: 400;
						}
					}
					
					.items {
						border-color: lightgray !important;
						
						.item {
							.text {
								font-size: 18px;
								color: #1c282d;
								font-weight: 400;
							}
						}
					}
					
					input {
						width: 100%;
						height: auto;
						box-sizing: border-box;
					}
				}
				
				.col-data {
					position: relative;
					z-index: 2;
					border-radius: 4px;
					background: #ffffff;
					border: 1px solid lightgray;
					box-sizing: border-box;
					padding-left: 11px;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					width: auto;
					height: auto;
					line-height: 30px;
					padding-right: 20px;
					display: flex;
					
					span {
						font-size: 18px;
						color: #1c282d;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					
					.dropdown-icon {
						position: absolute;
						right: 7px;
						width: 7px;
						height: 7px;
						top: 50%;
						margin-top: -3.5px;
					}
					
					/*&:after {
						position: absolute;
						content: "▾";
						color: black;
						right: 1em;
						width: 6px;
						height: 6px;
						top: 0;
					}*/
					
					&.disabled {
						padding-right: 0px;
						background-color: transparent;
						
						&:after {
							content: '';
						}
					}
					
					&.not-specified {
						span {
							font-size: 10px;
						}
					}
				}
				
				&_more {
					height: 46px;
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
					
					.more_btn {
						width: 99px;
						height: 35px;
					}
				}
			}
		}
		
		.items-list {
			width: 100%;
			// min-height: 220px;
			// max-height: 220px;
			flex: 5;
			overflow-y: scroll;
			// margin-bottom: 10px;
			background-color: #ffffff;
			position: relative;
			// &:after {
			// 	content: '';
			// 	width: 2px;
			// 	height: 100%;
			// 	background-color: #e5e5e5;
			// 	position: absolute;
			// 	left: 55%;
			// 	top: 0;
			// 	// box-shadow: 1px 0 2px 0 #c3c3c3;
			// }
			table {
				width: 100%;
				table-layout: fixed;
				margin: 0;
				border-collapse: collapse;
				box-sizing: border-box;
				position: relative;
				
				tr {
					&:nth-child(odd) {
						background-color: #FAF9FB;
					}
					
					th, td {
						&:nth-child(4) {
							position: relative;
							/*&:after {
								content: '';
								width: 2px;
								height: 100%;
								background-color: #e5e5e5;
								position: absolute;
								right: 5px;
								top: 0;
								// box-shadow: 1px 0 2px 0 #c3c3c3;
							}*/
						}
					}
				}
				
				th {
					border-top: 2px solid #e9ecef;
					border-bottom: 2px solid #e9ecef;
					vertical-align: middle;
					height: 30px;
					padding: 5px 10px;
					overflow-wrap: break-word;
					color: #14191f;
					font-size: 10px;
					font-weight: 700;
					text-align: center;
					text-transform: uppercase;
					
					&.left {
						text-align: left;
					}
					
					&:nth-child(1) {
						//width: 25% !important;
						width: 26% !important;
						text-align: center;
						cursor: pointer;
					}
					
					&:nth-child(2) {
						//width: 10% !important;
						width: 11% !important;
					}
					
					&:nth-child(3) {
						width: 18.5% !important;
						//width: 20% !important;
					}
					
					&:nth-child(4) {
						width: 11% !important;
						//width: 10% !important;
					}
					
					&:nth-child(5) {
						width: 11% !important;
						//width: 10% !important;
					}
					
					&:nth-child(6) {
						//width: 8% !important;
						width: 8.5% !important;
					}
					
					&:nth-child(7) {
						//width: 8% !important;
						width: 5.5% !important;
					}
					
					&:nth-child(8) {
						width: 8.5% !important;
						//width: 9% !important;
					}
				}
				
				td {
					vertical-align: middle;
					height: auto;
					padding: 4px 2px;
					overflow-wrap: break-word;
					color: #1a141f;
					font-size: 14px;
					text-align: center;
					
					&:first-child {
						span {
							padding-left: 4px;
							text-align: left;
							cursor: pointer;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							line-clamp: 3;
							-webkit-box-orient: vertical;
						}
					}
					
					.state {
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						
						.status {
							background-color: red;
							
							&.status-1 {
								background-color: #EB262B;
							}
							
							&.status-2 {
								background-color: #FFCB05;
							}
							
							&.status-3 {
								background-color: #82B941;
							}
						}
						
						.circle {
							height: 12px;
							width: 12px;
							border-radius: 100%;
						}
						
						.text {
							font-weight: normal;
							font-size: 12px;
							line-height: 148%;
							align-items: center;
							color: #1a141f;
							margin-left: 8px;
						}
						
					}
					
					&:nth-child(3) {
						padding: 5px 0;
					}
					&.item-discount {
						span {
							border: 1px solid #D9E8F3;
							border-radius: 8px;
							padding: 4px 4px 4px 4px;
							display: flex;
							text-align: right;
							justify-content: flex-end;
							align-items: center;
							width: 70%;
							margin: 0 0 0 auto;
						}
					}
					&.qty-td {
						div {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							flex-wrap: nowrap;
							width: 80%;
							margin: 0 auto;
							
							span {
								padding: 0 10px;
								margin: 0 auto;
							}
							
							img {
								cursor: pointer;
							}
						}
					}
					
					&.del {
						text-align: center;
						
						button {
							width: 36px;
							height: 32px;
							background: #FFFFFF;
							border: 1px solid #D9E8F3;
							border-radius: 8px;
							cursor: pointer;
							display: flex;
							justify-content: center;
							align-items: center;
							margin: 0 auto;
							img {
								width: 14px;
								height: 18px;
							}
						}
					}
				}
			}
			
			&::-webkit-scrollbar {
				display: none;
			}
		}
		
		.order-calculations {
			width: 100%;
			flex: 1;
			box-sizing: border-box;
			padding: 0;
			border-radius: 8px;
			background-color: #fff;
			border-top: 1px solid #E5E0EB;
			
			.calc-wrapper {
				width: 100%;
				position: relative;
				// &:after {
				// 	content: '';
				// 	width: 2px;
				// 	height: 100%;
				// 	background-color: #e5e5e5;
				// 	position: absolute;
				// 	right: 0;
				// 	top: 0;
				// 	// box-shadow: 1px 0 2px 0 #c3c3c3;
				// }
				// &:before {
				// 	content: '';
				// 	width: 100%;
				// 	height: 2px;
				// 	background-color: #e5e5e5;
				// 	position: absolute;
				// 	left: -1px;
				// 	bottom: -2px;
				// }
				.row {
					width: 85%;
					height: 35px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: nowrap;
					padding: 0 0 0 35%;
					box-sizing: border-box;
					background-color: white;
					position: relative;
					
					.discount-details {
						position: absolute;
						font-size: 14px;
						height: 30px;
						right: -180px;
					}
					
					.switch {
						position: absolute;
						width: 100px;
						height: 26px;
						left: calc((35% - 100px) / 2);
						top: 50%;
						margin-top: -13px;
						
						.slider {
							position: absolute;
							cursor: pointer;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							background-color: #cccccc;
							box-shadow: 0 0 1px #3f8ec5;
							border-radius: 34px;
							font-weight: 600;
							font-size: 12px;
							display: flex;
							align-items: center;
							text-transform: uppercase;
							justify-content: flex-end;
							padding-right: 10px;
							-webkit-transition: .5s;
							transition: .5s;
							
							&.active {
								color: #ffffff !important;
								justify-content: flex-start !important;
								padding-left: 10px;
								background: #3f8ec5;
								
								&:before {
									-webkit-transform: translateX(72px);
									-ms-transform: translateX(72px);
									transform: translateX(72px);
								}
							}
							
							&:before {
								position: absolute;
								content: "";
								height: 19px;
								width: 19px;
								left: 4px;
								bottom: 4px;
								border-radius: 50%;
								background-color: white;
								-webkit-transition: .4s;
								transition: .4s;
							}
						}
					}
					
					&:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
					
					&:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
					
					&:last-child {
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					}
					
					&.total {
						border-top-width: 2px;
						
						div {
							font-weight: 700;
						}
					}
					
					div {
						text-align: right;
						text-transform: uppercase;
						color: #14191f;
						font-size: 14px;
						padding: 5px 0;
					}
					
					.title {
						text-align: left;
						width: 60%;
						border-top: 1px solid #e3e3e3;
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						line-height: 15px;
						text-transform: uppercase;
						color: #1C282D;
						
						&_first {
							border-top: none;
						}
					}
					
					.amount {
						width: 40%;
						border-top: 1px solid #e3e3e3;
						padding-right: 15px;
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						line-height: 15px;
						text-transform: uppercase;
						color: #1C282D;
						
						&_first {
							border-top: none;
						}
					}
					
					.autoGratuity {
						text-decoration-line: line-through;
					}
				}
			}
		}
		
		.controls {
			width: calc(100% - 20px);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			padding: 10px;
			box-sizing: border-box;
			flex: 1;
			
			.row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: nowrap;
				
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				
				&.x3 {
					button {
						width: 32%;
						height: 36px;
					}
				}
				
				&.x2 {
					button {
						width: 49%;
					}
				}
			}
		}
	}
	
	.def-btn {
		padding: 10px 2px;
	}
}

@media (max-width: 1200px) {
	#selected-order {
		min-width: 440px;
		
		.order {
			.items-list {
				table {
					th {
						height: 30px;
						padding: 0 6px;
						font-size: 7px;
						
						&:nth-child(1) {
							//width: 25% !important;
							width: 26% !important;
							text-align: center;
							cursor: pointer;
						}
						
						&:nth-child(2) {
							//width: 10% !important;
							width: 11% !important;
						}
						
						&:nth-child(3) {
							width: 18.5% !important;
							//width: 20% !important;
						}
						
						&:nth-child(4) {
							width: 11% !important;
							//width: 10% !important;
						}
						
						&:nth-child(5) {
							width: 11% !important;
							//width: 10% !important;
						}
						
						&:nth-child(6) {
							//width: 8% !important;
							width: 8.5% !important;
						}
						
						&:nth-child(7) {
							//width: 8% !important;
							width: 5.5% !important;
						}
						
						&:nth-child(8) {
							width: 8.5% !important;
							//width: 9% !important;
						}
					}
					
					td {
						font-size: 12px;
						height: 30px;
						padding: 0 6px;
						
						&.qty-td {
							padding: 0;
						}
					}
				}
			}
			
			.order-calculations {
				.calc-wrapper {
					.row {
						.switch {
							width: 80px;
							
							.slider {
								font-size: 8px;
								padding-right: 4px;
								
								&.active {
									padding-left: 7px;
									
									&:before {
										-webkit-transform: translateX(55px);
										-ms-transform: translateX(55px);
										transform: translateX(55px);
									}
								}
								
								&:before {
									left: 3px;
								}
							}
						}
						
						.discount-details {
							font-size: 10px;
						}
						
						div {
							font-size: 12px;
						}
					}
				}
			}
			
			.controls {
				button {
					font-size: 13px;
					height: 40px;
					padding: 5px 10px;
				}
			}
		}
		
	}
}

.loading {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/*@media (max-width: 850px) {
	#selected-order {
		min-width: 480px;
	}
}*/

#check-details-wrapper {
	position: fixed;
	left: 0;
	top: 84px;
	padding-left: 8px;
	width: 100%;
	height: calc(100vh - 84px);
	background-color: #ECF4F9;
	z-index: 999;
	img {
		position: absolute;
		top: 14px;
		right: 14px;
		width: 28px;
		cursor: pointer;
	}
	
}

@media (max-width: 1200px) {
	#check-details-wrapper {
		top: 70px;
		height: calc(100vh - 70px);
	}
}

.switch {
		--toggle-width: 97px;
		--toggle-height: 23px;
		--toggle-border: 0.1rem;
		--toggle-font-size: 0.75rem;
		--toggle-duration: 150ms;
		--toggle-bg-on: #3F8EC5;
		--toggle-bg-off: #ccc;
		--toggle-bg-on-disabled: #d1d5db;
		--toggle-bg-off-disabled: #e5e7eb;
		--toggle-border-on: #3F8EC5;
		--toggle-border-off: #e5e7eb;
		--toggle-border-on-disabled: #d1d5db;
		--toggle-border-off-disabled: #e5e7eb;
		--toggle-ring-width: 3px;
		--toggle-ring-color: transparent;
		--toggle-text-on: #ffffff;
		--toggle-text-off: #eb262b;
		--toggle-text-on-disabled: #9ca3af;
		--toggle-text-off-disabled: #9ca3af;
		--toggle-handle-enabled: #ffffff;
		--toggle-handle-disabled: #f3f4f6;
	}
</style>
