<template>
	<transition name="fade-scale">
		<div id="modal" ref="modal" :class="['modal', 'c-modal-backdrop-wr', {'keyboard-on': hasKeyboard},
		customClass]"
		     v-if="isVisible" @keydown.esc="close" tabindex="0">
			<div :class="['c-modal', {'customTop': customTop}]">
				<div :class="!customBot ? 'window' : 'window window_cb'" :style="{background: anotherBackground ? '#F5F9FC' : 'white'}">
					<icon-component iconName="close" class="close-btn" @click="close" />
					<slot></slot>
				</div>
				<keyboard v-if="hasKeyboard" :style="[showKeyboard ? 'visibility: visible' : 'visibility: hidden']" ref="keyboard" id="keyboard" :has-close-btn="false" @clickOnKey="click" @clear="click"></keyboard>
			</div>
			<div class="c-modal-backdrop"></div>
		</div>
	</transition>
	<confirmation-modal-component ref="confirmation-modal"></confirmation-modal-component>
</template>

<script>
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import Keyboard from "@/components/_shared/Keyboard";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'ModalComponent',
	props: {
		anotherBackground:{
				type:Boolean,
				default: false
		},
		hasKeyboard: {
			type: Boolean,
			default: false,
			required: false
		},
		showKeyboard: {
			type: Boolean,
			default: true,
			required: false
		},
		customClass: {
			type: String,
			default: '',
			required: false
		},
		customTop: {
			type: Boolean,
			default: false,
			required: false
		},
		customBot: {
			type: Boolean,
			default: false,
			required: false
		},
	},
	components: {Keyboard, ConfirmationModalComponent, iconComponent},
	data() {
		return {
			isVisible: false,
			title: undefined,
			message: undefined,
			okButton: undefined,
			cancelButton: 'Нет',

			// Private variables
			resolvePromise: undefined,
			rejectPromise: undefined,
		}
	},
	emits: ['onModalClose', 'clickOnKey'],
	computed: {
		design() {
			return this.$store.state.UI
		},
	},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		click(e) {
			this.$emit('clickOnKey', e)
		},
		escListener(e) {
			if (e.code === 'Escape') {
				this.close();
			}
		},
		open() {
			this.isVisible = true;
			this.$nextTick(() => {
				const mo = this.$refs['modal'];
				mo.focus();
			})
			document.addEventListener('keydown', this.escListener);
		},
		close() {
			this.isVisible = false;
			document.removeEventListener('keydown', this.escListener);
			setTimeout(() => {
				this.$emit('onModalClose');
			}, 300)
		},
		show(opts = {}) {
			this.title = opts.title;
			this.message = opts.message;
			this.okButton = opts.okButton;
			if (opts.cancelButton) {
				this.cancelButton = opts.cancelButton
			}
			// Once we set our config, we tell the popup modal to open
			this.open();
			// Return promise so the caller can get results
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			})
		},

		_confirm() {
			this.close();
			this.resolvePromise(true);
		},

		_cancel() {
			this.close();
			this.resolvePromise(false);
			// Or you can throw an error
			// this.rejectPromise(new Error('User cancelled the dialogue'))
		},
	},
	watch: {
		isVisible: function(res) {
			if (res) {
				document.body.classList.add("modal-open");
			} else {
				setTimeout(() => {
					document.body.classList.remove("modal-open");
				}, 300)
			}
		},
	}
}
</script>

<style lang="less" scoped>
/* css class for the transition */
.fade-scale-enter-active, .fade-scale-leave-active {
	transition: all 0.3s;
	.window {
		transition: all 0.3s;
	}
}
.fade-scale-enter-from,
.fade-scale-leave-to {
	opacity: 0;
	.window {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}

.c-modal-backdrop-wr {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	&.keyboard-on {
		.c-modal {
			flex-direction: row;
			flex-wrap: wrap;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.window {
				margin-bottom: 30px;
				&_cb {
					margin-bottom: 0;
				}
			}
		}
	}
	.c-modal {
		overflow-x: auto;
		display: flex;
		flex-direction: column;
		z-index: 1041;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		.window {
            background: #FFFFFF;
            border-radius: 5px;
			box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
			// width: 800px;
			height: auto;
			min-height: 290px;
			margin-left: auto;
			margin-right: auto;
			//padding: 15px;
			padding: 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			flex-wrap: wrap;
			position: relative;
			box-sizing: border-box;
			z-index: 999;
			.close-btn {
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
				z-index: 999;
			}
			:deep(.modal-container) {
				.modal-header {
					font-style: normal;
					font-weight: 400;
					font-size: 42px;
					line-height: 56px;
					// color: #3F8EC5;
					margin-bottom: 20px;
				}
				.modal-controls {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: nowrap;
					&.x1 {
						justify-content: center;
						button {
							width: 50%;
						}
					}
					&.x2 {
						button {
							width: 47%;
						}
					}
					&.x3 {
						button {
							width: 30%;
						}
					}
				}
			}
		}
		#keyboard {
			position: relative;
		}
	}
	.customTop {
		justify-content: flex-start;
		margin-top: calc((100vh - 955px));
	}
	.c-modal-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1040;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0;
	}
}
</style>
