<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" :custom-class="'add-edit-item-modal-wrapper'" :has-keyboard="true" :show-keyboard="true" @clickOnKey="clickOnKey" @onModalClose="onModalClose">
			<div id="add-edit-item-modal" class="modal-container">
				<div class="modal-header">
					{{ action === 'add' ? 'Add new item' : 'Edit item' }}
				</div>
				<div class="block top-block">
					<div v-if="!showImagesValue" class="image-name-wrapper no-images">
						<div class="no-images_item__1">
							<icon-component v-if="design === 'ecruise'" :iconName="!selectedItem.combo ? 'menuItem' : 'menuItemCombo'" />
						</div>
						<!-- <img v-else-if="design === 'margaritaville'" :src="deepCopy.photo_url || require('../../assets/img/72category.svg')" alt=""> -->
						<div class="top-block-text no-images_item__2">{{ deepCopy.name }}</div>
					</div>
					<div v-if="showImagesValue" class="image-name-wrapper">
						<icon-component :iconName="deepCopy.photo_url || 'menuCategory'" />
						<div class="top-block-text">{{ deepCopy.name }}</div>
					</div>
					<div class="block quantity-block">
						<div class="text quantity-text">Quantity:</div>
						<div class="changer">
							<button @click="changeItemCount(null)">
								<icon-component v-if="deepCopy.status === 1" iconName="minusBig" />
							</button>
							<span>{{ count }}</span>
							<button @click="changeItemCount('increment')">
								<icon-component v-if="deepCopy.status === 1" iconName="plusBig" />
							</button>
						</div>
					</div>
				</div>
				<div class="block price-block">
					<div class="text price-text">Price:</div>
					<div class="price">${{ parseFloat(deepCopy.price).toFixed(2) }}</div>
				</div>
				
				<div class="left-right-wrapper">
					<div class="left-content">
						<div class="condiments">
							<div class="condiments-title">
								Condiment(s):
							</div>
							<div v-if="deepCopy.condiments_parsed && deepCopy.condiments_parsed.length" class="condiments-list-wrapper">
								<div v-for="(i,index) in deepCopy.condiments_parsed" class="condiments-item">
									<div class="item-content">
										<div class="left" @click="i.type === 2 ? checkItems(i, false) : null">
											<icon-component v-if="i.type === 1" iconName="checkboxCheckedBg" class="checkbox-image" />
											<icon-component v-else :iconName="i.checked ? 'checkboxChecked' : 'checkboxUnchecked'" class="checkbox-image" />
											<div class="item-title">{{ i.name }}</div>
										</div>
										<div class="right">
											<div v-if="i.type === 2" class="counter">
												<button :class="{'disabled': !i.checked}" @click="i.checked ? changeCondimentsCount(i, 'minus') : null">
													<icon-component iconName="minusBig" />
												</button>
												<span>{{ i.count }}</span>
												<button :class="{'disabled': !i.checked}" @click="i.checked ? changeCondimentsCount(i, 'plus') : null">
													<icon-component iconName="plusBig" />
												</button>
											</div>
											<div v-if="i.type === 2" :class="{'not-selected': !i.checked && i.type === 2}" class="price">
												{{ (i.price) ? '$' + parseFloat(i.price).toFixed(2) : '' }}
											</div>
										</div>
									</div>
									<div v-if="i.type === 1" class="sub-item-content">
										<div v-for="ii in i.items" :class="{'disabled': deepCopy.status !== 1}" class="sub-item" @click="deepCopy.status === 1 ? checkItems(i, ii) : null">
											<div class="left">
												<icon-component :iconName="ii.checked ? 'radioOn' : 'radioOff'" class="sub-item-radio" />
												<div class="sub-item-title">
													{{ ii.name }}
												</div>
											</div>
											<div class="right">
												<div class="sub-item-count">
													x{{ ii.count }}
												</div>
												<div :class="{'not-selected': !ii.checked}" class="sub-item-price">
													${{ ii.price ? ii.price.toFixed(2) : '' }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else class="condiments-list-wrapper no-condiments">
								<div class="text">No condiments</div>
							</div>
						</div>
						<div
								v-if="action !== 'add'"
								:class="{'has-border': !deepCopy.condiments_parsed || !deepCopy.condiments_parsed.length}"
								class="block discount-block"
						>
							<div class="text discount:-text">Discount:</div>
							<div class="discount">{{ '$' + calcDiscount }}</div>
						</div>
						<div class="block total-block">
							<div class="text total-text">total:</div>
							<div class="total">${{ (calcTotal - calcDiscount).toFixed(2) }}</div>
						</div>
					</div>
					<div class="right-content">
						<div class="modifiers">
							<div class="modifiers-title">
								Modifiers(s):
							</div>
							<div v-if="deepCopy.modifiers_parsed && deepCopy.modifiers_parsed.length" class="modifiers-list-wrapper">
								<div v-for="(i,index) in deepCopy.modifiers_parsed" class="modifiers-item">
									<div class="item-content">
										<div :class="{'disabled': deepCopy.status !== 1}" class="left" @click="deepCopy.status === 1 ? (!i.is_required ? checkItemsModifier(i, false) : null ) : null">
											<icon-component v-if="i.is_required" iconName="checkboxCheckedBg" class="checkbox-image" />
											<icon-component v-if="!i.is_required && !i.options.length && !i.has_free_text" :iconName="i.checked ? 'checkboxChecked' : 'checkboxUnchecked'" class="checkbox-image" />
											<icon-component v-if="!i.is_required && !i.is_multiple && i.options.length" :iconName="i.checked ? 'checkboxChecked' : 'checkboxUnchecked'" class="checkbox-image" />
											<div class="item-title">{{ i.name }}</div>
										</div>
									</div>
									<div class="sub-item-content">
										<div v-for="(ii, child_index) in i.options" :class="{'disabled': deepCopy.status !== 1}" class="sub-item" @click="deepCopy.status === 1 ? checkItemsModifier(i, ii) : null">
											<div class="left">
												<icon-component v-if="(!i.is_required && !i.is_multiple) || (i.is_required && !i.is_multiple)" :iconName="ii.checked ? 'radioOn' : 'radioOff'" class="sub-item-radio" />
												<icon-component v-if="(!i.is_required && i.is_multiple) || (i.is_required && i.is_multiple)" :iconName="ii.checked ? 'checkboxChecked' : 'checkboxUnchecked'" class="sub-item-radio" />
												<div v-if="ii.id !== null" class="sub-item-title">
													{{ ii.name }}
												</div>
												<input v-if="ii.id === null" v-model="ii.name" :class="{'disabled': deepCopy.status !== 1}" :disabled="deepCopy.status !== 1" class="item-input" placeholder="Client`s wishes" @click="setInputFocus(index, child_index)" @keyup="checkInputItem(index, child_index, ii.name)"/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else class="modifiers-list-wrapper no-modifiers">
								<div class="text">No modifiers</div>
								<!--<div class="item-wrapper">
									<div class="item-title">Recepie changes:</div>
									<div class="item">
										<img class="sub-item-checkbox" :src="recipeChanges.checked ? require('../../assets/img/split-order-checkbox-on.svg') : require('../../assets/img/split-order-checkbox-off.svg')" alt="checkbox-image" @click="toggleRecipeChanges">
										<input class="item-input" placeholder="Client`s wishes" v-model="recipeChanges.text" :disabled="!recipeChanges.checked"/>
									</div>
								</div>-->
							</div>
						</div>
						<div class="block timer-block ">
							<div class="text seat-text">Postpone:</div>
							<div class="changer">
								<button v-if="deepCopy.status === 1" @click="setPostponeTimer('decrease')">
									<icon-component iconName="minusBig" />
								</button>
								<span>{{ postponeTimer }}</span>
								<button v-if="deepCopy.status === 1" @click="setPostponeTimer('increase')">
									<icon-component iconName="plusBig" />
								</button>
							</div>
						</div>
						<div class="block seat-block ">
							<div class="text seat-text">seat:</div>
							<div class="changer">
								<button v-if="selectedOrder.table" @click="changeSeatNumber('decrease')">
									<icon-component iconName="minusBig" />
								</button>
								<!--<input type="number" v-model="seat" step="1" min="1" max="100" id="age"/>-->
								<span>{{ selectedOrder.table ? seat : 'N/A' }}</span>
								<button v-if="selectedOrder.table" @click="changeSeatNumber('increase')">
									<icon-component iconName="plusBig" />
								</button>
							</div>
						</div>
					</div>
				</div>
				<!--v-if="action !== 'add'"-->
				
				<div :class="['modal-controls', (action === 'add' || isWaiterAssist || selectedOrder.items.length <= 1) ? 'add' : '', (action==='edit' && !isWaiterAssist && selectedOrder.items.length > 1) ? 'x3' : 'x2']">
					<base-button v-if="action==='edit' && !isWaiterAssist && selectedOrder.items.length > 1" text="Delete" classes="def-btn delete-action-btn uppercase medium border-2" @onClick="delItem"/>
					<base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
					<base-button :text="action === 'add' ? 'ADD ITEM' : 'CONFIRM'" :isDisabled="!ifEveryRequiredModifierIsChecked" classes="def-btn main-action-btn uppercase medium border-2" @onClick="addEditItem"/>
				</div>
			</div>
		</modal-component>
		<item-deletion-notice-modal
				v-if="showDelNoticeModal"
				ref="item-deletion-notice-modal"
				:selected-order="selectedOrder"
				:selectedItem="deepCopy"
		>
		</item-deletion-notice-modal>
		<confirmation-modal-component ref="confirmation-modal-component" :has-cancel-btn="false"/>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import ItemDeletionNoticeModal from "@/components/_modals/ItemDeletionNoticeModal";
import CancelOrderModal from "@/components/_modals/CancelOrderModal";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import {usersService} from "@/_api/users.api";
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'AddEditItemModal',
	components: {ConfirmationModalComponent, ModalComponent, ItemDeletionNoticeModal, CancelOrderModal, BaseButton, iconComponent},
	props: {
		selectedOrder: {
			type: Object,
			require: true
		},
		selectedItem: {
			type: Object,
			require: true
		},
		action: {
			type: String,
			require: true,
			default: 'add'
		},
	},
	emits: ['addEditItem', 'onModalClose', 'deleteItem'],
	data() {
		return {
			seat: null,
			count: 1,
			postponeTimer: 0,
			showCancelOrderModal: false,
			showDelNoticeModal: false,
			inputInFocus: null,
			deepCopy: null,
		}
	},
	
	computed: {
		showImagesValue() {
			return this.$store.state.settings.showImages;
		},
		design() {
			return this.$store.state.UI;
		},
		total() {
			return ((this.deepCopy.count * this.deepCopy.price) - (this.deepCopy.discount ? this.deepCopy.discount : 0)).toFixed(2);
		},
		isWaiterAssist() {
			return this.$store.state.auth.user.user.role === 'waiter assist';
		},
		calcTotal() {
			let totalCondiments = 0;
			if (this.deepCopy.condiments_parsed && this.deepCopy.condiments_parsed.length) {
				this.deepCopy.condiments_parsed.forEach((o) => {
					let filterChecked = o.items.filter(o => o.checked);
					if (filterChecked.length) {
						filterChecked.forEach((oo) => {
							totalCondiments += (oo.count * this.count) * oo.price;
						})
					}
				})
			}
			return ((this.deepCopy.price * this.count) + totalCondiments).toFixed(2);
		},
		calcDiscount() {
			if (this.action === 'add') {
				return '0.00';
			}
			if (this.deepCopy.percentage_discount) {
				return ((this.deepCopy.price * this.count) * this.deepCopy.percentage_discount / 100).toFixed(2);
			} else if (this.deepCopy.amount_discount) {
				return ((this.deepCopy.price * this.count) - this.deepCopy.amount_discount).toFixed(2);
			} else {
				if (this.deepCopy.combo) {
					let totalCondimentsPers = 1;
					if (this.deepCopy.condiments && this.deepCopy.condiments.length) {
						totalCondimentsPers = (this.deepCopy.condiments[0].price / (this.deepCopy.condiments[0].discount / this.deepCopy.condiments[0].count));
					} else {
						if (this.deepCopy.items && this.deepCopy.items.length) {
							this.deepCopy.items.forEach((o) => {
								if (o.condiments && o.condiments.length) {
									totalCondimentsPers = o.condiments[0].price / (o.condiments[0].discount / o.condiments[0].count);
								}
							});
						}
					}
					let totalDisc = 0;
					if (this.deepCopy.condiments_parsed && this.deepCopy.condiments_parsed.length) {
						this.deepCopy.condiments_parsed.forEach((o) => {
							let filterChecked = o.items.filter(o => o.checked);
							if (filterChecked.length) {
								filterChecked.forEach((oo) => {
									totalDisc += (oo.count * oo.price) / totalCondimentsPers;
								})
							}
						})
					}
					if (this.selectedOrder.with_discount) {
						return ((totalDisc + (this.deepCopy.subtotal - this.deepCopy.total) / this.deepCopy.count) *
								this.count).toFixed(2);
					} else {
						return '0.00';
					}
				} else {
					if (this.selectedOrder.with_discount) {
						let condimentsDiscountPercentage = 1;
						let condimentsDiscount = 0;
						if (this.deepCopy.condiments && this.deepCopy.condiments.length) {
							condimentsDiscountPercentage = (this.deepCopy.condiments[0].price / (this.deepCopy.condiments[0].discount / this.deepCopy.condiments[0].count)).toFixed(2);
							this.deepCopy.condiments_parsed.forEach((o) => {
								let filterChecked = o.items.filter(o => o.checked);
								if (filterChecked.length) {
									filterChecked.forEach((oo) => {
										condimentsDiscount += ((oo.count * oo.price) / condimentsDiscountPercentage);
									});
								}
							});
						}
						if (this.deepCopy.subtotal > this.deepCopy.total) {
							let itemDiscount = (this.deepCopy.subtotal - this.deepCopy.total) / this.deepCopy.count;
							return ((itemDiscount + condimentsDiscount) * this.count).toFixed(2);
						} else {
							if (!this.deepCopy.condiments.length || this.deepCopy.condiments[0].discount === 0) {
								return '0.00';
							} else {
								return ((condimentsDiscount) * this.count).toFixed(2);
							}
						}
					} else {
						return '0.00';
					}
				}
			}
		},
		ifEveryRequiredModifierIsChecked() {
			const requiredModifiers = this.deepCopy.modifiers_parsed.filter(o => o.is_required);
			return requiredModifiers.every(o => o.options.some(oo => oo.checked));
		},
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			if (this.$refs['modal']) {
				this.close();
			}
		});
	},
	beforeUnmount() {
		this.showDelNoticeModal = false;
		this.emmiter.off('closeModal');
		this.emmiter.emit('toggleModals', {opened: false, type: 'AddEditItemModal'});
	},
	methods: {
		onModalClose() {
			this.seat = null;
			this.count = 1;
			this.showCancelOrderModal = false;
			this.inputInFocus = null;
			this.emmiter.emit('toggleModals', {opened: false, type: 'AddEditItemModal'});
			this.$emit('onModalClose');
		},
		openModal() {
			this.deepCopy = Object.assign({}, this.selectedItem);
			if (this.deepCopy.seat) {
				this.seat = this.deepCopy.seat;
			} else {
				this.seat = this.selectedOrder.seat;
			}
			this.postponeTimer = this.deepCopy.print_in_min ? this.deepCopy.print_in_min : 0
			this.count = this.deepCopy.count;
			this.$refs['modal'].show();
			this.emmiter.emit('toggleModals', {opened: true, type: 'AddEditItemModal'});
		},
		openConfirmationModal() {
			this.$refs['confirmation-modal-component'].show({
				title: 'Restricted',
				message: 'Ordering this menu item has age restrictions.',
				okButton: 'OK',
			})
		},
		openConfirmationModifiersModal() {
			this.$refs['confirmation-modal-component'].show({
				title: 'Attention',
				message: 'You should select all required modifiers before continuing.',
				okButton: 'OK',
			})
		},
		addEditItem() {
			if (!this.ifEveryRequiredModifierIsChecked) {
				this.openConfirmationModifiersModal();
			} else {
				let newItems = {
					...this.deepCopy,
					count: this.count,
					seat: this.seat,
					total: this.total,
					print_in_min: this.postponeTimer
				};
				this.$emit('addEditItem', newItems);
				this.close();
			}
		},
		close() {
			this.$refs['modal'].close()
			this.seat = 1;
			this.count = 1;
		},
		delItem() {
			if (this.deepCopy.status !== 1) {
				this.showDelNoticeModal = true;
				this.$nextTick(() => {
					this.$refs['item-deletion-notice-modal'].openModal();
				})
			} else {
				/*this.emmiter.emit('deleteItem', {
					type: 'update',
					id: this.deepCopy.id,
					orderId: this.selectedOrder.id
				});*/
				this.$emit('deleteItem', {
					type: 'update',
					id: this.deepCopy.id,
					orderId: this.selectedOrder.id
				})
			}
		},
		changeSeatNumber(type) {
			if (type === 'decrease') {
				this.seat > 1 ? this.seat -= 1 : null;
			} else {
				this.seat += 1;
			}
		},
		setPostponeTimer(type) {
			if (type === 'increase') {
				this.postponeTimer += 1;
			} else {
				if (this.postponeTimer) {
					this.postponeTimer -= 1;
				}
			}
		},
		checkItems(parent, child) {
			if (child) {
				this.deepCopy.condiments_parsed.forEach((o) => {
					if (parent.name === o.name) {
						o.items.forEach(oo => {
							o['checked'] = false;
							oo['checked'] = false;
							if (oo.menu_item_condiment_id === child.menu_item_condiment_id) {
								o.checked = true;
								oo.checked = true;
							}
						})
					}
				})
			} else {
				parent.checked = !parent.checked;
				if (!parent.checked) {
					parent.items.forEach((o) => {
						o.checked = false;
					})
				} else {
					parent.items[0].checked = true;
				}
			}
		},
		checkInputItem(parent, child, data) {
			let t = this.deepCopy.modifiers_parsed[parent];
			t.options[child].checked = data.length;
			if (t.options.length === 1) {
				t.checked = data.length;
			}
			if (t.options[child].checked) {
				if ((t.is_required && !t.is_multiple) || !t.is_required && !t.is_multiple) {
					t.options.forEach(o => {
						if (o.id !== null) {
							o.checked = false;
						}
					})
				}
			} else {
				if (t.is_required && !t.is_multiple || t.is_required && t.is_multiple && t.options.length > 1 || (!t.is_required && !t.is_multiple && t.options.length > 1)) {
					t.options[0].checked = true;
				}
			}
		},
		checkItemsModifier(parent, child) {
			if (!child) {
				parent['checked'] = !parent['checked'];
			}
			parent.options.map((o) => {
				if (child.id !== null) {
					if (o.id === child.id) {
						if (parent.is_required && !parent.is_multiple) {
							o['checked'] = true;
						} else if (parent.is_required && parent.is_multiple) {
							if (parent.options.filter(o => o.checked).length > 1) {
								o['checked'] = !o['checked'];
							} else {
								o['checked'] = true;
							}
						} else {
							o['checked'] = !o['checked'];
							parent['checked'] = !parent.options.every((o) => {return !o.checked});
						}
					} else {
						if ((parent.is_required && !parent.is_multiple) || !parent.is_multiple) {
							o['checked'] = false;
						}
					}
				}
				return o;
			})
		},
		changeCondimentsCount(data, type) {
			if (type === 'minus') {
				data.count > 1 ? data.count-- : null;
			} else {
				data.count++;
				this.$forceUpdate();
			}
		},
		setInputFocus(parent, child) {
			this.inputInFocus = {
				parent: parent,
				child: child
			};
		},
		clickOnKey(e) {
			if (this.inputInFocus) {
				let t = this.deepCopy.modifiers_parsed[this.inputInFocus.parent].options[this.inputInFocus.child].name;
				if (e === undefined) {
					t = t.substring(0, t.length - 1);
					if (!t.length) {
						t = '';
					}
				} else {
					t += e;
				}
				this.deepCopy.modifiers_parsed[this.inputInFocus.parent].options[this.inputInFocus.child].name = t;
				this.checkInputItem(this.inputInFocus.parent, this.inputInFocus.child, t);
			}
		},
		changeItemCount(type) {
			if (type === 'increment') {
				if (this.deepCopy.legal_age_class === 1 && this.selectedOrder.payers.length) {
					usersService.getSinglePosUser(this.selectedOrder.payers[0].id).then((res) => {
						if (res) {
							if (res.intoxication_state) {
								this.openConfirmationToxicModal();
							} else {
								this.count++;
							}
						}
					})
				} else {
					this.count++;
				}
			} else {
				this.count > 1 ? this.count-- : null;
			}
		},
		openConfirmationToxicModal() {
			this.$refs['confirmation-modal-component'].show({
				title: 'Restricted',
				message: 'Payers has intoxication state.',
				okButton: 'OK',
			})
		},
	}
}
</script>

<style lang="less" scoped>
#modal-wrapper {
	:deep(.add-edit-item-modal-wrapper) {
		.c-modal {
			justify-content: flex-start;
			padding: 5px;
			
			.window {
				margin-bottom: 0;
			}
		}
	}
	
	#add-edit-item-modal {
		width: 950px;
		background-color: white;
		
		h1 {
			font-weight: normal;
			font-size: 42px;
			text-align: center;
			margin: 0;
		}
		
		.text {
			font-weight: normal;
			font-size: 14px;
			align-items: center;
			text-align: right;
			text-transform: uppercase;
			color: #000;
		}
		
		.has-border {
			border-top: 1px solid #e0e0e0;
		}
		
		.top-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 70px;
			
			.image-name-wrapper {
				justify-content: flex-start;
				display: flex;
				align-items: center;
				
				img {
					max-height: 70px;
					margin-right: 30px;
				}
				
				.top-block-text {
					color: #1A141F;
					font-weight: 600;
					font-size: 18px;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 400px;
					white-space: nowrap;
				}
			}

			.no-images {
				justify-content: center;
				gap: 12px;
				.no-images_item {
					
					&__1 {
						flex: 1;
						display: flex;
						justify-content: flex-end;
						
						img {
							margin: 0;
							height: 24px;
						}
					}
					
					&__2 {
						flex: 3;
						display: flex;
						justify-content: flex-start;
					}
				}
			}
		}
		
		.price-block {
			width: 48%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 47px;
			
			div {
				color: black;
				
				&:last-child {
					width: 35%;
					text-align: right;
					justify-content: flex-end;
				}
			}
		}
		
		.left-right-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: nowrap;
			margin-bottom: 20px;
			
			& > div {
				width: 48%;
			}
			
			.left-content {
				.condiments {
					.condiments-title {
						color: #1C282D;
						font-weight: 600;
						font-size: 18px;
						margin-bottom: 10px;
					}
					
					.condiments-list-wrapper {
						max-height: 180px;
						overflow-y: auto;
						min-height: 180px;
						
						&.no-condiments {
							.text {
								font-size: 14px;
								font-weight: 400;
								color: #777E81;
								background-color: #FAF9FB;
								text-transform: none;
								text-align: center;
								padding: 5px;
							}
						}
						
						.condiments-item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex-wrap: wrap;
							width: 100%;
							// border-bottom: 1px solid black;
							.item-content {
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								flex-wrap: nowrap;
								border-bottom: 1px solid #e0e0e0;
								padding: 10px;
								
								.left {
									display: flex;
									flex-wrap: nowrap;
									justify-content: flex-start;
									align-items: center;
									
									.checkbox-image {
										margin-right: 10px;
									}
									
									.item-title {
										font-size: 16px;
										font-weight: 400;
										color: #000;
									}
								}
								
								.right {
									display: flex;
									flex-wrap: nowrap;
									justify-content: center;
									align-items: center;
									
									.counter {
										display: flex;
										align-items: center;
										margin-right: 10px;
										
										button {
											border: none;
											background: white;
											cursor: pointer;
											
											&.disabled {
												filter: invert(32%) brightness(1.6);
												cursor: not-allowed;
											}
											
											img {
												width: 20px;
												height: 20px;
											}
										}
										
										span {
											padding: 0 5px;
											// background: #ECF4F9;
											border-radius: 5px;
											color: #1C282D;
										}
									}
									
									.price {
										&.not-selected {
											color: red
										}
									}
								}
							}
							
							.sub-item-content {
								width: 100%;
								padding-left: 10%;
								
								.sub-item {
									width: 100%;
									display: flex;
									justify-content: space-between;
									align-items: center;
									flex-wrap: wrap;
									border-bottom: 1px solid #e0e0e0;
									padding: 10px;
									
									.left {
										display: flex;
										justify-content: flex-start;
										align-items: center;
										flex-wrap: nowrap;
										
										.sub-item-radio {
											margin-right: 10px;
										}
										
										.sub-item-title {
											font-size: 16px;
											font-weight: 400;
											color: #000;
										}
									}
									
									.right {
										display: flex;
										justify-content: flex-start;
										align-items: center;
										flex-wrap: nowrap;
										
										.sub-item-count {
											font-size: 16px;
											font-weight: 400;
											color: #000;
											margin-right: 30px;
										}
										
										.sub-item-price {
											font-size: 16px;
											font-weight: 400;
											color: #000;
											
											&.not-selected {
												color: red
											}
										}
									}
									
									&.disabled {
										opacity: 0.6;
										cursor: not-allowed;
									}
									
								}
							}
							
						}
					}
				}
				
				.total-block {
					border-top: 2px solid #e0e0e0;
					
					div {
						font-weight: bold;
					}
				}
				
				.total-block, .discount-block {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					height: 47px;
					
					div {
						&:last-child {
							width: 35%;
							text-align: right;
							justify-content: flex-end;
						}
					}
				}
			}
			
			.right-content {
				.modifiers {
					margin-bottom: 10px;
					
					.modifiers-title {
						color: #1C282D;
						font-weight: 600;
						font-size: 18px;
						margin-bottom: 10px;
					}
					
					.modifiers-list-wrapper {
						max-height: 209px;
						min-height: 209px;
						overflow-y: auto;
						
						&.no-modifiers {
							.text {
								font-size: 14px;
								font-weight: 400;
								color: #777E81;
								background-color: #FAF9FB;
								text-transform: none;
								text-align: center;
								padding: 5px;
							}
							
							.item-wrapper {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								flex-wrap: wrap;
								
								.item-title {
									width: 100%;
									text-align: left;
									color: #000;
									font-size: 16px;
									padding-bottom: 5px;
									border-bottom: 1px solid #E5E0EB;
								}
								
								.item {
									width: 100%;
									margin-left: 20px;
									display: flex;
									justify-content: flex-start;
									align-items: center;
									padding: 5px 0;
									border-bottom: 1px solid #E5E0EB;
									
									img {
										margin-right: 20px;
									}
									
									.item-input {
										background-color: #ECF4F9;
										border-radius: 4px 4px 2px 2px;
										padding: 5px;
										border: none;
										width: 100%;
										
										&.disabled {
											pointer-events: none;
										}
									}
								}
							}
						}
						
						.modifiers-item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex-wrap: wrap;
							width: 100%;
							// border-bottom: 1px solid black;
							.item-content {
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								flex-wrap: nowrap;
								border-bottom: 1px solid #e0e0e0;
								padding: 10px;
								
								.left {
									display: flex;
									flex-wrap: nowrap;
									justify-content: flex-start;
									align-items: center;
									
									.checkbox-image {
										margin-right: 10px;
									}
									
									.item-title {
										font-size: 16px;
										font-weight: 400;
										color: #000;
									}
									
								}
								
								.right {
									display: flex;
									flex-wrap: nowrap;
									justify-content: center;
									align-items: center;
									
									.counter {
										display: flex;
										align-items: center;
										margin-right: 10px;
										
										button {
											border: none;
											background: white;
											cursor: pointer;
											
											&.disabled {
												filter: invert(32%) brightness(1.6);
												cursor: not-allowed;
											}
											
											img {
												width: 20px;
												height: 20px;
											}
										}
										
										span {
											padding: 0 5px;
											// background: #ECF4F9;
											border-radius: 5px;
											color: #1C282D;
										}
									}
									
									.price {
										&.not-selected {
											color: red
										}
									}
								}
							}
							
							.sub-item-content {
								width: 100%;
								padding-left: 10%;
								
								.sub-item {
									width: 100%;
									display: flex;
									justify-content: space-between;
									align-items: center;
									flex-wrap: wrap;
									border-bottom: 1px solid #e0e0e0;
									padding: 10px;
									
									.left {
										display: flex;
										justify-content: flex-start;
										align-items: center;
										flex-wrap: nowrap;
										width: 100%;
										
										.sub-item-radio {
											margin-right: 10px;
										}
										
										.sub-item-title {
											font-size: 16px;
											font-weight: 400;
											color: #000;
										}
										
										.item-input {
											background-color: #ECF4F9;
											border-radius: 4px 4px 2px 2px;
											padding: 5px;
											border: none;
											width: 100%;
										}
									}
									
									.right {
										display: flex;
										justify-content: flex-start;
										align-items: center;
										flex-wrap: nowrap;
										
										.sub-item-count {
											font-size: 16px;
											font-weight: 400;
											color: #000;
											margin-right: 30px;
										}
										
										.sub-item-price {
											font-size: 16px;
											font-weight: 400;
											color: #000;
											
											&.not-selected {
												color: red
											}
										}
									}
									
									&.disabled {
										opacity: 0.6;
										cursor: not-allowed;
									}
									
								}
							}
							
						}
					}
				}
			}
		}
		
		.quantity-block, .seat-block, .timer-block {
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			
			button {
				border: none;
				background: white;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&:last-of-type {
					padding-right: 0;
				}
			}
			
			.changer {
				display: flex;
				align-items: center;
				
				span {
					padding: 0 15px;
					background: #ECF4F9;
					border-radius: 5px;
					color: #1C282D;
				}
			}
			
			.text {
				margin-right: 10px;
			}
		}
	}
}

@media (max-width: 1200px) {
	#modal-wrapper {
		#add-edit-item-modal {
			h1 {
				font-size: 36px;
			}
		}
	}
}

@media (max-width: 850px) {
	#modal-wrapper {
		#add-edit-item-modal {
			min-width: 480px;
		}
	}
}
</style>
