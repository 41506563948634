import store from "@/store";
import {settingsService} from "@/_api/settings.api";

export const businessDayService = {
  checkBusinessDay,
  stopChecking
};

let checkInterval;

function checkBusinessDay() {
  let data = settingsService.getBusinessDay()
  store.dispatch('setBusinessDay', data);
  clearInterval(checkInterval);
  checkInterval = setInterval(() => {
    store.dispatch('setBusinessDay', data);
  }, 300000);
};

function stopChecking() {
  clearInterval(checkInterval);
};
