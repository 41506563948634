import axios from "axios";
import store from "@/store";

export const printersService = {
	getPrinters,
	createPrinter,
	deletePrinter,
	editPrinter,
	getSinglePrinters,
	checkPrintFileExistence,
	printExistingFiles,
	printRunnerChit,
	printCheck,
	printTest,
}

function getPrinters(data) {
	return axios.get(
			`/printers`, {
				params:data
			}).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function getSinglePrinters(id) {
	return axios.get(
			`/printers/${id}`).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function createPrinter(data) {
	return axios.post(
			`/printers`, data).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function editPrinter(data, id) {
	return axios.put(
			`/printers/${id}`, data).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function deletePrinter(id) {
	return axios.delete(
			`/printers/${id}`).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function checkPrintFileExistence(){
	return axios.get(
			`${store.state.PRINT_SERVICE_API_BASE}/print/file/exist`,{
				params: {
					pos_id: store.state.auth.posId
				}
			}).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}
function printExistingFiles(){
	return axios.get(
			`${store.state.PRINT_SERVICE_API_BASE}/print/file`,{
				params: {
					pos_id: store.state.auth.posId
				}
			}).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}
function printRunnerChit(data) {
	return axios.post(
			`${store.state.PRINT_SERVICE_API_BASE}/print`,{
				type: 'runner-chit',
				order_id: data
			}).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}
function printCheck(data) {
	return axios.post(
			`${store.state.PRINT_SERVICE_API_BASE}/print`,{
				type: 'invoice',
				order_id: data
			}).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}
function printTest(data) {
	return axios.post(
			`${store.state.PRINT_SERVICE_API_BASE}/print`,{
				type: 'test',
				printer: data
			}).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}
