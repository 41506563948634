<template>
	<div id="terminal-setup-root" :class="{'is-auth': isAuth}" class="terminal-setup-root">
		
		<div class="terminal-wrapper">
			<button v-if="!isAuth" class="btn-logout" @click="logout()">Log out</button>
			<div class="img-wrapper">
				<icon-component iconName="mainLogo" :class="design === 'ecruise' ? 'logo' : 'logo margaritaville-logo'" />
			</div>
			<div class="header">
				<h1>{{ isAuth ? 'Change department' : 'Set up the terminal' }}</h1>
			</div>
			<div class="select-terminal-wrapper">
				<div class="set-up-error-wrapper" v-if="step === 1">
					<div class="error-text">The terminal is not set up.</div>
					<div class="error-text">Please inform the manager.</div>
					<button class="def-btn main-action-btn border-2 uppercase" @click="goToDepartments">OK</button>
				</div>
				<div class="set-up-terminal-wrapper" v-else-if="step === 2">
					<div class="title">Select Department</div>
					<Dropdown
						ref="depInput"
						:terminalInput="depInput"
						:options="newDepartments"
						:default="'Department'"
						class="select"
						@onItemClick="(e) => this.depInput = e"
						@onInput="setDepartment"
						@searchTerminal="searchTerminal"
						style="z-index: 3"
						:max-height="'200'"
						@click="setFocus('department')"
					/>
					
					<div v-if="department">
						<div class="title">POS terminal name</div>
						<Dropdown
							ref="posInput"
							:terminalInput="posInput"
							:options="newTerminalNames"
							:default="'POS Terminal'"
							class="select"
							@onItemClick="(e) => this.posInput = e"
							@onInput="setTerminalName"
							@searchTerminal="searchTerminalName"
							style="z-index: 2"
							@click="setFocus('pos')"
						/>
					</div>

					<base-button v-if="terminalName" text="SET UP" classes="def-btn main-action-btn uppercase medium border-2" @onClick="login"/>

				</div>
			</div>
		</div>
		<Keyboard
				v-if="!isAuth"
				:has-close-btn="false"
				:show-by-default="true"
				class="letters-keypad"
				@clear="clear"
				@clickOnKey="clickOnKey"
		/>
	</div>
</template>

<script>
import Dropdown from "@/components/_shared/Dropdown";
import Keyboard from "@/components/_shared/Keyboard";
import {authService} from "@/_api/auth.api";
import store from "@/store";
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'TerminalSetupRootComponent',
	props: {
		isAuth: {
			default: false,
			required: false
		}
	},
	emits: ['depChanged'],
	components: {Keyboard, Dropdown, BaseButton, iconComponent},
	data() {
		return {
			step: 1,
			inFocus: '',
			depInput: '',
			posInput: '',
			posId: null,
			posNameId: null,
			department: '',
			terminalName: null,
			departments: [],
			terminalNames: [],
			newDepartments: [],
			newTerminalNames: []
		}
	},
	computed: {
		design() {
			return this.$store.state.UI;
		},
		authUser() {
			return this.$store.state.auth.user;
		},
	},
	created() {
		if (this.authUser.user.role === 'manager') {
			this.goToDepartments();
		}
		/*this.getDepartments();*/
	},
	mounted() {
		this.newDepartments = this.departments;
		this.newTerminalNames = this.terminalNames;
	},
	beforeUnmount() {
	},
	methods: {
		goToDepartments(e) {
			if (this.authUser.user.role !== 'manager') {
				this.logout()
			} else {
				authService.getDepartments({
					page: 1,
					per_page: 1000,
					
				}).then(res => {
					if (res && res.data) {
						res.data.forEach(el => {
							this.departments.push({name: el.name, id: el.id})
						})
					}
				}).catch(e => {
					//throw e
				})
				this.step = 2
			}
		},
		getDepartments() {
			authService.getDepartments({
				page: 1,
				per_page: 1000,
				
			}).then(res => {
				if (res && res.data) {
					res.data.forEach(el => {
						this.departments.push({name: el.name, id: el.id})
					})
				}
			}).catch(e => {
				//throw e
			})
		},
		setDepartment(e) {
			this.terminalNames.splice(0, this.terminalNames.length)
			authService.getPoses({
				department_id: e.id
			}).then(res => {
				if (res && res.data) {
					res.data.forEach(el => {
						this.terminalNames.push(el)
					})
				}
			}).catch(error => {
				//throw error
			})
			this.department = e;
			this.posInput = '';
			this.setFocus('pos');
		},
		setTerminalName(e) {
			this.posNameId = e.id;
			this.department = e;
			this.terminalName = e;
		},
		searchTerminal(e) {
			this.department = null;
			this.depInput = e;
			this.newDepartments = this.departments.filter(el => el.name.toLowerCase().includes(this.depInput.toLowerCase()));
		},
		searchTerminalName(e) {
			this.terminalName = null;
			this.posInput = e;
			this.newTerminalNames = this.terminalNames.filter(el => el.name.toLowerCase().includes(this.posInput.toLowerCase()));
		},
		login() {
			authService.setup({
				id: this.posNameId
			}).then(res => {
				if (res) {
					this.posId = this.posNameId;
					store.dispatch('setupPosId', this.posId);
					store.dispatch('setShowImages', true);
					// localStorage.setItem('posId', this.posId);
					this.$emit('depChanged');
					if (!this.isAuth) {
						this.$router.push('/pos');
					}
				}
			}).catch(e => {
				//throw e
			})
		},
		logout() {
			this.$store.dispatch('logoutUser').then(() => {
				this.$router.replace({path: '/auth/login'})
			})
		},
		clickOnKey(e) {
			if (this.inFocus === 'department') {
				this.depInput += e;
				this.newDepartments = this.departments.filter(el => el.name.toLowerCase().includes(this.depInput.toLowerCase()));
				this.$refs['depInput'].openDD();
			} else if (this.inFocus === 'pos') {
				this.posInput += e;
				this.newTerminalNames = this.terminalNames.filter(el => el.name.toLowerCase().includes(this.posInput.toLowerCase()));
				this.$refs['posInput'].openDD();
			} else {
				if (this.department) {
					this.inFocus = 'pos';
					this.posInput += e;
					this.newTerminalNames = this.terminalNames.filter(el => el.name.toLowerCase().includes(this.posInput.toLowerCase()));
					this.$refs['posInput'].openDD();
				} else {
					this.inFocus = 'department';
					this.depInput += e;
					this.newDepartments = this.departments.filter(el => el.name.toLowerCase().includes(this.depInput.toLowerCase()));
					this.$refs['depInput'].openDD();
				}
			}
		},
		clear() {
			if (this.inFocus === 'department') {
				if (this.depInput.length <= 1) {
					this.depInput = '';
					this.department = null;
					this.posInput = '';
					this.terminalName = null;
				} else {
					this.depInput = this.depInput.slice(0, -1);
				}
				this.newDepartments = this.departments.filter(el => el.name.toLowerCase().includes(this.depInput.toLowerCase()));
			} else if (this.inFocus === 'pos') {
				this.posInput = this.posInput.slice(0, -1);
				if (this.depInput.length <= 1) {
					this.setFocus('department');
				}
				this.newTerminalNames = this.terminalNames.filter(el => el.name.toLowerCase().includes(this.posInput.toLowerCase()));
				
			}
		},
		setFocus(type) {
			this.inFocus = type;
			if (type === 'department' && this.department) {
				this.$refs['posInput'].blur();
			}
		}
	},
	
}
</script>

<style lang="less" scoped>
#terminal-setup-root {
	// position: relative;
	// background: #FFFFFF;
	// box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
	// border-radius: 8px;
	width: 840px;
	// height: 516px;
	margin: 50px auto 0 auto;
	// padding: 24px;
	&.is-auth {
		margin-top: 0;
		
		.terminal-wrapper {
			padding: 0;
		}
	}
	
	:deep(.letters-keypad) {
		left: 0;
		right: 0;
		margin: 0 auto;
		// position: relative;
		&.keypad-type {
			border: none;
			
			.container {
				box-shadow: none;
				border: none;
				background-color: unset;
				padding: 0;
				width: 100%;
				.keypad {
					margin: 0 auto;
				}
			}
		}
	}
	
	.def-btn {
		width: 100%;
		height: 40px;
	}
	
	.terminal-wrapper {
		position: relative;
		display: block;
		min-height: 470px!important;
		padding: 24px;
		background: #FFFFFF;
		box-shadow: 4px 4px 12px rgb(0 0 0 / 12%);
		border-radius: 8px;
		margin-bottom: 20px;
		
		.img-wrapper {
			.margaritaville-logo {
				padding: 20px 40px;
				// background-color: var(--theme_color);
				border-radius: 10px;
			}
		}
		
		.btn-logout {
			position: absolute;
			right: 10px;
			top: 10px;
			border: none;
			outline: none;
			background: white;
			font-size: 14px;
			line-height: 19px;
			text-align: right;
			text-decoration-line: underline;
			// color: var(--theme_color);
			cursor: pointer;
		}
		
		.header {
			h1 {
				font-weight: normal;
				font-size: 42px;
				line-height: 56px;
				text-align: center;
				// color: var(--theme_color);
			}
		}
		
		.select-terminal-wrapper {
			width: 320px;
			margin: 0 auto;
			
			.set-up-error-wrapper {
				position: relative;
				align-items: center;
				
				.error-text {
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					text-align: center;
					color: #1C282D;
					
					&:last-of-type {
						margin-bottom: 40px;
					}
				}
			}
			
			.set-up-terminal-wrapper {
				.title {
					text-align: left;
					font-weight: 600;
					font-size: 14px;
					line-height: 16px;
					color: #1C282D;
					margin: 44px 0 4px 0;
				}
				
				.def-btn {
					margin-top: 40px;
				}
			}
		}
		
		
	}
}

@media (max-width: 1200px) {
	#terminal-setup-root {
		width: 95%;
	}
	
}
@media (max-width: 850px) {
	#keyboard {
		width: 95%;
	}
}
</style>
