import axios from "axios";

export const settingsService ={
	getSettings,
	getBusinessDay
}

function getSettings(){
	return axios.get(`/settings`).then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function getBusinessDay() {
	// return axios.get(`/settings`).then((res) => {
	// 	return res.data
	// }).catch((err) => {
	// 	return Promise.reject(err)
	// })
	return {status: 'rolled'};

}
