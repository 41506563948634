import {authService} from "@/_api/auth.api";
import store from "@/store";

export default function getPosHelper(posId) {
	return new Promise((resolve, reject) => {
		authService.getPos({
			id: posId
		}).then((res) => {
			if (res) {
				store.dispatch('savePosData', res);
				resolve(res)
			}
		}).catch((err) => {
			reject(err);
		}).finally(() => {})
	})
}
