<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" @onModalClose="this.$emit('onModalClose')">
			<div id="print-check-modal" class="modal-container">
				<!--<embed :src="checkImage+'#toolbar=0&navpanes=0&scrollbar=0'" style="overflow:hidden; width: 700px; height: 500px"/>-->
				<div class="modal-header">Print check</div>
				<div class="container">
					<div class="header">
						<h3 class="sub-header">{{ getPosData.department.name }}</h3>
						<p class="header-date">DATE: {{date}}</p>
					</div>
					<div class="configured-header">{{getPosData.department.check_header}}</div>
					
					<div class="department-info">
						<p class="text">Payer: {{selectedOrder.payers.length ? `${selectedOrder.payers[0].first_name} ${selectedOrder.payers[0].last_name}` : 'Not specified yet'}}</p>
						<p class="text">Waiter: {{ selectedOrder.opened_by.name }}</p>
						<p class="text">UDID: 6b3aff05-8e64-4edf-8531-9e088abf01a7</p>
					</div>
					<div class="check-info">
						<div class="items-list">
							<div class="item-wrapper" v-for="(i,index) in selectedOrder.items" :key="index">
								<div class="item">
									<div class="name">{{ i.name }} <span v-if="i.count > 1">(x{{i.count}}) </span></div>
									<div class="sum">{{ '$' + calcItemTotal(i) }}</div>
								</div>
								<div class="condiments" v-if="i.condiments">
									<div class="item" v-for="ii in i.condiments">
										<div class="name">- {{ ii.menu_item_condiment.name }} <span v-if="ii.count > 1">(x{{ii.count}}) </span></div>
										<div v-if="selectedOrder.with_discount" class="sum">{{ '$' + parseFloat(ii.total_amount_with_discount / ii.count).toFixed(2) }}</div>
										<div v-else class="sum">{{ '$' + parseFloat(ii.total_amount / ii.count).toFixed(2) }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="info">
							<div class="subtotal" >
								<div class="title">subtotal:</div>
								<div class="sum">{{'$' + selectedOrder.subtotal.toFixed(2)}}</div>
							</div>
							<div class="surcharge">
								<div class="title">surcharge:</div>
								<div class="sum">{{'$' + surcharge.toFixed(2)}}</div>
							</div>
							<div class="discount">
								<div class="title">discount:</div>
								<div class="sum">${{ calcDiscount }}</div>
							</div>
							<div class="tax">
								<div class="title">tax:</div>
								<div class="sum">{{'$' + tax.toFixed(2)}}</div>
							</div>
							<div class="autogratuity">
								<div class="title">autogratuity:</div>
								<div class="sum">{{'$' + autogratuity.toFixed(2)}}</div>
							</div>
							<div class="total">
								<div class="title ">total:</div>
								<div class="sum">${{selectedOrder.total ?
										selectedOrder.total.toFixed(2) : selectedOrder.subtotal.toFixed(2)}}</div>
							</div>
						</div>
					</div>
					<div class="configured-footer">
						{{getPosData.department.check_footer}}
					</div>
				</div>
				<!--<div class="print">
					<div class="icon"><img src="@/assets/img/36printer.svg" alt="printer-icon"></div>
					<div class="text">Kitchen Second Floor HP DeskJet 3755 MX</div>
				</div>-->
				<div class="modal-controls x2">
					<base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
					<base-button text="PRINT" classes="def-btn main-action-btn uppercase medium border-2" @onClick="printCheck"/>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {orderService} from "@/_api/order.api";
import BaseButton from "@/components/_shared/Button.vue";

export default {
    name: "PrintCheckModal",
    components: { ModalComponent, BaseButton },
	emits: ['printMethods', 'onModalClose'],
    props: {
	    selectedOrder: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            subtotal: 0,
            surcharge:0,
            discount:0,
            tax:0,
            autogratuity:0,
            total:0,
            date:null,
	        checkImage: null
        }
    },
	
	mounted() {
		this.emmiter.on('closeModal', () => {
			if (this.$refs['modal']) {
				this.close();
			}
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.emit('toggleModals', {opened: false, type: 'PrintCheckModal'});
	},
	computed: {
		getPosData() {
			return this.$store.state.auth.posData
		},
		calcDiscount() {
			if (this.selectedOrder.with_discount) {
				return (this.selectedOrder.subtotal - this.selectedOrder.total).toFixed(2);
			} else {
				return '0.00';
			}
		},
	},
    methods: {
		calcItemTotal(data) {
			let totalCondimentsWithDisc = 0;
			let totalCondiments = 0;
			if (data.combo) {
				if (data.items && data.items.length) {
					data.items.forEach((o) => {
						if (o.condiments && o.condiments.length) {
							o.condiments.forEach((oo) => {
								if (oo.count > 0) {
									totalCondimentsWithDisc += (oo.total_amount - oo.discount);
									totalCondiments += oo.total_amount;
								}
							});
						}
					});
				}
		    }
			if (this.selectedOrder.with_discount) {
				if (data.combo) {
					return ((totalCondimentsWithDisc + data.total_amount_with_discount) / data.count).toFixed(2);
				} else {
					return (data.total_amount_with_discount / data.count).toFixed(2);
				}
			} else {
				if (data.combo) {
					return ((totalCondiments + data.total_amount) / data.count).toFixed(2);
				} else {
					return (data.total_amount / data.count).toFixed(2);
				}
			}
		},
	    showCondimentsOfRightType(arr) {
		    return arr.filter((o) => {return o.type === 1});
	    },
		getCheck(data) {
			orderService.getCheckPrint(data).then((res) => {
				const blob = new Blob([res], { type: 'application/pdf' });
				this.checkImage = URL.createObjectURL(blob);
				// this.checkImage = 'data:image/jpeg;base64,' + btoa(res);
			})
		},
        openModal() {
            this.date = new Date().toLocaleString().slice(0,-3);
            this.subtotal = 0;
            this.surcharge = 0;
            this.discount = 0;
            this.tax = 0;
            this.autogratuity = 0;
            this.total = 0;
            this.$refs['modal'].show();
	        this.emmiter.emit('toggleModals', {opened: true, type: 'PrintCheckModal'});
        },
        close() {
            this.$refs['modal'].close();
	        this.emmiter.emit('toggleModals', {opened: false, type: 'PrintCheckModal'});
        },
        printCheck() {
	        /*this.emmiter.emit('printMethods', {
		        type: 'invoice'
	        })*/
	        this.$emit('printMethods', {
		        type: 'invoice'
	        })
            // orderService.printInvoiceOrder(this.selectedOrderId.id)
            // this.$refs['modal'].close()
        },
    }
}
</script>

<style scoped lang="less">
#modal-wrapper {
	#print-check-modal {
		width: 500px;
		.container {
			padding: 0 60px;
			margin-bottom: 40px;
			max-height: 450px;
			overflow-y: scroll;
			.header {
				margin-bottom: 20px;
				.sub-header{
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 32px;
					text-align: center;
					text-transform: uppercase;
					color: #1C282D;
					margin: 0 0 10px 0;
				}
				&-date{
					font-weight: normal;
					font-size: 14px;
					line-height: 148%;
					align-items: center;
					text-align: center;
					text-transform: uppercase;
					color: #000000;
					margin: 0;
				}
			}
			.configured-header {
				margin-bottom: 40px;
			}
			.department-info {
				margin-bottom: 40px;
				.text{
					text-transform: uppercase;
					margin: 0;
					text-align: left;
				}
			}
			.check-info {
				margin-bottom: 40px;
				.items-list {
					margin-bottom: 30px;
					.item-wrapper {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;
						// height: 30px;
						width: 100%;
						.item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex-wrap: wrap;
							width: 100%;
						}
						.condiments {
							width: 100%;
							.item {
								padding-left: 10px;
							}
						}
					}
				}
				.info {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					& > div {
						width: 100%;
						display: flex;
						justify-content: flex-end;
						align-content: center;
						flex-wrap: nowrap;
						padding: 5px 0;
						&:not(:last-child){
							border-bottom: 1px solid rgba(0,0,0,.2);
						}
					}
					.total {
						font-weight: bold;
					}
					
					.title{
						font-size: 14px;
						text-transform: uppercase;
						color: #000000;
						// margin-left: 50%;
					}
					.sum {
						width: 30%;
						text-align: right;
					}
				}
			}
		}
		.print {
			display: flex;
			// width: 492px;
			height: 48px;
			border: 1px solid #3F8EC5;
			box-sizing: border-box;
			border-radius: 8px;
			align-items: center;
			justify-content: flex-start;
			// margin-top: 86px;
			padding: 0 10px;
			width: 80%;
			margin: 0 auto 20px auto;
			.icon {
				margin-right: 20px;
			}
		}
	}
}
</style>
