<template>
    <div id="modal-wrapper">
	    <modal-component ref="modal" @onModalClose="this.$emit('onModalClose')">
		    <div id="change-item-state-modal" class="modal-container">
			    <div class="modal-header">Change the item state</div>
			    <div class="state-wrapper">
				    <div class="header"></div>
				    <div class="states">
					    <div v-for="(s,i) in states" :class="s.selected ? 'selected' : 'state'" @click="changeStatus(i)" v-show="s.show">
						    <div class="circle" :style="{'background-color': circleColors[s.status-1]} "></div>
						    <div class="title">{{ s.name }}</div>
					    </div>
				    </div>
			    </div>
			    <div class="modal-controls x2">
						<base-button text="no" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
						<base-button text="yes" classes="def-btn main-action-btn uppercase medium border-2" @onClick="submit"/>
			    </div>
		    </div>
	    </modal-component>
    </div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import BaseButton from "@/components/_shared/Button.vue";

export default {
    name: "ChangeItemStateModal",
    emits: ['closeOrder', 'upload', 'changeItemStatus'],
    components: {ModalComponent, BaseButton},
    props: {
        selectedOrder: {
            type: Object
        },
    },
    data() {
        return {
            states: [
	            {name: 'Ordered', status: 1, show: true},
	            {name: 'Placed on the food runner chit', status: 2, show: true},
                {name: 'Served', status: 3, show: true},
            ],
            circleColors: ['#EB262B', '#FFCB05','#82B941'],
	        item: null
        }
    },
	created() {
		this.emmiter.on('closeModal', (reset) => {
			this.close();
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.emit('toggleModals', {opened: false, type: 'ChangeItemStateModal'});
	},
	computed: {},
    methods: {
        openModal(data, spliceOptionsArr, indexToSplice) {
			this.states.map((i) => {return i.show = true})
			if (spliceOptionsArr) {
				this.states[indexToSplice]['show'] = false;
			}
			
			this.item = data;
			if (data.status) {
				this.states.map((o) => {return o.status === data.status ? o.selected = true : o.selected = false})
			} else {
				this.states[2].selected = true;
			}
	        this.emmiter.emit('toggleModals', {opened: true, type: 'ChangeItemStateModal'});
            this.$refs['modal'].show();
        },
        close() {
	        this.emmiter.emit('toggleModals', {opened: false, type: 'ChangeItemStateModal'});
            this.$refs['modal'].close();
        },
	    submit() {
		    this.item['status'] = this.states.filter((o) => {return o.selected})[0].status;
			this.$emit('changeItemStatus', this.item);
			this.close();
        },
        changeStatus(i) {
            this.states.forEach(el => el.selected = false);
            this.states[i].selected = true;
        },
    }
}
</script>

<style scoped lang="less">
#modal-wrapper {
	#change-item-state-modal {
		.states {
			margin-bottom: 30px;
			.state {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				height: 41px;
				padding-right: 15px;
				padding-left: 7px;
				width: 464px;
			}
			
			.selected {
				border: 1px solid #3F8EC5;
				box-sizing: border-box;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				height: 41px;
				padding-right: 15px;
				padding-left: 6px;
				width: 100%;
			}
			
			.circle {
				border-radius: 100%;
				height: 12px;
				width: 12px;
				background: #FFCB05;
			}
			
			.title {
				padding-left: 7px;
			}
		}
	}
}
</style>
