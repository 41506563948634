<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" :anotherBackground="true" @onModalClose="this.$emit('onModalClose')">
			<div id="discount-gratuity-modal" class="modal-container">
				<div class="modal-header">{{ title }}</div>
				
				<div class="sets" :style="{padding: title === 'All discounts' ? '0px 42px' : '0px'}">
					<div class="inputs">
						<div class="fidelio">
							<p v-if="title === 'All discounts'">Fidelio check discount, $:</p>
							<p v-if="title === 'All discounts'">{{
									(selectedOrder.subtotal - selectedOrder.total).toFixed(2)
								}}</p>
							<p v-if="title === 'Item discount'">Fidelio discount, $:</p>
							<p v-if="title === 'Item discount'">{{ selectedOrder.items[singleItemIndex].discount }}</p>
							<p v-if="title === 'Gratuity'">Fidelio autogratuity:</p>
							<p v-if="title === 'Gratuity'">fg</p>
						</div>
						
						<div class="manual">
							<label v-if="title !== 'Gratuity'" for="mv">Manual discount, $:</label>
							<label v-if="title === 'Gratuity'" for="mv">Manual gratuity:</label>
							<input id="mv" type="text" v-model="manualValue" @keypress="isNumber($event)">
						</div>
					</div>
					
					<div v-if="title === 'Gratuity'" class="switches">
						<Switch
								class="switch switch_1"
								:id="'switch_1'"
								:val="false"
								:onLabel="'INCLUDED'"
								:offLabel="'EXCERPTED'"
								@toggled="toggleGratuity"
						/>
						<Switch
								class="switch switch_2"
								:id="'switch_2'"
								:val="manualGratuityTypeBool"
								:onLabel="'$'"
								:offLabel="'%'"
								@toggled="toggleManualGratuityType"
						/>
					</div>
				</div>
				
				<div v-if="title === 'All discounts'" class="discounts-table">
					<table class="table">
						<thead>
						<tr>
							<th class="th-name">ITEM</th>
							<th class="th-price">PRICE</th>
							<th class="th-q">QTY</th>
							<th class="th-fd">FIDELIO DISCOUNT</th>
							<th class="th-md">MANUAL DISCOUNT</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="i in this.selectedOrder.items">
							<td class="td-name">{{ i.name }}</td>
							<td>{{ i.price.toFixed(2) }}</td>
							<td>{{ i.count }}</td>
							<td>{{ itemDiscount(i) }}</td>
							<td class="td-md">
								<div class="val">{{ i.discount ? i.discount.toFixed(2) : i.discount }}</div>
							</td>
						</tr>
						</tbody>
					</table>
					<!-- <div class="total">
						TOTAL DISCOUNT: <span>${{ totalDiscount }}</span>
					</div> -->
				</div>
				
				<keypad @clickOnKey="keypadClick" @clear="keypadClear"></keypad>
				
				<div class="modal-controls">
		          <base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="closeModal()"/>
		          <base-button v-if="title !== 'All discounts'" text="ok" classes="def-btn main-action-btn uppercase medium border-2" @onClick="confirm()"/>
		          <base-button v-if="title === 'All discounts'" text="save" classes="def-btn main-action-btn uppercase medium border-2" @onClick="confirm()"/>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import Keypad from "@/components/_shared/Keypad";
import Switch from "@/components/_shared/Switch.vue";
import BaseButton from "@/components/_shared/Button.vue";

export default {
	name: 'SetDiscountGratuityModalComponent',
	props: {
		selectedOrder: {
			default: null,
			required: true
		}
	},
	emits: ['confirm', 'onModalClose'],
	components: {BaseButton, ModalComponent, Keypad, Switch},
	data() {
		return {
			title: '',
			manualValue: '',
			includedGratuity: false,
			manualGratuityTypeBool: true,
			manualGratuityType: '$',
			singleItemIndex: null
		}
	},
	computed: {
		design() {
			return this.$store.state.UI;
		},
		itemsWithDiscount() {
			return this.selectedOrder.items.filter((o) => {return o.discount});
		},
		totalDiscount() {
			let totalDiscount = this.selectedOrder.subtotal - this.selectedOrder.total;
			return totalDiscount.toFixed(2);
		},
	},
	created() {},
	mounted() {},
	beforeUnmount() {
		this.emmiter.emit('toggleModals', {opened: false, type: 'SetDiscountGratuityModal'});
	},
	methods: {
		confirm() {
			if (this.title === 'Item discount') {
				this.selectedOrder.items[this.singleItemIndex].discount = +this.manualValue;
			}
			if (this.title === 'All discounts') {
			
			}
			if (this.title === 'Gratuity') {
			
			}
			
			this.$emit('confirm', this.selectedOrder);
			this.$refs['modal'].close();
		},
		isNumber(e) {
			e = (e) ? e : window.event;
			let charCode = (e.which) ? e.which : e.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				e.preventDefault();
			} else {
				// this.manualValue += e.key;
				return true;
			}
		},
		itemDiscount(item) {
			let condimentsDiscount = 0;
			if (item.condiments && item.condiments.length) {
				item.condiments.forEach((o) => {
					if (o.count > 0) {
						condimentsDiscount += o.discount;
					}
				})
			} else {
				if (item.items && item.items.length) {
					item.items.forEach((o) => {
						if (o.condiments && o.condiments.length) {
							o.condiments.forEach((oo) => {
								if (oo.count > 0) {
									condimentsDiscount += oo.discount;
								}
							});
						}
					});
				}
			}
			return (item.total_amount - item.total_amount_with_discount + condimentsDiscount).toFixed(2);
		},
		openModal(data) {
			this.$refs['modal'].show();
			if (data) {
				this.title = data.title;
			}
			if (data.index || data.index === 0) {
				this.singleItemIndex = data.index;
			}
			this.emmiter.emit('toggleModals', {opened: true, type: 'SetDiscountGratuityModal'});
		},
		closeModal() {
			this.emmiter.emit('toggleModals', {opened: false, type: 'SetDiscountGratuityModal'});
			this.$refs['modal'].close();
		},
		keypadClick(e) {
			this.manualValue += e.toString();
		},
		keypadClear() {
			this.manualValue = this.manualValue.slice(0, -1)
		},
		toggleGratuity(val) {
			this.includedGratuity = val;
		},
		toggleManualGratuityType(val) {
			if (val) {
				this.manualGratuityType = '$';
			} else {
				this.manualGratuityType = '%';
			}
		},
	},
	watch: {}
}
</script>

<style lang="less" scoped>
#modal-wrapper {
	.window {
		background-color: #F5F9FC !important;
	}
	
	#discount-gratuity-modal {
		width: 600px;
		background-color: #F5F9FC;
		
		.modal-header {
			font-style: normal;
			font-weight: 300;
			font-size: 42px;
			line-height: 56px;
			text-align: center;
			color: #3F8EC5;
		}
		
		.sets {
			width: 100%;
			display: flex;
			flex-direction: row;
			gap: 18px;
			margin-bottom: 26px;
			
			.inputs {
				flex: 4;
				flex-direction: column;
				
				.fidelio {
					padding: 3px;
					width: 100%;
					height: 40px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					background-color: #fff;
					margin-bottom: 24px;
					
					p {
						padding: 0;
						width: max-content;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 16px;
						color: #1C282D;
					}
				}
				
				.manual {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 4px;
					
					label {
						padding: 0;
						width: max-content;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 16px;
						color: #1C282D;
					}
					
					input {
						box-sizing: border-box;
						padding: 3px;
						width: 100%;
						height: 40px;
						background-color: #fff;
						border: none;
						border-bottom: 2px solid #3F8EC5;
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 20px;
						color: #1C282D;
					}
				}
			}
			
			.switches {
				padding: 4px 0;
				width: max-content;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
		
		.discounts-table {
			width: 100%;
			padding: 0;
			box-sizing: border-box;
			max-height: 400px;
			overflow-x: auto;
			
			.table {
				width: 100%;
				table-layout: fixed;
				border-collapse: collapse;
				box-sizing: border-box;
				text-align: left;
				background-color: #fff;
				
				thead {
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					color: #1C282D;
					
					tr {
						height: 36px;
						
						th {
							border: 1px solid #E5E0EB;
							border-top: 2px solid #E5E0EB;
							border-bottom: 2px solid #E5E0EB;
							padding: 2px;
							text-align: center;
						}
						
						.th-name {
							width: 20%;
							border-left: none;
						}
						
						.th-price {
							width: 15%;
						}
						
						.th-q {
							width: 10%;
						}
						
						.th-fd {
							width: 15%;
						}
						
						.th-md {
							width: 40%;
							border-right: none;
						}
					}
					
				}
				
				tbody {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					color: #1C282D;
					
					tr {
						height: 36px;
						
						td {
							padding: 2px;
							border: 1px solid #E5E0EB;
							text-align: right;
						}
						
						.td-name {
							border-left: none;
							text-align: left;
						}
						
						.td-md {
							padding: 2px 6px;
							border-right: none;
							
							.val {
								width: 100%;
								text-align: left;
								background-color: #ECF4F9;
								line-height: 32px;
							}
						}
					}
				}
			}
			
			// .total {
			// 	text-align: right;
			// 	font-weight: bold;
			// 	text-transform: uppercase;
			// 	span {
			// 		margin-left: 20px;
			// 	}
			// }
		}
		
		.keypad {
			margin: 0 auto;
			border: none;
			margin-bottom: 24px;
		}
		
		.modal-controls {
			justify-content: center;
			gap: 12px;
			
			button {
				width: 180px;
				height: 48px;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
			}
		}
	}
	
	.switch {
		--toggle-width: 96px;
		--toggle-height: 24px;
		--toggle-border: 0.125rem;
		--toggle-font-size: 0.75rem;
		--toggle-duration: 150ms;
		--toggle-bg-on: #3F8EC5;
		--toggle-bg-off: #e5e7eb;
		--toggle-bg-on-disabled: #d1d5db;
		--toggle-bg-off-disabled: #e5e7eb;
		--toggle-border-on: #3F8EC5;
		--toggle-border-off: #e5e7eb;
		--toggle-border-on-disabled: #d1d5db;
		--toggle-border-off-disabled: #e5e7eb;
		--toggle-ring-width: 3px;
		--toggle-ring-color: transparent;
		--toggle-text-on: #ffffff;
		--toggle-text-off: #374151;
		--toggle-text-on-disabled: #9ca3af;
		--toggle-text-off-disabled: #9ca3af;
		--toggle-handle-enabled: #ffffff;
		--toggle-handle-disabled: #f3f4f6;
		
		&_1 {
			--toggle-text-off: #EB262B;
		}
		
		&_2 {
			--toggle-bg-off: #3F8EC5;
			--toggle-border-off: #3F8EC5;
			--toggle-text-off: #fff;
			--toggle-font-size: 1.5rem;
		}
	}
}
</style>
