export const constants = {
	tempOrderID: '-10',
	paymentMethods: [
		{id: 1, name: 'Cabin'},
		{id: 2, name: 'Account'}
	],
	payerAddedModes: [
		{id: 1, name: 'manual'}, {id: 2, name: 'card'}
	],
	maxPayers: 8
}
