import {alert} from "@/store/alert.module";
import {auth} from "@/store/auth.module";
import {settings} from "@/store/settings.module";
import Vuex from 'vuex'

export default new Vuex.Store({
	state: {
		API_BASE: window.___env.apiBase,
		PRINT_SERVICE_API_BASE: window.___env.printServiceApiBase,
		UI: window.___env.UI
	},
	mutations: {},
	getters: {},
	actions: {},
	modules: {
		alert,
		auth,
		settings
	}
})
