import store from "@/store";

const icons = {
  ecruise: {
    // global
    sidebarPos: require('@/assets/img/global/all__pos.svg'),
    sidebarChecks: require('@/assets/img/global/all__checks.svg'),
    sidebarDashboard: require('@/assets/img/global/all__dashboard.svg'),
    sidebarReports: require('@/assets/img/global/all__reports.svg'),
    sidebarPrinters: require('@/assets/img/global/all__printers.svg'),
    sidebarSettings: require('@/assets/img/global/all__settings.svg'),
    mainLogo: require('@/assets/img/global/ecruise__mainLogo.svg'),
    alertError: require('@/assets/img/global/all__alert_error.svg'),
    loader: require('@/assets/img/global/ripple-1s-200px.svg'),
    checkboxCheckedBg: require('@/assets/img/global/checked_checkbox_bg.svg'),
    checkboxChecked: require('@/assets/img/global/checkbox_checked.svg'),
    checkboxUnchecked: require('@/assets/img/global/checkbox_unchecked.svg'),
    checkboxAnotherChecked: require('@/assets/img/global/checkbox_another_checked.svg'),
    checkboxSomeChecked: require('@/assets/img/global/checkbox_some_checked.svg'),
    menuItem: require('@/assets/img/global/menu_item.svg'),
    menuMealBlk: require('@/assets/img/global/menu_meal_black.svg'),
    menuItemCombo: require('@/assets/img/global/menu_item_combo.svg'),
    menuCategory: require('@/assets/img/global/ecruise__menu_category.svg'),
    minusBig: require('@/assets/img/global/ecruise__minus_big.svg'),
    plusBig: require('@/assets/img/global/ecruise__plus_big.svg'),
    minusBg: require('@/assets/img/global/ecruise__minus_bg.svg'),
    plusBg: require('@/assets/img/global/ecruise__plus_bg.svg'),
    radioOn: require('@/assets/img/global/ecruise__radio_on.svg'),
    radioOff: require('@/assets/img/global/ecruise__radio_off.svg'),
    barcode: require('@/assets/img/global/barcode.svg'),
    dots: require('@/assets/img/global/dots.svg'),
    plus: require('@/assets/img/global/ecruise__plus.svg'),
    kitchenPrinter: require('@/assets/img/global/kitchen_printer.svg'),
    invoicePrinter: require('@/assets/img/global/invoice_printer.svg'),
    arrowRight: require('@/assets/img/global/arrow_right.svg'),
    arrowLeft: require('@/assets/img/global/arrow_left.svg'),
    arrowRightWh: require('@/assets/img/global/arrow_right_wh.png'),
    arrowLeftWh: require('@/assets/img/global/arrow_left_wh.png'),
    dropdown: require('@/assets/img/global/dropdown.svg'),
    chosen: require('@/assets/img/global/ecruise__chosen.svg'),
    add: require('@/assets/img/global/ecruise__add.svg'),
    clear: require('@/assets/img/global/clearfield.svg'),
    search: require('@/assets/img/global/search.svg'),
    warning: require('@/assets/img/global/warning.svg'),
    home: require('@/assets/img/global/home.svg'),
    backTo: require('@/assets/img/global/back_to.svg'),
    backSpace: require('@/assets/img/global/back_space.png'),
    backSpaceBlk: require('@/assets/img/global/back_space_blk.svg'),
    close: require('@/assets/img/global/ecruise__close.svg'),
    intoxInfo: require('@/assets/img/global/intox-info.svg'),

    // pos
    greenTable: require('@/assets/img/pos/green_table.svg'),
    redTable: require('@/assets/img/pos/red_table.svg'),
    yellowTable: require('@/assets/img/pos/yellow_table.svg'),
    // shared
    bdRolled: require('@/assets/img/shared/ecruise__bd_rolled.svg'),
    bdStopped: require('@/assets/img/shared/ecruise__bd_stopped.svg'),
    // btn
    refresh: require('@/assets/img/buttons/ecruise__refresh.svg'),
    menuGrid: require('@/assets/img/buttons/ecruise__menu_view_g.svg'),
    menuList: require('@/assets/img/buttons/ecruise__menu_view_l.svg'),
    logout: require('@/assets/img/buttons/ecruise__logout-icon.svg'),
    closeRed: require('@/assets/img/buttons/close_red.jpg'),
    addWhite: require('@/assets/img/buttons/add_white.svg'),
    delete: require('@/assets/img/buttons/delete.svg'),
    cancelOrder: require('@/assets/img/buttons/cancel_cross_wh.svg'),
    checksWh: require('@/assets/img/buttons/checks_wh.svg'),
    closeOrderWh: require('@/assets/img/buttons/close_order_wh.svg'),
    editDiscount: require('@/assets/img/buttons/edit_discs.svg'),
    hideCalendar: require('@/assets/img/buttons/hide_calendar.svg'),
    showCalendar: require('@/assets/img/buttons/show_calendar.svg'),
    printCheck: require('@/assets/img/buttons/print_check.svg'),
    reopenOrder: require('@/assets/img/buttons/reopen_order.svg'),
    resetOrder: require('@/assets/img/buttons/reset_order.svg'),
    printFoodRunner: require('@/assets/img/buttons/ecruise__print_food_runner.svg'),
  },

  margaritaville: {
    // global
    sidebarPos: require('@/assets/img/global/all__pos.svg'),
    sidebarChecks: require('@/assets/img/global/all__checks.svg'),
    sidebarDashboard: require('@/assets/img/global/all__dashboard.svg'),
    sidebarReports: require('@/assets/img/global/all__reports.svg'),
    sidebarPrinters: require('@/assets/img/global/all__printers.svg'),
    sidebarSettings: require('@/assets/img/global/all__settings.svg'),
    mainLogo: require('@/assets/img/global/margaritaville__mainLogo.png'),
    mainLogoPalm: require('@/assets/img/global/margaritaville__mainLogoPalm.png'),
    logoRainbow: require('@/assets/img/global/margaritaville__logoRainbow.png'),
    alertError: require('@/assets/img/global/all__alert_error.svg'),
    loader: require('@/assets/img/global/ripple-1s-200px.svg'),
    checkboxCheckedBg: require('@/assets/img/global/checked_checkbox_bg.svg'),
    checkboxChecked: require('@/assets/img/global/checkbox_checked.svg'),
    checkboxUnchecked: require('@/assets/img/global/checkbox_unchecked.svg'),
    checkboxAnotherChecked: require('@/assets/img/global/checkbox_another_checked.svg'),
    checkboxSomeChecked: require('@/assets/img/global/checkbox_some_checked.svg'),
    menuItem: require('@/assets/img/global/menu_item.svg'),
    menuItemCombo: require('@/assets/img/global/menu_item_combo.svg'),
    menuCategory: require('@/assets/img/global/margaritaville__menu_category.svg'),
    minusBig: require('@/assets/img/global/margaritaville__minus_big.svg'),
    plusBig: require('@/assets/img/global/margaritaville__plus_big.svg'),
    minusBg: require('@/assets/img/global/margaritaville__minus_bg.svg'),
    plusBg: require('@/assets/img/global/margaritaville__plus_bg.svg'),
    radioOn: require('@/assets/img/global/ecruise__radio_on.svg'),
    radioOff: require('@/assets/img/global/ecruise__radio_off.svg'),
    barcode: require('@/assets/img/global/barcode.svg'),
    dots: require('@/assets/img/global/dots.svg'),
    plus: require('@/assets/img/global/ecruise__plus.svg'),
    kitchenPrinter: require('@/assets/img/global/kitchen_printer.svg'),
    invoicePrinter: require('@/assets/img/global/invoice_printer.svg'),
    arrowRight: require('@/assets/img/global/arrow_right.svg'),
    arrowLeft: require('@/assets/img/global/arrow_left.svg'),
    arrowRightWh: require('@/assets/img/global/arrow_right_wh.png'),
    arrowLeftWh: require('@/assets/img/global/arrow_left_wh.png'),
    dropdown: require('@/assets/img/global/dropdown.svg'),
    itemEdit: require('@/assets/img/global/margaritaville__item_edit.svg'),
    chosen: require('@/assets/img/global/ecruise__chosen.svg'),
    add: require('@/assets/img/global/ecruise__add.svg'),
    clear: require('@/assets/img/global/clearfield.svg'),
    search: require('@/assets/img/global/search.svg'),
    warning: require('@/assets/img/global/warning.svg'),
    home: require('@/assets/img/global/home.svg'),
    backTo: require('@/assets/img/global/back_to.svg'),
    backSpace: require('@/assets/img/global/back_space.png'),
    backSpaceBlk: require('@/assets/img/global/back_space_blk.svg'),
    close: require('@/assets/img/global/margaritaville__close.svg'),
    intoxInfo: require('@/assets/img/global/intox-info.svg'),
    
    // pos
    greenTable: require('@/assets/img/pos/green_table.svg'),
    redTable: require('@/assets/img/pos/red_table.svg'),
    yellowTable: require('@/assets/img/pos/yellow_table.svg'),
    // shared
    bdRolled: require('@/assets/img/shared/ecruise__bd_rolled.svg'),
    bdStopped: require('@/assets/img/shared/ecruise__bd_stopped.svg'),
    // btns
    refresh: require('@/assets/img/buttons/margaritaville__refresh.png'),
    menuGrid: require('@/assets/img/buttons/ecruise__menu_view_g.svg'),
    menuList: require('@/assets/img/buttons/ecruise__menu_view_l.svg'),
    logout: require('@/assets/img/buttons/ecruise__logout-icon.svg'),
    closeRed: require('@/assets/img/buttons/close_red.jpg'),
    addWhite: require('@/assets/img/buttons/add_white.svg'),
    delete: require('@/assets/img/buttons/delete.svg'),
    cancelOrder: require('@/assets/img/buttons/cancel_cross_wh.svg'),
    checksWh: require('@/assets/img/buttons/checks_wh.svg'),
    closeOrderWh: require('@/assets/img/buttons/close_order_wh.svg'),
    editDiscount: require('@/assets/img/buttons/edit_discs.svg'),
    hideCalendar: require('@/assets/img/buttons/hide_calendar.svg'),
    showCalendar: require('@/assets/img/buttons/show_calendar.svg'),
    printCheck: require('@/assets/img/buttons/print_check.svg'),
    reopenOrder: require('@/assets/img/buttons/reopen_order.svg'),
    resetOrder: require('@/assets/img/buttons/reset_order.svg'),
    printFoodRunner: require('@/assets/img/buttons/margaritaville__print_food_runner.svg'),

  }
}

let iconConst = icons[store.state.UI];

export default iconConst;
