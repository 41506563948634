<template>
	<!--v-click-outside="$emit('clickOutside')"-->
	<div class="keypad">
		<div class="flex-row">
			<button @click="$emit('clickOnKey',1)" class="number">1</button>
			<button @click="$emit('clickOnKey',2)" class="number">2</button>
			<button @click="$emit('clickOnKey',3)" class="number">3</button>
			<button @click="$emit('clickOnKey',4)" class="number">4</button>
			<button @click="$emit('clickOnKey',5)" class="number">5</button>
			<button @click="$emit('clickOnKey',6)" class="number">6</button>
			<button @click="$emit('clickOnKey',7)" class="number">7</button>
			<button @click="$emit('clickOnKey',8)" class="number">8</button>
			<button @click="$emit('clickOnKey',9)" class="number">9</button>
			<button @click="$emit('clickOnKey',0)" class="number">0</button>
			<button v-if="!keyboardToggle" :style="{visibility: dot ? 'visible' : 'hidden'}" @click="$emit('clickOnKey','.')" class="number">.</button>
			<button v-else :style="{visibility: dot ? 'visible' : 'hidden'}" @click="$emit('toggleKeyboardType', 'keyboard')" class="number">ABC</button>
			<button @click="$emit('clear')" class="number">
				<icon-component iconName="backSpaceBlk" />
			</button>
		</div>
	</div>
</template>

<script>
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: "Keypad",
	components: {iconComponent},
	props: {
		keyboardToggle: {
			default: false,
			required: false,
			type: Boolean
		},
		dot: {
			default: true,
			required: false
		}
	},
	data() {
		return {}
	},
	methods: {}
}
</script>
<style lang="less" scoped>
.keypad {
	z-index: 1112;
	background: #F5F9FC;
	width: 396px;
	height: 324px;
	// border: 2px solid #3F8EC5;
	box-sizing: border-box;
	border-radius: 12px;
	
	.flex-row {
		display: flex;
		padding: 27px 15px;
		flex-wrap: wrap;
		
		.number {
			border: none;
			width: 119px;
			height: 63px;
			background: white;
			border-radius: 2px;
			flex: 1 1 30%;
			margin-right: 5px;
			margin-bottom: 6px;
			font-weight: 600;
			font-size: 32px;
			line-height: 43px;
			align-items: center;
			text-align: center;
			letter-spacing: 0.291225px;
		}
		
		/*.number:not(:last-child) {
			box-shadow: 0 2px 0 #3F8EC5;
		}*/
		
		.number:active {
			box-shadow: 0 2px 0 white;
			background: #f8e9e9;
		}
	}
}
</style>
