<template>
	<button
		type="button"
		class="refresh-btn square-btn def-btn secondary-action-btn"
		:class="{'animation': animation}"
		:style="customStyles"
		@click="clickEvent"
		v-ripple-effect
	>
		<icon-component iconName="refresh" />
		<span v-if="text">{{ text }}</span>
	</button>
</template>

<script>
import IconComponent from "../_shared/Icon.vue";

export default {
	name: 'RefreshBtnComponent',
	props: {
		text: {
			type: String,
			required: false,
			default: ''
		},
		customStyles: {
			default: '',
			required: false,
			type: String
		}
	},
	components: { IconComponent },
	data() {
		return {
			animation: false
		}
	},
	computed: {
			design(){
				return this.$store.state.UI
			},
    },
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		onAnimate() {
			this.animation = true;
			setTimeout(() => {this.animation = false}, 1000);
		},
		stopAnimation() {
			this.animation = false;
		},
		clickEvent() {
			this.$emit('clickEvent');
			this.onAnimate();
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
.refresh-btn {
	min-width: 60px;
	width: 60px;
	height: 60px;

	span {
		margin-left: 10px;
		font-family: 'Segoe UI';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		text-transform: uppercase;
		color: #3F8EC5;
	}
	&.animation {
		img {
			animation: spin 1s infinite;
		}
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
	/*100% {
		transform: rotate(0deg);
	}*/
}
</style>
