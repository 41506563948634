<template>
    <div id="modal-wrapper">
	    <modal-component ref="modal" :has-keyboard="true" :show-keyboard="selectedOrder && selectedOrder.payment_type ?
	    selectedOrder.payment_type === 2 : false" @clickOnKey="setHouseAccountNumber"
	                     @onModalClose="$emit('onModalClose')">
		    <div id="close-order-modal" class="modal-container">
			    <div class="modal-header">Finalize order</div>
			    <div class="total-wrapper">
				    <div class="total-title">Total Payable:</div>
				    <div class="total">${{ selectedOrder.total ? selectedOrder.total.toFixed(2) :
						    selectedOrder.subtotal.toFixed(2) }}</div>
			    </div>
			    <div class="payment">
				    <div class="payment-header">Payment method:</div>
				    <div class="payment-methods">
					    <div @click="$emit('toggleCPT', this.paymentMethods[0].id)" class="method">
						    <div class="method-title">Cabin/Crew charge</div>
						    <div v-if="selectedOrder.payment_type === 1" class="method-active">
							    <icon-component iconName="chosen" />
						    </div>
					    </div>
					    <div @click="$emit('toggleCPT', this.paymentMethods[1].id)" class="method">
						    <div class="method-title">House account</div>
						    <div v-if="selectedOrder.payment_type === 2" class="method-active">
							    <icon-component iconName="chosen" />
						    </div>
					    </div>
				    </div>
			    </div>
			    <div class="payers" v-if="selectedOrder.payment_type === 1">
				    <div class="payers-header">
					    Payer(s):
				    </div>
				    <div class="payers-list">
					    <table>
						    <tr>
							    <th>Name</th>
							    <th>Cabin</th>
							    <th>Payment</th>
							    <th>Delete</th>
						    </tr>
						    <tr v-for="(i, index) in tempUsers">
							    <td>{{`${i.first_name ? i.first_name : ''} ${i.last_name}`}}</td>
							    <td>{{i.cabin}}</td>
							    <td>{{i.person_type === 'C' ? 'Crew charge' : 'Cabin charge'}}</td>
							    <td>
										<span @click="onDeletePayer(index)">
											<icon-component iconName="delete" />
										</span>
									</td>
						    </tr>
					    </table>
				    </div>
				    <div class="def-btn secondary-action-btn icon left uppercase border-2 medium" @click="openUsersModal" :class="{'disabled': tempUsers.length >= constants.maxPayers}">
					    <icon-component iconName="add" />
					    Add payer
				    </div>
			    </div>
			    <div class="payers" v-if="selectedOrder.payment_type === 2">
				    <div class="payers-header">
					    House account number:
				    </div>
					<input v-model.trim="houseAccountInput" class="input-houseAccount" @input="(e) => $emit('houseAccountInputChange', e.target.value)">
			    </div>
			    <div class="modal-controls x2">
				    <button @click="close" class="def-btn cancel-action-btn uppercase  medium border-2">NO</button>
				    <button @click="closeOrder" class="def-btn main-action-btn uppercase medium border-2"
				            :class="{'disabled': (selectedOrder.payment_type === 1 &&
				            !tempUsers.length ||
				            selectedOrder.payment_type === 2 && houseAccountInput.length < 4)
				            }">YES</button>
			    </div>
		    </div>
	    </modal-component>
	    <users-modal
		    v-if="usersModalRef"
			ref="usersModalRef"
			:selected-order="selectedOrder"
			:new-order-creation="false"
			@addPayer="onSetPayer"
		    @onModalClose="onUsersModalClose"
		/>
		<confirmation-modal-component ref="confirmation-modal-component" :has-cancel-btn="false"/>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import UsersModal from "@/components/_modals/UsersModal";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import {usersService} from "@/_api/users.api";
import BaseButton from "@/components/_shared/Button.vue";
import {constants} from "@/constants/constants";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
    name: "CloseOrderModal",
    emits:['closeOrder','upload','closeModal', 'onModalClose', 'houseAccountInputChange', 'toggleCPT'],
    components: {UsersModal, ModalComponent, ConfirmationModalComponent, iconComponent},
    props:{
        selectedOrder: {
            type: Object,
	        required: true
        },
    },
    data() {
        return {
			paymentMethods: constants.paymentMethods,
	        usersModalRef: false,
			houseAccountInput: '',
	        tempUsers: [],
	        loading: false
        }
    },
	computed: {
		constants() {
			return constants
		},
		calcFullTotal() {
			let total = 0;
			this.selectedOrder.items.forEach(t => {
				t.condiments_parsed.forEach((o) => {
					if (o.type === 1) {
						o.items.forEach(oo => {
							if (oo.checked) {
								total += oo.count * o.count * parseFloat(oo.price).toFixed(2)
							}
						})
					}
				});
			})
			if (this.selectedOrder.discount !== null) {
				return parseFloat(this.selectedOrder.total + total).toFixed(2)
			} else {
				return parseFloat(this.selectedOrder.subtotal + total).toFixed(2)
			}
		},
		maxUserLimitExceeded() {
			return this.tempUsers.length <= constants.maxPayers
		}
	},
	watch: {
		houseAccountInput() {
			this.houseAccountInput = this.houseAccountInput.replace(/[^0-9]/g, '');
		}
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
		this.emmiter.on('stopLoading', () => {
			this.loading = false;
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.off('stopLoading');
		this.tempUsers = [];
		this.emmiter.emit('toggleModals', {opened: false, type: 'CloseOrderModal'});
	},
	methods: {
		onUsersModalClose() {
			this.usersModalRef = false;
		},
		refreshPayers() {
			if (this.selectedOrder.payers.length) {
				for (let i = 0; i < this.selectedOrder.payers.length; i++) {
					this.loading = true;
					usersService.getSinglePosUser(this.selectedOrder.payers[i].id).then((res) => {
						this.selectedOrder.payers[i] = res;
						this.selectedOrder.payers[i]['main'] = true;
						this.loading = false;
						this.tempUsers.push(this.selectedOrder.payers[i]);
					})
				}
			}
		},
        openModal() {
			this.refreshPayers();
			this.$refs['modal'].show();
			this.houseAccountInput = this.selectedOrder.payment_house_account_number ?
					this.selectedOrder.payment_house_account_number : '';
			this.emmiter.emit('toggleModals', {opened: true, type: 'CloseOrderModal'});
        },
        close() {
	        this.houseAccountInput = '';
			this.tempUsers = [];
	        this.$refs['modal'].close();
	        this.emmiter.emit('toggleModals', {opened: false, type: 'CloseOrderModal'});
        },
	    openUsersModal() {
			if (this.tempUsers.length < constants.maxPayers) {
				this.usersModalRef = true;
				this.$nextTick(() => {
					this.$refs['usersModalRef'].openModal({
						modalTitleText: 'Add payer',
						modalSubmitBtnText: 'Add',
					});
				})
			}
			
	    },
        closeOrder() {
	        if (!this.loading && this.tempUsers.length <= constants.maxPayers) {
				if ((this.selectedOrder.payment_type === 1 && this.tempUsers.length && !this.tempUsers.some((o) =>
						o.intoxication_state)) || this.selectedOrder.payment_type === 2) {
					let data = {
						id: this.selectedOrder.id,
				        payment_type: null,
			        }
			        if (this.selectedOrder.payment_type === 2) {
						data.houseAccount = parseInt(this.houseAccountInput);
			        }
					data.payment_type = this.selectedOrder.payment_type;
					this.loading = true;
					this.selectedOrder.payers = this.tempUsers;
					this.emmiter.emit('closeOrder', data);
		        }
				if (this.selectedOrder.payment_type === 1 && this.tempUsers.some((o) => o.intoxication_state)) {
					if (this.selectedOrder.items.every((o) => o.legal_age_class !== 1)) {
						let data = {
							id: this.selectedOrder.id,
							payment_type: 1,
						}
						this.loading = true;
						this.selectedOrder.payers = this.tempUsers;
						this.emmiter.emit('closeOrder', data);
					} else {
						let alcos = this.tempUsers.filter((o) => o.intoxication_state);
						let alcosName = [];
						alcos.forEach((o) => alcosName.push(o.first_name + ' ' + o.last_name));
						this.openConfirmationToxicModal(alcosName);
					}
				}
	        }
			
        },
	    onDeletePayer(index) {
		    this.tempUsers.splice(index, 1);
			this.$forceUpdate();
	    },
	    onSetPayer(data) {
			let index = this.tempUsers.findIndex((i) => {return i.id === data.id});
			if (index === -1) {
				this.tempUsers.push(data);
				this.$refs['usersModalRef'].close();
			} else {
				this.$refs['confirmation-modal-component'].show({
					title: 'Attention',
					message: 'User is already in the list',
					okButton: 'OK',
				});
				
			}
	    },
		setHouseAccountNumber(e) {
			if (e === undefined) {
				this.houseAccountInput = this.houseAccountInput.substring(0, this.houseAccountInput.length - 1);
				this.$emit('houseAccountInputChange', this.houseAccountInput);
			} else {
				this.houseAccountInput += e;
				this.$emit('houseAccountInputChange', this.houseAccountInput);
				
			}
		},
		openConfirmationToxicModal(alcosName) {
			let names = alcosName[0];
			if (alcosName.length > 1) {
				alcosName.shift();
				alcosName.forEach((o) => names += (',' + ' ' + o));
			}
		    this.$refs['confirmation-modal-component'].show({
			    title: 'Restricted',
			    message: `Payer ${names} has intoxication state.`,
			    okButton: 'OK',
		    })
	    },
    }
}
</script>

<style scoped lang="less">
#modal-wrapper {
	#close-order-modal {
		width: 650px;
		.total-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 5px;
			margin-top: 24px;
			border-top: 1px solid rgba(53, 131, 197, .2);
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			color: #1C282D;
			width: 100%;
		}
		.payers {
			margin-bottom: 40px;
			.payers-header {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				text-align: center;
				color: #1C282D;
				margin-bottom: 10px;
			}
			.input-houseAccount {
				margin: 40px 0 50px 0;
				padding: 0 10px;
				border: none;
				border-bottom: 2px solid;
				font-size: 20px;
				max-width: 150px;
				width: 100%;
			}
			.payers-list {
				margin-bottom: 10px;
				table {
					width: 100%;
					table-layout: fixed;
					margin: 0;
					border-collapse: collapse;
					box-sizing: border-box;
					position: relative;
					tr {
						display: table;
						width: 100%;
						table-layout: fixed;
						&:nth-child(odd) {
							background-color: #FAF9FB;
						}
						th {
							border: 1px solid #e9ecef;
							vertical-align: middle;
							height: 30px;
							padding: 5px 10px;
							overflow-wrap: break-word;
							color: #14191f;
							font-size: 10px;
							text-align: center;
							text-transform: uppercase;
							&:nth-child(1) {
								width: 40%!important;
							}
							&:nth-child(2) {
								width: 25%!important;
							}
							&:nth-child(3) {
								width: 25%!important;
							}
							&:nth-child(4) {
								width: 10%!important;
							}
						}
						td {
							vertical-align: middle;
							height: 30px;
							padding: 5px 10px;
							overflow-wrap: break-word;
							color: #1a141f;
							font-size: 14px;
							text-align: center;
							border: 1px solid #e9ecef;
							&:nth-child(1) {
								width: 40%!important;
							}
							&:nth-child(2) {
								width: 25%!important;
							}
							&:nth-child(3) {
								width: 25%!important;
							}
							&:nth-child(4) {
								width: 10%!important;
								span {
									cursor: pointer;
									border: 1px solid #D9E8F3;
									border-radius: 12px;
									display: flex;
									justify-content: center;
									align-items: center;
									width: 40px;
									height: 30px;
									align-self: center;
									margin: 0 auto;
								}
							}
						}
					}
				}
			}
			.def-btn {
				width: 40%;
				margin: 0 auto;
			}
		}
		.payment {
			margin-bottom: 50px;
			&-header {
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				text-align: center;
				color: #1C282D;
				margin-bottom: 10px;
			}
			&-methods {
				.method {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 41px;
					border-bottom: 1px solid rgba(53, 131, 197, .2);
					padding-right: 15px;
					&:active{
						background: #D9E8F3;
						//border: none;
					}
				}
				
			}
		}
	}
}

</style>
