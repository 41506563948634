<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" @onModalClose="onModalClose()">
			<div id="intoxication-mark-info-modal" class="modal-container">
				<div class="modal-header">
					Intoxication mark info
				</div>
				<div class="content">
					<div class="table">
						<table>
							<tr>
								<th>date</th>
								<th>state</th>
								<th>department</th>
								<th>waiter</th>
							</tr>
							<tr>
								<td>{{intoxicationMarkInfo.date}}</td>
								<td :class="{'state-on': intoxicationMarkInfo.state}">{{intoxicationMarkInfo.state ? 'ON' : 'OFF'}}</td>
								<td>{{intoxicationMarkInfo.department}}</td>
								<td>{{intoxicationMarkInfo.causer}}</td>
							</tr>
						</table>
					</div>
					<div class="comment-wrapper">
						<div class="comment-title">Comment</div>
						<div class="comment">
							{{intoxicationMarkInfo.comment}}
						</div>
					</div>
					
				</div>
				<div class="modal-controls x1">
					<base-button text="ok" classes="def-btn main-action-btn uppercase medium border-2" @onClick="closeModal()"/>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import BaseButton from "@/components/_shared/Button.vue";

export default {
	name: 'IntoxicationMarkInfoModalComponent',
	props: {
		intoxicationMarkInfo: {
			default: null,
			required: true
		}
	},
	components: {ModalComponent, BaseButton},
	data() {
		return {}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
	},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		onModalClose() {},
		openModal() {
			this.$refs['modal'].show()
		},
		closeModal() {
			this.$refs['modal'].close()
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
#modal-wrapper {
	#intoxication-mark-info-modal {
		width: 600px;
		.content {
			margin-bottom: 40px;
			padding: 0 5%;
			.table {
				width: 100%;
				padding: 0;
				box-sizing: border-box;
				margin-bottom: 30px;
				table {
					width: 100%;
					table-layout: fixed;
					margin: 0;
					border-collapse: collapse;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 20px;
					tr {
						&:first-child {
							border-top: 1px solid #e9ecef;
						}
						&:not(:last-child) {
							border-bottom: 1px solid #e9ecef;
						}
						&:last-child {
							border-bottom: 1px solid #e9ecef;
						}
						th {
							font-weight: 600;
							padding: 10px 0;
							text-align: left;
							font-size: 12px;
							text-transform: uppercase;
							color: black;
							&:nth-child(1) {
								width: 26%!important;
							}
							&:nth-child(2) {
								width: 10%!important;
							}
							&:nth-child(3) {
								width: 35%!important;
							}
							&:nth-child(4) {
								width: 29%!important;
							}
						}
						td {
							padding: 10px 0;
							text-align: left;
							font-size: 12px;
							&.state-on {
							
							}
							&:nth-child(1) {
								width: 26%;
							}
							&:nth-child(2) {
								width: 10%!important;
								color: red
							}
							&:nth-child(3) {
								width: 35%!important;
							}
							&:nth-child(4) {
								width: 29%!important;
							}
						}
					}
				}
			}
			.comment-wrapper {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-content: flex-start;
				.comment-title {
					text-align: left;
					width: 100%;
					font-weight: 600;
					font-size: 12px;
					text-transform: uppercase;
					margin-bottom: 10px;
					color: black;
				}
				.comment {
					font-weight: 400;
					font-size: 16px;
					color: #1C282D;
				}
			}
		}
	}
}
</style>
