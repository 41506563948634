<template>
  <img :src="getIcon()" alt="icon">
</template>

<script>
import iconConst from "@/constants/icon-const";

export default {
  name: 'IconComponent',
	props: {
		iconName: {
			type: String,
      require: true,
		},
	},
	methods: {
		getIcon() {
			return iconConst[`${this.iconName}`];
		},
	},
}
</script>

<style lang="less" scoped>
  
</style>