<template>
	<nav id="nav" :class="design">
		<div class="content left-content" v-if="design==='ecruise'">
			<icon-component iconName="mainLogo" class="logo" />

			<div v-if="authData.posId && posDataValue && posDataValue.department" class="department-title">
				{{ posDataValue.department.name }}
			</div>

			<refresh-btn-component
				@click="refreshSettings"
				class="refresh-btn"
				:text="'refresh'"
				:customStyles="'padding:0; width: 122px; height: 60px; border: 2px solid #3F8EC5; box-shadow: none'"
			></refresh-btn-component>

			<!-- <base-button text="refresh" classes="def-btn secondary-action-btn uppercase medium border-2 icon left" customStyles="padding:0; width: 122px; height: 60px;" imgSrc="24refresh.svg" @onClick="refreshSettings"/> -->

			<div v-if="businessDayStatus === 'rolled'" class="day-status day-status_r">
				<icon-component iconName="bdRolled" />
				<p>Business day In Progress</p>
			</div>
			<div v-if="businessDayStatus === 'stopped'" class="day-status day-status_s">
				<icon-component iconName="bdStopped" />
				<p>COMPLETED</p>
			</div>
			<!-- <button :class="'def-btn main-action-btn big border-2 medium icon left uppercase ' +
			design"
							@click="changeDepartment()">
				<span>CHANGE DEPARTMENT</span>
			</button> -->
		</div>
		<div class="content left-content" v-if="design==='margaritaville'">
			<icon-component iconName="mainLogoPalm" style="margin: 20px 0 0 -20px" />
			<icon-component iconName="mainLogo" style="margin-left: -110px" />

			<refresh-btn-component 
				@click="refreshSettings" 
				class="refresh-btn" 
				:text="'refresh'"
				:customStyles="'padding:0; margin-left: 24px; width: 122px; height: 60px; border: 2px solid #3F8EC5; box-shadow: none'"
			></refresh-btn-component>

			<div v-if="businessDayStatus === 'rolled'" class="day-status day-status_r">
				<icon-component iconName="bdRolled" />
				<p>Business day In Progress</p>
			</div>

			<div v-if="businessDayStatus === 'stopped'" class="day-status day-status_s">
				<icon-component iconName="bdStopped" />
				<p>COMPLETED</p>
			</div>
		</div>

		<div class="content right-content">
			<div v-if="authData.status.loggedIn" class="pos-title">{{ authData.user.user.first_name }}
				{{ authData.user.user.last_name }}
				<span v-if="authData.user.role === 'waiter assist'">(assistant)</span>
			</div>

			<base-button text="log out" classes="def-btn main-action-btn uppercase medium border-2 icon left" customStyles="padding:0; width: 156px; height: 60px;" imgSrc="logout" @onClick="logout"/>
		</div>
		<!--:has-keyboard="true" :show-keyboard="inFocus.length > 0" @clickOnKey="click"-->
		<!-- <modal-component ref="modal" :has-keyboard="true" @clickOnKey="clickOnKey" @onModalClose="onModalClose">
			<terminal-setup-root-component ref="terminal-setup-root-component" :isAuth="true" @dep-changed="depChanged()"
			></terminal-setup-root-component>
		</modal-component> -->
	</nav>
</template>

<script>
import {authService} from "@/_api/auth.api";
import store from "@/store";
import ModalComponent from "@/components/_shared/Modal.vue";
import TerminalSetupRootComponent from "@/views/auth/TerminalSetup.vue";
import BaseButton from "@/components/_shared/Button.vue";
import getPosHelper from "@/_helpers/get-pos-helper";
import {settingsService} from "@/_api/settings.api";
import RefreshBtnComponent from "@/components/_shared/RefreshButton";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'HeaderComponent',
	props: {},
	components: {TerminalSetupRootComponent, ModalComponent, BaseButton, RefreshBtnComponent, iconComponent},
	data() {
		return {
			// posData: null
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
		authData() {
			this.posData = this.$store.state.auth.posData
			return this.$store.state.auth
		},
		posDataValue() {
			return this.$store.state.auth.posData;
		},
		businessDayStatus() {
			return this.$store.state.settings.businessDay.status;
		},
	},
	created() {
		// if (this.authData.status.loggedIn && this.authData.posId) {
		// 	this.getPos();
		// }
	},
	mounted() {},
	beforeUnmount() {},
	methods: {
		refreshSettings() {
			this.getPos();
			this.getSettings();
		},
		getPos() {
			getPosHelper(this.authData.posId);
		},
		getSettings() {
			settingsService.getSettings().then((res) => {
				store.dispatch('setLegalAge', res.data);
			})
		},
		logout() {
			this.$store.dispatch('logoutUser').then(() => {
				this.$router.replace({path: '/auth/login'})
			})
		},
		changeDepartment() {
			this.$refs.modal.open();
		},
		// depChanged() {
		// 	this.getPos();
		// 	// this.$refs.modal.close();
		// },
		onModalClose() {},
		clickOnKey(e) {
			if (e === undefined) {
				this.$refs['terminal-setup-root-component'].clear();
			} else {
				this.$refs['terminal-setup-root-component'].clickOnKey(e);
			}
		},
	},
	watch: {}
}
</script>

<style lang="less" scoped>

#nav {
	display: none;
	height: 54px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	padding: 15px 18px;
	.content {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		&.right-content {
			margin-right: 4px;
			.def-btn {
				padding: 30px 40px;
				&.margaritaville {
					/*background-color: var(--second_theme_color);
					border-color: var(--second_theme_color);*/
				}
			}
		}
		.logo {
			width: 60px;
			height: 54px;
			margin-right: 24px;
		}
		.department-title {
			margin-right: 24px;
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			text-transform: uppercase;
			color: #3f8ec5;
		}

		.day-status {
			margin-left: 26px;
			display: flex;
			align-items: center;
			gap: 6px;
			text-transform: uppercase;
			img {
				width: 30px;
			}
			p {
				margin: 0;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 21px;
			}
			&_r {
				p {
					color: #82B941;
				}
			}
			&_s {
				p {
					color: #EB262B;
				}
			}
		}
		.pos-title {
			margin-right: 24px;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
			text-align: right;
			text-transform: uppercase;
		}
		span {
			margin-left: 5px
		}
	}
}

@media (max-width: 1200px) {
	#nav {
		height: 50px;
		.content {
			.logo {
				height: 50px;
			}
			.def-btn {
				padding: 5px 25px!important;
			}
		}
	}
}
@media (max-width: 850px) {

}
</style>
