<template>
	<div id="orders-list" class="orders-list">
		<div class="controls-wrapper" v-if="combineOrder">
			<base-button customStyles="margin-right: 12px;" text="Combine orders" :isDisabled="!hasCheckedOptions || loading" class="fn_btn" classes="def-btn main-action-btn uppercase medium border-2" @onClick="combineOrders"/>
			<base-button :imgSrc="'closeRed'" :customStyles="'min-width: 60px; width: 60px; height: 60px; border: none'" classes="def-btn secondary-action-btn icon one square-btn" @onClick="resetCombine"/>
		</div>
		<div class="controls-wrapper" v-else>
			<base-button text="NEW ORDER" customStyles="margin-right: 12px;" imgSrc="addWhite" class="fn_btn" classes="def-btn main-action-btn icon left uppercase medium border-2" @onClick="openNewOrderModal"/>
			<refresh-btn-component ref="refresh-btn" @click="this.getOrders({resetList: true, resetSelected: true})" class="refresh-btn" :customStyles="'border: none;'"></refresh-btn-component>
		</div>
		<search-bar-component @onInput="(e) => this.debounceSearch(e)" parentComp="menuList" :placeholder="'Table, Waiter, etc.'" @onClear="onInputClear"></search-bar-component>
		<div class="loading" v-if="loading">
			<icon-component iconName="loader" />
		</div>
		<div v-else class="list" @scroll="(e) => this.ordersListScrollHandle(e)">
			<div v-for="(i, index) in orders" :key="index" @click="onOrderSelect(i,index)" class="order" :class="{'active-order': i.active, 'highlighted': isHighlighted(i)}">
				<div class="table">
					<div class="table-wrapper">
						<icon-component  v-if="i.status === 1" :iconName="configureOrderStatus(i)" />
						<div class="table-number">{{ i.table ? i.table : null }}</div>
					</div>
					<div class="checkbox-wrap" v-if="combineOrder" @click.stop.prevent="i.idCheck ? null : i.checked = !i.checked">
						<icon-component v-if="i.idCheck" iconName="checkboxCheckedBg" />
						<icon-component v-else-if="i.checked" iconName="checkboxChecked" />
						<icon-component v-else iconName="checkboxUnchecked" />
					</div>
					<icon-component v-else iconName="dots" class="order-options" @click="openOrderOptionsModal(i)" />
				</div>
				<div class="text waiter">WAITER: <span>{{ i.opened_by.name }}</span></div>
				<div class="text guest">GUEST:
					<span>{{ (i.payers.length ? `${i.payers[0].first_name ? i.payers[0].first_name : ''} ${i.payers[0].last_name}` : 'Not specified') }}</span>
				</div>
				<div class="text cabin">CABIN:
					<span>{{
							i.payers.length ? (i.payers[0].cabin ? i.payers[0].cabin : 'Not specified') : 'Not specified'
						}}</span>
				</div>
			</div>
		</div>
	</div>
	<new-order-modal-component
			v-if="showNewOrderModal"
			ref="new-order-modal"
			:set-payer-after="authData.posData.department.flow_ordering_guest"
			@addTempOrder="createTempOrder"
	/>
	<order-options-modal
			v-if="showOrderOptionsModal"
			ref="order-options-modal"
			@combineOrder="combineOrder = true"
			@splitOrder="splittingOrder"
			@onModalClose="onCombineOrdersModalClose"
			@closeOrderOptionsModal="closeOrderOptionsModal"
	/>
	<users-modal
			:selected-order="selectedOrder"
			v-if="usersModalRef"
			ref="usersModalRef"
			:new-order-creation="true"
			@onSetPayer="setPayer"
	/>
	<split-order-modal
			v-if="splitOrderModalRef"
			:selectedOrder="selectedOrder"
			ref="splitOrderModalRef"
			@splitOrder="splitOrders"
			@onModalClose="splitOrderModalRef = false"
	/>
	<confirmation-modal-component ref="confirmation-modal-component" :has-cancel-btn="false"/>
	<barcode-scan-interceptor
			@submitBarcode="handleBarcode"
			:openedFromLogin="false"/>
</template>

<script>
import SearchBarComponent from "@/components/_shared/SearchBar";
import RefreshBtnComponent from "@/components/_shared/RefreshButton";
import ModalComponent from "@/components/_shared/Modal";
import Keypad from "@/components/_shared/Keypad";
import NewOrderModalComponent from "@/components/_modals/NewOrderModal";
import OrderOptionsModal from "@/components/_modals/OrderOptionsModal";
import UsersModal from "@/components/_modals/UsersModal";
import {authService} from "@/_api/auth.api";
import store from "@/store";
import SplitOrderModal from "@/components/_modals/SplitOrderModal";
import {settingsService} from "@/_api/settings.api";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal.vue";
import {orderService} from "@/_api/order.api";
import {formatDataService as formatItemCondiments, formatDataService} from "@/_helpers/format-data";
import {constants} from "@/constants/constants";
import BaseButton from "@/components/_shared/Button.vue";
import BarcodeScanInterceptor from "@/components/_shared/BarcodeScanInterceptor.vue";
import getPosHelper from "@/_helpers/get-pos-helper";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'OrdersListComponent',
	props: {
		/*orders: {
			type: Array
		},*/
		selectedOrder: {
			type: Object
		},
		modalOpened: {
			type: [Boolean, Number],
			required: false
		}
	},
	emits: ['refresh', 'updateOrder'],
	components: {
		BarcodeScanInterceptor,
		ConfirmationModalComponent,
		OrderOptionsModal,
		NewOrderModalComponent,
		Keypad,
		ModalComponent,
		RefreshBtnComponent,
		SearchBarComponent,
		UsersModal,
		SplitOrderModal,
		BaseButton,
		iconComponent
	},
	data() {
		return {
			orders: [],
			total: 0,
			page: 0,
			per_page: 9,
			searchOrder: null,
			loading: false,
			orderLoading: false,
			
			showNewOrderModal: false,
			showOrderOptionsModal: false,
			usersModalRef: false,
			combineOrder: false,
			highlighted: null,
			combineOrdersIn: null,
			splitOrderModalRef: false,
			// ADDITION_LEGAL_AGE_TABACCO: 0,
			// ADDITION_LEGAL_AGE_ALCOHOL: 0,
		}
	},
	
	computed: {
		hasCheckedOptions: function () {
			return this.orders.filter((o) => {return o.checked}).length
		},
		authData() {
			return this.$store.state.auth
		},
		authUser() {
			return this.$store.state.auth.user
		},
		legalAge() {
			return this.$store.state.settings.legalAge;
		},
		orderPayerModes() {
			const mods = this.$store.state.auth.posData.department.order_payer_addition_modes;
			if (mods.length === 2) {
				return 'both'
			}
			if (mods.length < 2 && mods[0].id === 2) {
				return 'card'
			} else {
				return 'manual'
			}
			// return this.$store.state.auth.posData.department.order_payer_addition_modes
		},
	},
	updated() {},
	watch: {},
	created() {},
	mounted() {
		this.getOrders({resetList: true, resetSelected: true});
		
		this.emmiter.on('updateOrderInList', (h) => {
			let index;
			if (h.data.id === constants.tempOrderID) {
				index = this.orders.findIndex((i) => i.tempId === h.data.tempId);
			} else {
				if (h.removedItem) { // method to find and replace temp order with created one
					index = this.orders.findIndex((i) => i.tempId === h.removedItem[0].tempId);
				} else {
					index = this.orders.findIndex((i) => i.id === h.data.id);
				}
			}
			if (index !== -1) {
				if (h.action === 'remove') {
					this.orders.splice(index, 1);
				} else if (h.action === 'replace') {
					this.orders[index] = {...h.data, active: true};
				} else {
					this.orders[index] = {...this.orders[index], ...h.data};
				}
				
			}
		});
		this.emmiter.on('singleOrderLoading', (data) => {
			this.orderLoading = data;
		});
	},
	beforeUnmount() {
		this.emmiter.off('updateOrderInList')
		this.emmiter.off('singleOrderLoading')
	},
	methods: {
		getOrders(reset, findAndSelect) {
			if (!this.loading) {
				if (reset.resetList) {
					this.page = 0;
					this.orders = [];
					this.loading = true;
				}
				if (reset.resetSelected) {
					this.$emit('updateOrder', null);
				}
				orderService.getOrders({
					search: this.searchOrder ? this.searchOrder : null,
					status: 1,
					page: !this.page ? this.page += 1 : this.page,
					per_page: this.per_page,
				}).then(res => {
					res.data.forEach(el => {
						this.orders.push(el);
					});
					if (res.data.length > 0) {
						this.page += 1;
					}
					
					if (reset.resetList) {
						let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
						if (tempOrders) {
							if (this.searchOrder !== null) {
								tempOrders.forEach((i) => {
									if (i.table.toString().includes(this.searchOrder) || (i.payers[0] && i.payers[0].cabin.includes(this.searchOrder))) {
										this.orders.unshift(i);
									}
								})
							} else {
								this.orders = tempOrders.concat(this.orders);
							}
							this.orders.forEach((o) => {
								o['active'] = false
							})
						}
					}
					if (this.selectedOrder) {
						this.orders.forEach((o) => {
							o['active'] = false;
							if (o.id !== null) {
								if (o.id === this.selectedOrder.id) {
									o['active'] = true;
									this.$emit('updateOrder', o);
								}
							} else {
								if (o.tempId === this.selectedOrder.tempId) {
									o['active'] = true;
									this.$emit('updateOrder', o);
								}
							}
						})
					}
					
					if (findAndSelect) {
						this.orders.forEach((o) => {
							o['active'] = false;
							if (o.id === findAndSelect) {
								o['active'] = true;
								// this.selectedOrder = o;
								this.$emit('updateOrder', o);
								//this.handleItemCondiments(this.selectedOrder);
								//this.handleItemModifiers(this.selectedOrder);
							}
						})
					}
					this.loading = false;
				})
			}
		},
		updateOrdersList(data) {
			this.orders.forEach((o) => {
				o['active'] = false;
			});
			this.orders.unshift(data);
			this.$emit('updateOrder', this.orders[0]);
			// this.selectedOrder = this.orders[0];
		},
		updateOrder(data) {
			this.orders.forEach((yy, index) => {
				if ((this.selectedOrder.id !== null && (yy.id === this.selectedOrder.id)) || (this.selectedOrder.id === null && (yy.tempId === this.selectedOrder.tempId))) {
					this.orders[index] = data;
				}
			});
		},
		handleBarcode(data) {
			if (this.orderPayerModes !== "manual") {
				if (this.modalOpened) {
					this.emmiter.emit('barcodeScannedHID', {barcode: data});
				} else {
					orderService.getUsers({
						search: data,
						order_by: 'cabin',
						sort: 'asc',
						page: 1,
						per_page: 10000000
					}).then((res) => {
						if (res.data.length) {
							const user = res.data[0];
							user['addition_mode'] = constants.payerAddedModes[1];
							if (user.posting_allowed) {
								let tableRequired = this.authData.posData.department.flow_ordering_table;
								if (tableRequired) {
									this.showNewOrderModal = true;
									this.$nextTick(() => {
										this.$refs['new-order-modal'].openModal(null, user);
									})
								} else {
									this.createTempOrder(null, null, user)
								}
								
							} else {
								this.$refs['confirmation-modal-component'].show({
									title: 'Error',
									message: 'This person has posting_allowed parameter set to false',
									okButton: 'OK',
								})
							}
						} else {
							this.$refs['confirmation-modal-component'].show({
								title: 'Error',
								message: `No users found with this barcode (${data})`,
								okButton: 'OK',
							})
						}
					})
				}
			}
			
		},
		createTempOrder(data) {
			let tempOrder = {
				payers: data.payers ? [data.payers] : [],
				id: constants.tempOrderID, // just an indicator for temp order so that SelectedOrder.vue could differentiate
				active: true,
				items: [],
				opened_by: {
					name: `${this.authUser.user.first_name !== null ? this.authUser.user.first_name : ''} ${this.authUser.user.last_name}`
				},
				seat: data.seat,
				status: 1,
				table: data.table ? parseInt(data.table) : null,
				subtotal: 0,
				tempId: window.crypto.getRandomValues(new Uint8Array(16)).join('')
			};
			if (tempOrder.payers.length) {
				tempOrder['payment_type'] = 1;
			};
			console.log('TTT',tempOrder);
			this.updateOrdersList(tempOrder);
			let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
			if (tempOrders) {
				tempOrders.unshift(tempOrder);
				localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
			} else {
				localStorage.setItem('tempOrders', JSON.stringify([tempOrder]))
			}
		},
		onInputClear() {
			this.debounceSearch({
				target: {
					value: ''
				}
			})
		},
		debounceSearch(e) {
			this.searchOrder = null;
			clearTimeout(this.debounce);
			this.debounce = setTimeout((event) => {
				this.searchOrder = e.target.value;
				this.getOrders({resetList: true, resetSelected: false});
			}, 500)
		},
		ordersListScrollHandle(e) {
			if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
				if (!this.searchOrder) {
					this.getOrders({resetList: false, resetSelected: false});
				}
			}
		},
		splittingOrder() {
			if (this.selectedOrder.items.length > 0) {
				this.splitOrderModalRef = true;
				this.$nextTick(() => {
					this.$refs['splitOrderModalRef'].openModal();
				})
			}
		},
		idCheck(i) {
			if (i.id !== constants.tempOrderID && (i.id === this.combineOrdersIn.id)) {
				return true
			} else if ((i.tempId && (i.tempId === this.combineOrdersIn.tempId))) {
				return true
			}
			return false
		},
		isHighlighted(i) {
			if (this.highlighted !== null) {
				if (this.highlighted.id !== constants.tempOrderID && (this.highlighted.id === i.id)) {
					return true
				} else if (this.highlighted.id === constants.tempOrderID && (this.highlighted.tempId === i.tempId)) {
					return true
				}
			} else {
				return false
			}
		},
		turnOffCombineOrder() {
			this.combineOrder = false;
		},
		onOrderSelect(i, index) {
			if (!this.orderLoading) {
				if (this.selectedOrder) {
					if (i.id === constants.tempOrderID) {
						if (this.selectedOrder.tempId === i.tempId) {
							return;
						}
					} else {
						if (this.selectedOrder.id === i.id) {
							return;
						}
					}
				}
				this.orders.forEach((o) => {
					o['active'] = false;
					if (o.id === constants.tempOrderID) {
						if (o.tempId === i.tempId) {
							o['active'] = true;
						}
					} else {
						if (o.id === i.id) {
							o['active'] = true;
						}
					}
				})
				this.$emit('updateOrder', {...i, active: true});
			}
		},
		openNewOrderModal() {
			if (this.$store.state.settings.businessDay.status !== 'rolled') {
				store.dispatch('alert/error', 'Business day is not in progress yet');
				return;
			};
			if (this.authData.status.loggedIn && this.authData.posId) {
				let guestRequired = this.authData.posData.department.flow_ordering_guest;
				let tableRequired = this.authData.posData.department.flow_ordering_table;
				
				if (guestRequired && tableRequired) {
					this.showNewOrderModal = true;
					this.$nextTick(() => {
						this.$refs['new-order-modal'].openModal();
					})
				} else if (guestRequired && !tableRequired) {
					this.usersModalRef = true;
					this.$nextTick(() => {
						this.$refs['usersModalRef'].openModal({
							modalTitleText: 'Set the payer',
							modalSubmitBtnText: 'OK'
						});
					})
				} else if (!guestRequired && tableRequired) {
					this.showNewOrderModal = true;
					this.$nextTick(() => {
						this.$refs['new-order-modal'].openModal();
					})
				} else if (!guestRequired && !tableRequired) {
					/*this.emmiter.emit('addTempOrder', {
						table: '',
						seat: null
					});*/
					this.createTempOrder({
						table: '',
						seat: null
					})
				}
			}
		},
		setPayer(data) {
			this.createTempOrder({
				table: '',
				seat: null,
				payers: [data]
			})
		},
		configureOrderStatus(data) {
			let orderedStatus = data.items.filter((i) => {return i.status === 1});
			let placesStatus = data.items.filter((i) => {return i.status === 2});
			if (!data.items.length || orderedStatus.length) {
				return 'redTable'
			} else if (!orderedStatus.length && placesStatus.length) {
				return 'yellowTable'
			} else if (!orderedStatus.length && !placesStatus.length) {
				return 'greenTable'
			}
		},
		openOrderOptionsModal(order) {
			this.highlighted = order;
			this.combineOrdersIn = order;
			this.showOrderOptionsModal = true;
			this.orders.forEach((i) => {
				i['idCheck'] = this.idCheck(i);
			})
			this.$nextTick(() => {
				this.$refs['order-options-modal'].openModal(order);
			})
		},
		closeOrderOptionsModal() {
			this.showOrderOptionsModal = false;
		},
		combineOrders() {
			if (this.hasCheckedOptions) {
				let arr = this.orders.filter((o) => {return o.checked});
				this.combineOrdersAPI({
					ordersToCombine: arr,
					ordersCombineDetails: this.combineOrdersIn
				})
			}
		},
		onCombineOrdersModalClose() {
			this.highlighted = null;
		},
		resetCombine() {
			this.combineOrder = false;
			this.orders.forEach((o) => {
				o['checked'] = false;
			})
		},
		handleAPIResponse(data, refreshList, closeModal, resetSelected) {
			if (closeModal) {
				this.emmiter.emit('closeModal');
			}
			if (data) {
				this.order = data;
				this.order.items = formatDataService.handleCondimentsModifiers(this.order.items);
				this.$emit('updateOrder', {...this.order, active: true})
			}
			if (refreshList) {
				this.getOrders({resetList: refreshList, resetSelected: resetSelected});
				/*this.emmiter.emit('refreshOrderList', {resetList: refreshList, resetSelected: resetSelected});*/
			}
		},
		openHasAlcoholModal(data) {
			this.$refs['confirmation-modal-component'].show({
				title: 'Attention',
				message: data,
				okButton: 'OK',
			})
		},
		combineOrdersAPI(data) {
			this.loading = true;
			// let deepCopy = JSON.parse(JSON.stringify(this.selectedOrder));
			let orderCombineInto = JSON.parse(JSON.stringify(data.ordersCombineDetails));
			orderCombineInto.items = formatDataService.handleCondimentsModifiers(orderCombineInto.items);
			let ordersToCombine = JSON.parse(JSON.stringify(data.ordersToCombine));
			let itemsToCombine = [];
			let hasAlcohol = false;
			const t0 = performance.now();
			ordersToCombine.forEach((o) => {
				o.items = formatDataService.handleCondimentsModifiers(o.items);
				if (!hasAlcohol) {
					hasAlcohol = o.items.some((o) => {return o.legal_age_class === 1})
				}
			});
			const t1 = performance.now();
			console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);
				if (hasAlcohol) {
					if (!orderCombineInto.payers.length) {
						this.openHasAlcoholModal('Items in order(s) have age restrictions. You need to add payer first.');
						return
					}
					if (orderCombineInto.payers[0].age <= this.legalAge.ADDITION_LEGAL_AGE_ALCOHOL) {
						this.openHasAlcoholModal('Items in order(s) have age restrictions. You need to add payer with respected age first.');
						return
					}
					if (orderCombineInto.payers[0].intoxication_state) {
						this.openHasAlcoholModal('Items in order(s) have age restrictions. You need to add payer without intoxication state set to true');
						return
					}
				}
				
				ordersToCombine.forEach((o) => {
					o.items.forEach((oo) => {
						oo['remove_id'] = true;
						itemsToCombine.push(oo)
					})
				});
				let tempArr = [];
				itemsToCombine.forEach((o) => {
					let index = orderCombineInto.items.findIndex((oo) => {
						return ((oo.orderable_type === "App\\Models\\MenuItem" && oo.orderable_id === o.orderable_id) || (oo.orderable_type === "App\\Models\\MenuCombo" && oo.orderable_id === o.orderable_id)) && oo.status === 1 && (oo.seat === o.seat)
					});
					let requiredCondiments = o.condiments_parsed.filter((i) => {return i.type === 1 ? i : false});
					if (index !== -1) {
						if (orderCombineInto.items[index].status === 1 && o.status === 1 && (!requiredCondiments.length && !o.modifiers_parsed.length)) {
							orderCombineInto.items[index].count += o.count;
						} else {
							tempArr.push(o);
						}
					} else {
						let sub_index = tempArr.findIndex((i) => {return i.orderable_id === o.orderable_id});
						if (sub_index !== -1) {
							if (tempArr[sub_index].seat === o.seat
								&& tempArr[sub_index].status === 1
								&& !tempArr[sub_index].modifiers_parsed.length
								&& !tempArr[sub_index].condiments_parsed.filter((i) => {return i.type === 1 ? i :
											false}).length) {
								tempArr[sub_index].count += o.count;
							} else {
								tempArr.push(o);
							}
						} else {
							tempArr.push(o);
						}
					}
				});
				orderCombineInto.items = [...orderCombineInto.items, ...tempArr];
				if (orderCombineInto.id === constants.tempOrderID) {
					orderService.createNewOrder(null, formatDataService.formatItemsForAPI(orderCombineInto.items, true),
							orderCombineInto).then((res) => {
						const newlyCreatedOrderId = res.data.id;
						if (res) {
							let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
							let index = tempOrders.findIndex((o) => {
								return o.tempId === orderCombineInto.tempId
							});
							if (index !== -1) {
								tempOrders.splice(index, 1);
								localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
							}
							
							const pr = new Promise((resolve, reject) => {
								ordersToCombine.forEach((o, index) => {
									orderService.cancelOrder(o).then((res) => {
										if (index === ordersToCombine.length - 1) resolve()
									})
								})
							})
							pr.then(() => {
								this.loading = false;
								this.getOrders({resetList: true, resetSelected: true}, newlyCreatedOrderId);
								this.turnOffCombineOrder();
							})
							
						} else {
							this.loading = false;
						}
					})
				} else {
					orderService.updateOrder(formatDataService.formatItemsForAPI(orderCombineInto.items, true), orderCombineInto).then(() => {
						let pr = new Promise((resolve, reject) => {
							ordersToCombine.forEach((o, index) => {
								if (o.id !== constants.tempOrderID) {
									orderService.cancelOrder(o).then((res) => {})
								} else {
									let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
									let index = tempOrders.findIndex((oi) => {
										return oi.tempId === o.tempId
									});
									if (index !== -1) {
										tempOrders.splice(index, 1);
										localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
									}
								}
								
								if (index === ordersToCombine.length - 1) {
									resolve();
								}
							})
						});
						pr.then(() => {
							this.loading = false;
							this.getOrders({resetList: true, resetSelected: true}, orderCombineInto.id);
							this.turnOffCombineOrder();
						});
					})
				}
		},
		splitOrders(data) {
			let deepCopy = JSON.parse(JSON.stringify(this.selectedOrder));
			data.selected.forEach((o) => {
				o.count = o.qty_to_split
			});
			deepCopy.items.forEach((o, index) => {
				data.selected.forEach(oo => {
					if (oo.id === o.id) {
						if (o.count - o.qty_to_split === 0) {
							o.to_delete = true;
						} else {
							o.count -= o.qty_to_split
						}
					}
				})
			});
			
			deepCopy.items = deepCopy.items.filter((o, index, a2) => {if (!o.to_delete) {return o} });
			let orderSplitTo = {
				table: !data.newTable ? (deepCopy.table ? deepCopy.table : null) : (data.newTable ?
						data.newTable.table : null),
				is_split: true,
				payers: data.newPayer ? [data.newPayer] : [],
				seat: !data.newTable ? (deepCopy.seat ? deepCopy.seat : null) : (data.newTable ? data.newTable.seat : null),
				items: data.selected
			};
			deepCopy.items = formatDataService.handleCondimentsModifiers(deepCopy.items);
			if (!deepCopy.items.length) {
				orderService.cancelOrder(deepCopy).then((res) => {
					if (res) {
						/*this.handleAPIResponse(null, false, false, false);*/
						this.createTempOrder(deepCopy.table, deepCopy.seat, deepCopy.payers[0])
					}
				});
			} else {
				orderService.updateOrder(formatDataService.formatItemsForAPI(deepCopy.items), deepCopy).then((res) => {
					if (res) {
						/*this.handleAPIResponse(res.data, false, false, false);*/
					}
				});
			}
			
			orderService.createNewOrder(orderSplitTo.id, formatDataService.formatItemsForAPI(orderSplitTo.items), orderSplitTo, deepCopy.id).then((res) => {
				if (res) {
					this.handleAPIResponse(null, true, true, true);
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
input[type="checkbox"] {
	display: none;
}

/* check icon styles */
label {
	color: #000;
	cursor: default;
	font-weight: normal;
}

/* checkbox styles */
label:before {
	content: " ";
	color: #000;
	display: inline-block;
	position: relative;
	text-align: center;
	text-indent: 0px;
	width: 24px;
	height: 24px;
	background: #FFF;
	border: 1px solid #e3e3e3;
	border-image: initial;
	vertical-align: middle;
}

input:checked + label:before {
	content: "\2714";
	background: #3F8EC5;
	color: white;
	border: 1px solid #3F8EC5;
	
	
}

input:disabled + label:before {
	background: #eee;
	color: #aaa;
}

#orders-list {
	.controls-wrapper {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		box-sizing: border-box;
		padding: 0 10px 0 5px;
		width: 100%;
		// height: 70px;
		.fn_btn {
			height: 60px;
			width: 174px;
			padding: 12px;
			
			span {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 21px;
			}
		}
		
		div {
			&:first-child {
				margin-right: 12px;
			}
		}
		
		// .btn-next {
		// 	box-sizing: border-box;
		// 	width: calc(100% - 72px);
		// }
	}
	:deep(#search-bar) {
		// margin-bottom: 10px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 5px;
	}
	.list {
		width: 100%;
		height: calc(100% - 119px);
		// height: 100%;
		overflow-y: scroll;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		box-sizing: border-box;
		padding: 0 5px;
		
		&::-webkit-scrollbar {
			display: none;
		}
		
		.order {
			padding: 12px;
			min-height: 94px;
			background-color: #ffffff;
			margin-bottom: 10px;
			border-radius: 6px;
			box-shadow: 0 1px 4px 0 #a7a7a7;
			cursor: pointer;
			transition: all 200ms;
			box-sizing: border-box;
			width: 100%;
			position: relative;
			overflow: hidden;
			
			&.highlighted {
				//z-index: 1000;
				z-index: 9999;
			}
			
			&.active-order {
				box-shadow: 0 1px 2px 1px #a7a7a7;
			}
			
			&:before {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
				content: '';
				width: 4px;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				transition: opacity 500ms;
			}
			
			.table {
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				.table-wrapper {
					display: flex;
				}
				
				.checkbox-wrap {
					img {
						width: 18px;
						height: 18px;
					}
				}
				
				.order-options {
					height: 4px;
					padding: 5px;
				}
				
				&-number {
					margin-left: 12px;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.025em;
					color: #000000;
					font-weight: 600;
				}
			}
			
			.text {
				font-weight: 600;
				font-size: 10px;
				line-height: 18px;
				text-transform: uppercase;
				color: #1c282d;
				text-align: left;
				margin: 3px 0;
				
				span {
					font-weight: normal;
					font-size: 12px;
					line-height: 16px;
					color: #000000;
				}
			}
		}
		
		.active-order {
			&:before {
				opacity: 1;
			}
		}
	}
}

@media (max-width: 1200px) {
	#orders-list {
		.controls-wrapper {
			.def-btn {
				padding: 0;
			}
			
			img {
				display: none;
			}
		}
	}
}

@media (max-width: 850px) {
	#orders-list {}
}
</style>
