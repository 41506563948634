import axios from "axios";

export const usersService = {
	toggleIntoxication,
	getSinglePosUser,
	getUserTransactions
};

function getUserTransactions(id) {
	return axios.get(`/user-profiles/${id}/transactions`)
	.then((res) => {
		return res
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function toggleIntoxication(data, user_id) {
	return axios.post(`/user-profiles/${user_id}/intoxication-log`, data)
	.then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}

function getSinglePosUser(data) {
	return axios.get(`/user-profiles/${data}`)
	.then((res) => {
		return res.data
	}).catch((err) => {
		return Promise.reject(err)
	})
}
