<template>
	<button 
		type="button" 
		class="base-button" 
		:class="[classes, isDisabled ? 'disabled' : '']" 
	  :style="customStyles" 
		@click="clickEvent()"
		v-ripple-effect
	>
		<icon-component v-if="imgOne" :iconName="imgSrc" />
		<icon-component v-if="imgLeft" :iconName="imgSrc" />
		<span>{{ text }}</span>
		<icon-component v-if="imgRight" :iconName="imgSrc" />
	</button>
</template>

<script>
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'BaseButton',
	emits: ['onClick'],
	props: {
		classes: {
			default: true,
			required: false,
			type: String
		},
		isDisabled: {
			default: false,
			required: false,
			type: Boolean
		},
		text: {
			default: '',
			required: false,
			type: String
		},
		imgSrc: {
			default: '',
			required: false,
			type: String
		},
		customStyles: {
			default: '',
			required: false,
			type: String
		}
	},
	components: {iconComponent},
	data() {
		return {}
	},
	computed: {
		imgLeft() {
			const regex = new RegExp('(icon) (left)', 'gi');
			return regex.test(this.classes)
		},
		imgRight() {
			const regex = new RegExp('(icon) (right)', 'gi');
			return regex.test(this.classes)
		},
		imgOne() {
			const regex = new RegExp('(icon) (one)', 'gi');
			return regex.test(this.classes)
		}
	},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		clickEvent() {
			if (!this.isDisabled) {
				this.$emit('onClick')
			}
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
.base-button {

}
</style>
