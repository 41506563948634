<template>
	<div id="modal-wrapper" v-if="selectedOrder">
		<modal-component ref="modal">
			<div id="item-deletion-notice-modal" class="modal-container">
				<div class="modal-header">
					Delete item
				</div>
				<h2 class="sub-title">
					you must add a commment because the item is processed already
				</h2>
				<div class="quantity-block">
					<div class="text quantity-text">Quantity:</div>
					<div class="changer">
						<button @click="changeCount('minus')">
							<icon-component iconName="minusBig"/>
						</button>
						<span>{{selectedItem.count_select}}</span>
						<button @click="changeCount('plus')">
							<icon-component iconName="plusBig"/>
						</button>
					</div>
				</div>
				<div class="content">
					<textarea class="input" v-model="noticeText" placeholder="Input your comment here..."></textarea>
				</div>
				<div class="modal-controls x2">
					<base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
					<base-button text="ok" :isDisabled="!noticeText.length" classes="def-btn main-action-btn uppercase medium border-2" @onClick="deleteItem"/>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {orderService} from "@/_api/order.api";
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'ItemDeletionNoticeModal',
	components: {ModalComponent, BaseButton, iconComponent},
	props: {
		selectedOrder: {
			type: Object,
			required: true
		},
		selectedItem: {
			type: Object,
			required: true
		}
	},
	emits: ['deleteItem'],
	data() {
		return {
			noticeText: ''
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close();
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.emit('toggleModals', {opened: false, type: 'ItemDeletionNoticeModal'});
	},
	methods: {
		openModal() {
			this.emmiter.emit('toggleModals', {opened: true, type: 'ItemDeletionNoticeModal'});
			this.$refs['modal'].show();
			this.selectedItem['count_select'] = this.selectedItem.count;
		},
		close() {
			this.emmiter.emit('toggleModals', {opened: false, type: 'ItemDeletionNoticeModal'});
			this.noticeText = '';
			this.$refs['modal'].close();
		},
		changeCount(type) {
			if (type === 'plus') {
				if ((this.selectedItem.count_select + 1) <= this.selectedItem.count) {
					this.selectedItem.count_select++
				}
			} else {
				if (this.selectedItem.count_select > 1) {
					this.selectedItem.count_select--
				}
			}
		},
		deleteItem() {
			if (this.noticeText.length) {
				/*this.emmiter.emit('deleteItem', {
					type: 'delete',
					text: this.noticeText,
					number: this.selectedItem.count_select,
					id: this.selectedItem.id,
					orderId: this.selectedOrder.id
				})*/
				this.$emit('deleteItem', {
					type: 'delete',
					text: this.noticeText,
					number: this.selectedItem.count_select,
					id: this.selectedItem.id,
					orderId: this.selectedOrder.id
				})

				this.close();
			}
		}
	}
}
</script>

<style lang="less">
#modal-wrapper {
	#item-deletion-notice-modal {
		width: 400px;
		.sub-title {
			font-weight: 600;
			color: #1C282D;
			font-size: 12px;
			margin-bottom: 30px;
		}
		.quantity-block {
			display: flex;
			justify-content: flex-start;
			align-content: center;
			flex-wrap: nowrap;
			margin-bottom: 20px;
			.text {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 10px;
			}
			button {
				border: none;
				background: white;
				cursor: pointer;
				&:last-of-type {
					padding-right: 0;
				}
			}
			.changer {
				display: flex;
				align-items: center;
				span {
					padding: 0 15px;
					background: #ECF4F9;
					border-radius: 5px;
					color: #1C282D;
				}
			}
		}
		.content {
			margin-bottom: 20px;
			.input {
				width: 100%;
				border: 1px solid gainsboro;
				resize: none;
				height: 120px;
				border-radius: 4px;
				font-size: 16px;
				color: #202020;
				box-sizing: border-box;
				padding: 10px;
				&:focus {
					outline: none;
				}
			}
		}
	}
}
</style>
