import axios from "axios";
import {constants} from "@/constants/constants";
export const orderService = {
    getSingleOrder,
    getOrders,
    createNewOrder,
    closeOrder,
    cancelOrder,
    updateOrder,
    getOccupiedTables,
    printInvoiceOrder,
    downloadInvoiceOrder,
    getUsers,
    getPosUsers,
    getCheckPrint,
    deleteItem,
    reopenOrder,
    resetOrder
    // deleteOrder
}

function getCheckPrint(data) {
    return axios.post(`/orders/${data.id}/print`, {}, {
        responseType: 'arraybuffer'
    })
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getOrders(data) {
    return axios.get(
        `/orders`, {
            params: data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getSingleOrder(id) {
    return axios.get(`/orders/${id}`).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getOccupiedTables(data) {
    return axios.get(
        `/orders/occupied-tables`, {
            params: data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function createNewOrder(id, items, selected_order, is_split) {
    let data = {
        table: selected_order.table,
        payers: [],
        payment_type: selected_order.payment_type,
        seat: selected_order.seat ? selected_order.seat : 1,
        items: items,
        splited_order_id: is_split,
        with_discount: selected_order.with_discount ? selected_order.with_discount : false
    }
    if (selected_order.payers.length) {
        selected_order.payers.forEach((o) => {
            data.payers.push({
                user_profile_id: o.id,
                addition_mode_id: o.addition_mode?.id || constants.payerAddedModes[0].id,
            })
        })
        /*data.payers.push({
            user_profile_id: selected_order.payers[0].id,
            addition_mode_id: o.addition_mode?.id || 1,
        });*/
    }
    if (selected_order.opened_by) {
        data.opened_by = selected_order.opened_by.id
        // data.created_at = selected_order.opened_by.id
    }
    return axios.post(
        `/orders`, data).then(res => {
            return res
        }).catch((err) => {
            return Promise.reject(err)
        })
}

// function deleteOrder(data) {
//     return axios.delete(
//         `orders/${data.id}`
//         ).then(res => {
//         return res
//     }).catch((err) => {
//         return Promise.reject(err)
//     })
// }

function cancelOrder(data) {
    return axios.post(
        `orders/${data.id}/cancel`,
        {
            reason: data.text
        }).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function closeOrder(data) {
    const orderData = {
        payment_type: data.payment_type,
    }
    if (data.payment_type === 2) {
        orderData['sys_acc_number'] = data.houseAccount;
    }
    return axios.post(
        `orders/${data.id}/close`, orderData).then(res => {
        return res;
    }).catch((err) => {
        return Promise.reject(err);
    })
}

function updateOrder(items, selected_order, change_table) {
    let data = {
        payers: [],
        items: items,
        seat: selected_order.seat ? selected_order.seat : 1,
        with_discount: selected_order.with_discount,
    }
    if (selected_order.payers.length) {
        selected_order.payers.forEach((o) => {
            data.payers.push({
                user_profile_id: o.id,
                addition_mode_id: o.addition_mode?.id || constants.payerAddedModes[0].id,
            })
        })
    }
    if (change_table) {
        data['table'] = selected_order.table;
    }
    if (selected_order.seat) {
        data['seat'] = selected_order.seat;
    }
    if (selected_order.opened_by !== null) {
        data['crew_id'] = selected_order.opened_by.id
    }
    return axios.put(`/orders/${selected_order.id}`, data).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err);
    })
}

function reopenOrder(data) {
    return axios.post(
        `orders/${data.id}/reopen`,
        {
            reason: data.text
        }).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function resetOrder(data) {
    const orderData = {
        reason: data.text,
        payment_type: data.payment_type,
    };
    if (data.payment_type === 2) {
        orderData['sys_acc_number'] = data.houseAccount;
    };
    return axios.post(
        `orders/${data.id}/reset`, orderData).then(res => {
        return res;
    }).catch((err) => {
        return Promise.reject(err);
    })
}

function printInvoiceOrder(id) {
    return axios.post(
        `orders/${id}/print`,
    ).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function downloadInvoiceOrder(id) {
    return axios.post(
        `orders/${id}/download`,
    ).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getUsers(data) {
    return axios.get(`/user-profiles`, {
        params: data
    })
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getPosUsers(data) {
    return axios.get(`/users`, {
        params: data
    })
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function deleteItem(data, id, orderId) {
    return axios.patch(`/orders/${orderId}/items/${id}/cancel`, data)
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}
