<template>
    <div id="modal-wrapper">
	    <modal-component ref="modal" @onModalClose="this.$emit('onModalClose')">
		    <div id="select-printer-modal" class="modal-container">
			    <div class="modal-header">
				    Select the printer
			    </div>

			    <div class="selected-printer">
				    <div class="printer" v-for="i in selectedPrinter" >
					    <div class="icon">
								<icon-component iconName="sidebarPrinters" />
							</div>
					    <div class="circle" :class="i.state == 'Off' ? 'red': 'green'"></div>
					    <div class="state state-on" v-if="i.state === 'On'">On</div>
					    <div class="state state-off" v-else>Off</div>
					    <div class="name">{{ i.name }}</div>
				    </div>
			    </div>

			    <div class="printers">
				    <div class="printer printer-header bold">
					    <div class="state">state</div>
					    <div class="name">name</div>
				    </div>
				    <div class="printer printer-block" v-for="i in printers" @click="selectPrinter(i)">
					    <div class="circle" :class="i.state == 'Off' ? 'red': 'green'"></div>
					    <div class="state state-on" v-if="i.state === 'On'">On</div>
					    <div class="state state-off" v-else>Off</div>
					    <div class="name">{{ i.name }}</div>
				    </div>
			    </div>
			
			    <div class="modal-controls x2">
						<base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
						<base-button text="NEXT" :isDisabled="!this.selectedPrinter.length > 0" classes="def-btn main-action-btn uppercase medium border-2" @onClick="next"/>
			    </div>
		    </div>
	    </modal-component>
    </div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import BaseButton from "@/components/_shared/Button.vue";
import IconComponent from "../_shared/Icon.vue";

export default {
    name: "SelectThePrinterModal",
    components:  { ModalComponent, BaseButton, IconComponent },
    emits: ['printFoodRunner', 'selectedPrinter'],
    props: {

    },
    data() {
        return {
            printers: [
                {
                    name:'Kitchen Second Floor HP DeskJet 3755 MX',
                    state: 'On'
                },
                {
                    name:'Kitchen Second Floor HP DeskJet 3755 MX',
                    state: 'Off'
                }
            ],
            selectedPrinter: []
        }
    },
    methods: {
        openModal() {
            this.$refs['modal'].show();
	        this.emmiter.emit('toggleModals', {opened: true, type: 'SelectThePrinterModal'});
        },
        close() {
            this.$refs['modal'].close();
	        this.emmiter.emit('toggleModals', {opened: false, type: 'SelectThePrinterModal'});
        },
        next() {
            if (this.selectedPrinter.length > 0) {
                this.$emit('printFoodRunner')
                this.$refs['modal'].close()
            }
        },
        selectPrinter(el) {
            this.selectedPrinter = [];
            this.selectedPrinter.push(el);
            this.$emit('selectedPrinter', el);
        }
    }
}
</script>

<style scoped lang="less">
#modal-wrapper {
	#select-printer-modal {
		.header {
			margin: 0 0 20px 0;
			&-text {
				font-weight: normal;
				font-size: 42px;
				line-height: 56px;
				color: #3F8EC5;
			}
		}
		.printers{
			margin-left: 30px;
			.bold{
				font-weight: bold;
				font-size: 12px;
				line-height: 148%;
				text-transform: uppercase;
			}
			.printer{
				width: 490px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				border-top:  1px solid rgba(53, 131, 197,.2);
				&:last-child{
					border-bottom: 1px solid rgba(53, 131, 197,.2);
				}
				height: 41px;
				
				&:active:not(:first-child){
					background: #8DC7E1;
				}
			}
			.printer-block{
				cursor: pointer;
				
			}
			
		}
		.selected-printer{
			margin: -25px 0 30px 30px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.printer{
				display: flex;
				align-items: center;
				margin-top: 40px;
			}
			
			
			.printer-name{
				.header{
					font-size: 16px;
					line-height: 148%;
					color: #000000;
				}
				.subheader{
					font-weight: normal;
					font-size: 12px;
					line-height: 148%;
					margin-top: -10px;
					text-align: left;
				}
			}
			
		}
		.state{
			margin-right: 15px;
		}
		.circle{
			height: 12px;
			width: 12px;
			border-radius: 100%;
			margin-right: 2px;
		}
		.red{
			background: #EB262B;;
			margin: 0 5px;
		}
		.green{
			background: #82B941;
			margin: 0 5px;
		}
	}
}

</style>
