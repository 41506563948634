<template>
	<div id="check-details" :class="{'loaded': orderLoading}" v-if="selectedOrder !== null">
		<div class="header_action">
			<button
					:class="'def-btn secondary-action-btn big border-2 medium icon left uppercase ' + design"
					@click="goBack()"
			>
				<icon-component iconName="backTo" />
			</button>
			<p>Check #{{ selectedOrder.id }}</p>
		</div>
		
		<div class="loader" v-if="orderLoading">
			<icon-component iconName="loader" />
		</div>
		
		<div v-if="!orderLoading" class="content">
			<div class="block block_1">
				<p class="title">Order:</p>
				<table class="table table_1">
					<thead>
					<tr>
						<th class="th_item">ITEM</th>
						<th class="th_price">PRICE</th>
						<th class="th_q">QTY</th>
						<th class="th_disc">DISCOUNT</th>
						<th class="th_tax">TAX</th>
						<th class="th_total">TOTAL</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(item, index) in selectedOrder.items">
						<td class="td_name">{{ item.name }}</td>
						<td>{{ item.price.toFixed(2) }}</td>
						<td>{{ item.count }}</td>
						<td v-if="selectedOrder.status === 1">
							<base-button
								@onClick="$emit('setItemDiscount', 'setDiscountGratuityModalRef', {title: 'Item discount',index: index})"
								:text="item.discount ? item.discount.toFixed(2) : '0.00'"
								classes="def-btn disc secondary-action-btn icon"
								customStyles="color: black"
							/>
						</td>
						<td v-else>{{ item.discount ? item.discount.toFixed(2) : '0.00' }}</td>
						<td></td>
						<td class="td_total">{{ item.total_amount_with_discount?.toFixed(2) }}</td>
					</tr>
					<tr class="totals">
						<td colspan="3" class="td_name">TOTAL:</td>
						<td>td</td>
						<td>tta</td>
						<td class="td_total">{{ selectedOrder.total?.toFixed(2) }}</td>
					</tr>
					</tbody>
				</table>
				
				<p class="title">Details:</p>
				<table class="table table_2">
					<thead>
					<tr>
						<th class="th_name"></th>
						<th class="th_vals"></th>
						<th class="th_actions"></th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td class="td_name">TABLE:</td>
						<td class="td_vals">{{ selectedOrder.table }}</td>
						<td class="td_actions">
							<base-button v-if="selectedOrder.status === 1" @onClick="$emit('changeTable')" text="CHANGE" classes="def-btn secondary-action-btn uppercase medium border-2" />
						</td>
					</tr>
					<tr>
						<td class="td_name">OPENED BY:</td>
						<td class="td_vals">{{ selectedOrder.opened_by.name }}</td>
						<td class="td_actions"></td>
					</tr>
					<tr>
						<td class="td_name">OPENING DATE:</td>
						<td class="td_vals">{{ selectedOrder.created_at }}</td>
						<td class="td_actions"></td>
					</tr>
					<tr v-if="selectedOrder.status !== 3">
						<td class="td_name">SERVING BY:</td>
						<td class="td_vals">{{ selectedOrder.opened_by.name }}</td>
						<td class="td_actions">
							<base-button v-if="selectedOrder.status === 1" @onClick="$emit('changeServing')" text="CHANGE" classes="def-btn secondary-action-btn uppercase medium border-2" />
						</td>
					</tr>
					<tr v-if="selectedOrder.status !== 1">
						<td class="td_name">CLOSED BY:</td>
						<td class="td_vals">{{ selectedOrder.closed_by?.name }}</td>
						<td class="td_actions"></td>
					</tr>
					<tr v-if="selectedOrder.status !== 1">
						<td class="td_name">POSTING DATE:</td>
						<td class="td_vals">{{ selectedOrder.closed_at }}</td>
						<td class="td_actions"></td>
					</tr>
					</tbody>
				</table>
			</div>
			
			<div class="block block_2">
				<p class="title">Check info:</p>
				<table class="table table_3">
					<thead>
					<tr>
						<th class="th_name"></th>
						<th class="th_vals"></th>
						<th class="th_actions"></th>
						<th class="th_actions_2"></th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td class="td_name">SUBTOTAL:</td>
						<td class="td_vals">{{ selectedOrder.subtotal.toFixed(2) }}</td>
						<td class="td_actions"></td>
						<td class="td_actions"></td>
					</tr>
					<tr>
						<td class="td_name">DISCOUNT:</td>
						<td class="td_vals">{{
								(selectedOrder.subtotal - selectedOrder.total).toFixed(2)
							}}
						</td>
						<td class="td_actions">
							<Switch
								v-if="selectedOrder.status === 1"
								:val="selectedOrder.with_discount"
								@toggled="(value) => $emit('toggleDiscount', value)"
							/>
						</td>
						<td class="td_actions">
							<base-button	@onClick="this.$emit('showDiscountDetails')" text="DETAILS" classes="def-btn secondary-action-btn uppercase medium border-2" />
						</td>
					</tr>
					<tr>
						<td class="td_name">TAX:</td>
						<td class="td_vals">t</td>
						<td class="td_actions"></td>
						<td class="td_actions"></td>
					</tr>
					<tr>
						<td class="td_name">GTATUITY:</td>
						<td class="td_vals">g</td>
						<td class="td_actions"></td>
						<td class="td_actions"></td>
					</tr>
					<tr class="totals">
						<td class="td_name">TOTAL:</td>
						<td class="td_vals">{{ selectedOrder.total?.toFixed(2) }}</td>
						<td class="td_actions"></td>
						<td class="td_actions"></td>
					</tr>
					</tbody>
				</table>
				
				<p v-if="selectedOrder.status === 1" class="title">Payment method:</p>
				<table v-if="selectedOrder.status === 1" class="table table_4">
					<thead>
					<tr>
						<th class="th_name"></th>
						<th class="th_actions"></th>
					</tr>
					</thead>
					<tbody>
					<tr
						:class="(selectedOrder.payment_type === 1) ? 'sel' : ''"
						@click="$emit('toggleCPT', 1)"
					>
						<td class="td_name">Cabin/Crew charge</td>
						<td class="td_actions">
							<icon-component v-if="selectedOrder.payment_type === 1" iconName="chosen" />
						</td>
					</tr>
					<tr
						:class="(selectedOrder.payment_type === 2) ? 'sel' : ''"
						@click="$emit('toggleCPT', 2)"
					>
						<td class="td_name">House account</td>
						<td class="td_actions">
							<icon-component v-if="selectedOrder.payment_type === 2" iconName="chosen" />
						</td>
					</tr>
					</tbody>
				</table>
				
				<div class="payers" v-if="selectedOrder.payment_type === 2">
					<div class="payers-header">
						House account number:
					</div>
					<input v-model.trim="houseAccountInput" class="input-houseAccount" @input="(e) =>
					$emit('houseAccountInputChange', e.target.value)">
				</div>
				
				<p class="title">Payers:</p>
				<table class="table table_5">
					<thead>
					<tr>
						<th class="th_name">NAME</th>
						<th class="th_param">CABIN</th>
						<th class="th_param">ID GET</th>
						<th class="th_param">TOTAL</th>
						<th :class="(selectedOrder.status !== 1) ? 'th_actions' : 'th_param'">PAYMENT</th>
						<th v-if="selectedOrder.status === 1" class="th_actions">DELETE</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(payer, index) in selectedOrder.payers">
						<td class="td_name">{{ getFullName(payer) }}</td>
						<td>{{ payer.cabin }}</td>
						<td>{{ payer.addition_mode?.name }}</td>
						<td class="td_total"></td>
						<td :class="(selectedOrder.status !== 1) ? 'td_actions' : ''">{{ getPaymentMethods }}</td>
						<td v-if="selectedOrder.status === 1" class="td_actions">
							<button
								class="def-btn del secondary-action-btn icon"
								@click="$emit('deletePayer', index)"
							>
								<icon-component iconName="delete" />
							</button>
						</td>
					</tr>
					</tbody>
				</table>

				<base-button
					v-if="selectedOrder.status === 1"
					@onClick="$emit('addPayer', 'payersModalRef', {modalTitleText: 'Add payer', modalSubmitBtnText: 'Add',})"
					imgSrc="plus"
					text="ADD PAYER"
					classes="def-btn secondary-action-btn uppercase medium border-2 icon left"
					:is-disabled="maxUserLimitExceeded"
				/>
				
				<p v-if="selectedOrder.status === 3" class="title">Cancellation:</p>
				<table v-if="selectedOrder.status === 3" class="table table_6">
					<thead>
					<tr>
						<th class="th_name"></th>
						<th class="th_vals"></th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td class="td_name">CANCELLED BY:</td>
						<td class="td_vals">{{ selectedOrder.opened_by?.name }}</td>
					</tr>
					<tr>
						<td class="td_name">DATE:</td>
						<td class="td_vals">{{ selectedOrder.cancelled_at }}</td>
					</tr>
					<tr>
						<td class="td_name">REASON:</td>
						<td class="td_vals">{{ selectedOrder.reason }}</td>
					</tr>
					<tr>
						<td class="td_name">COMMENT:</td>
						<td class="td_vals">{{ selectedOrder.comment }}</td>
					</tr>
					</tbody>
				</table>
			</div>
			
			<div class="block block_3">
				<p class="title">Actions:</p>
				<div class="btns">
					<base-button v-if="selectedOrder.status !== 3" @onClick="$emit('editDiscounts')" imgSrc="editDiscount" text="EDIT DISCOUNTS" classes="def-btn secondary-action-btn uppercase medium border-2 icon left" />
					<base-button v-if="selectedOrder.status === 1" @onClick="$emit('addGratuity')" imgSrc="plus" text="ADD GRATUITY" classes="def-btn secondary-action-btn uppercase medium border-2 icon left" />
					<base-button @onClick="$emit('printCheck')" imgSrc="printCheck" text="PRINT CHECK" classes="def-btn secondary-action-btn uppercase medium border-2 icon left" />
					<base-button v-if="selectedOrder.status !== 3" :isDisabled="userRole === 'waiter assist'" @onClick="$emit('cancelOrder')" imgSrc="cancelOrder" text="CANCEL ORDER" classes="def-btn cancel main-action-btn uppercase medium border-2 icon left" />
					<base-button v-if="selectedOrder.status === 1" :isDisabled="userRole === 'waiter assist'" @onClick="$emit('closeOrder')" imgSrc="closeOrderWh" text="CLOSE ORDER" classes="def-btn main-action-btn uppercase medium border-2 icon left" />
					<base-button v-if="selectedOrder.status === 2" :isDisabled="userRole === 'waiter assist'" @onClick="$emit('reopenOrder')" imgSrc="reopenOrder" text="REOPEN ORDER" classes="def-btn cancel-action-btn uppercase medium border-2 icon left" />
					<base-button v-if="selectedOrder.status === 2" :isDisabled="userRole === 'waiter assist'" @onClick="$emit('resetOrder')" imgSrc="resetOrder" text="adjust closed check" classes="def-btn cancel-action-btn uppercase medium border-2 icon left" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Switch from "@/components/_shared/Switch.vue";
import {constants} from "@/constants/constants";
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'CheckDetailsComponent',
	props: {
		selectedOrder: {
			require: false,
			type: Object,
		},
		section: {
			require: false,
			type: String,
		},
		orderLoading: {
			require: false,
			type: Boolean,
			default: false
		},
	},
	emits: ['backToPos', 'changeTable', 'changeServing', 'toggleDiscount', 'editDiscounts', 'addGratuity',
		'printCheck', 'cancelOrder', 'closeOrder', 'setItemDiscount', 'showDiscountDetails', 'deletePayer',
		'resetOrder', 'reopenOrder', 'addPayer', 'toggleCPT', 'houseAccountInputChange'],
	components: { Switch, BaseButton, iconComponent },
	data() {
		return {
			paymentMethods: constants.paymentMethods,
			houseAccountInput: '',
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
		getPaymentMethods() {
			let pm = this.paymentMethods.find(el => el.id === this.selectedOrder.payment_type);
			if (pm) {
				return pm.name;
			}
			return '';
		},
		maxUserLimitExceeded() {
			return this.selectedOrder.payers.length >= constants.maxPayers
		},
		userRole() {
			return this.$store.state.auth.user.user.role;
		}
	},
	created() {},
	mounted() {},
	beforeUnmount() {},
	beforeDestroy() {},
	methods: {
		goBack() {
			if (this.section === 'POS') {
				this.$emit('backToPos', false);
			} else {
				this.$router.replace({path: `/checks`});
			}
			/*if (this.selectedOrder && this.selectedOrder.id) {
				this.$emit('backToPos', false);
			} else {
				this.$router.replace({path: `/checks`})
			}*/
		},
		getFullName(payer) {
			let name = '';
			if (payer.first_name) name += payer.first_name;
			if (payer.middle_name) name += ` ${payer.middle_name}`;
			if (payer.last_name) name += ` ${payer.last_name}`;
			return name;
		},
	},
	watch: {}
}
</script>

<style lang="less" scoped>
#check-details {
	padding-right: 12px;
	height: calc(100% - 12px);
	margin-left: 0;
	transition-property: margin-left;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	
	.header_action {
		// margin-top: 12px;
		padding-top: 12px;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 18px;
		
		button {
			width: 60px;
			height: 60px;
			
			img {
				margin: 0;
			}
		}
		
		p {
			margin: 0;
			padding: 0;
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			letter-spacing: 0.15px;
			color: #1C282D;
		}
	}
	
	.content {
		margin-top: 12px;
		height: calc(100% - 80px);
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		gap: 12px;
		// overflow-y: hidden;
		.block {
			padding: 24px 12px;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #FFFFFF;
			box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.14);
			border-radius: 8px;
			overflow-y: auto;
			
			&::-webkit-scrollbar {
				display: none;
			}
			
			.title {
				margin: 0;
				padding: 0;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				text-align: center;
				color: #1C282D;
			}
			
			.table {
				width: 100%;
				table-layout: fixed;
				border-collapse: collapse;
				box-sizing: border-box;
				text-align: left;
				
				tr {
					th {
						padding: 2px;
						text-align: center;
					}
				}
				
				tbody {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					color: #1C282D;
					
					tr {
						td {
							padding: 2px;
						}
					}
				}
			}
			
			&_1 {
				flex: 2;
				
				.table_1 {
					margin: 12px 0 48px;
					
					thead {
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						color: #1C282D;
						
						tr {
							height: 36px;
							
							th {
								border: 1px solid #E5E0EB;
								// border-bottom: 1px solid #E5E0EB;
								// border-left: 1px solid #E5E0EB;
							}
							
							.th_item {
								width: 30%;
								border-left: none;
							}
							
							.th_price {
								width: 15%;
							}
							
							.th_q {
								width: 10%;
							}
							
							.th_disc {
								width: 15%;
							}
							
							.th_tax {
								width: 15%;
							}
							
							.th_total {
								border-right: none;
								width: 15%;
							}
						}
					}
					
					tbody {
						tr {
							height: 36px;
							
							td {
								border: 1px solid #E5E0EB;
								text-align: right;
								
								.disc {
									padding: 10px 0;
									margin: 0 auto;
									width: 100%;
									height: 36px;
									border-color: #D9E8F3;
									border-radius: 8px;
									
									span {
										color: #1C282D;
										
									}
								}
							}
							
							.td_name {
								border-left: none;
								text-align: left;
							}
							
							.td_total {
								border-right: none;
							}
						}
						
						.totals {
							font-style: normal;
							font-weight: 700;
							font-size: 14px;
							color: #1C282D;
						}
					}
				}
				
				.table_2 {
					margin: 12px 0 48px;
					
					thead {
						tr {
							.th_name {
								width: calc(50% - 82px);
							}
							
							.th_vals {
								width: 50%;
							}
							
							.th_actions {
								width: 82px;
								min-width: max-content;
							}
						}
					}
					
					tbody {
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						color: #1C282D;
						
						tr {
							td {
								height: 40px;
								border-top: 1px solid #E5E0EB;
								border-bottom: 1px solid #E5E0EB;
							}
							
							.td_name {
								border-left: none;
								font-style: normal;
								font-weight: 600;
								font-size: 12px;
								color: #1C282D;
							}
							
							.td_actions {
								align-items: end;
								
								.def-btn {
									width: 82px;
									height: 28px;
								}
							}
						}
						
						.totals {
							font-style: normal;
							font-weight: 700;
							font-size: 14px;
							color: #1C282D;
						}
					}
				}
			}
			
			&_2 {
				flex: 2;
				
				.table_3 {
					margin: 12px 0 36px;
					
					thead {
						tr {
							.th_name {
								width: 30%;
							}
							
							.th_vals {
								width: 10%;
							}
							
							.th_actions {
								width: 25%;
							}
							
							.th_actions_2 {
								width: 20%;
							}
						}
					}
					
					tbody {
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						color: #1C282D;
						
						tr {
							td {
								height: 40px;
								border-top: 1px solid #E5E0EB;
								border-bottom: 1px solid #E5E0EB;
							}
							
							.td_name {
								border-left: none;
								font-style: normal;
								font-weight: 600;
								font-size: 12px;
								color: #1C282D;
							}
							
							.td_vals {
								text-align: right;
							}
							
							.td_actions {
								text-align: right;
								
								.def-btn {
									width: 82px;
									height: 28px;
									margin: 0 auto;
								}
							}
						}
						
						.totals {
							font-style: normal;
							font-weight: 700;
							font-size: 14px;
							color: #1C282D;
							
							td {
								border-top: 2px solid #E5E0EB;
								border-bottom: none;
								
							}
							
							.td_name {
								font-weight: 700;
							}
						}
					}
				}
				
				.table_4 {
					margin: 12px 0 36px;
					
					thead {
						tr {
							.th_name {
								width: calc(100% - 30px);
							}
							
							.th_actions {
								width: 30px;
							}
						}
					}
					
					tbody {
						font-weight: 400;
						font-size: 16px;
						
						tr {
							cursor: pointer;
							
							td {
								height: 40px;
								// border-top: 1px solid #E5E0EB;
								border-bottom: 1px solid #E5E0EB;
							}
							
							.td_name {
								border-left: none;
							}
							
							.td_actions {
								align-items: end;
								
								.def-btn {
									width: 82px;
									height: 28px;
								}
							}
						}
						
						.sel {
							background: #F5F9FC;
						}
					}
				}
				
				.table_5 {
					margin: 12px 0 24px;
					
					thead {
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						color: #1C282D;
						
						tr {
							height: 36px;
							
							th {
								border: 1px solid #E5E0EB;
								// border-bottom: 1px solid #E5E0EB;
								// border-left: 1px solid #E5E0EB;
							}
							
							.th_name {
								width: 30%;
								border-left: none;
							}
							
							.th_param {
								width: 14%;
							}
							
							.th_actions {
								width: 14%;
								border-right: none;
							}
						}
					}
					
					tbody {
						tr {
							height: 36px;
							
							td {
								border: 1px solid #E5E0EB;
							}
							
							.td_name {
								border-left: none;
							}
							
							.td_total {
								text-align: right;
							}
							
							.td_actions {
								border-right: none;
								
								.del {
									margin: 0 auto;
									width: 54px;
									height: 36px;
									border-color: #D9E8F3;
									border-radius: 8px;
								}
							}
						}
					}
				}
				
				.table_6 {
					margin: 12px 0 12px;
					
					thead {
						tr {
							.th_name {
								width: 30%;
							}
							
							.th_vals {
								width: 70%;
							}
						}
					}
					
					tbody {
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						color: #1C282D;
						
						tr {
							td {
								height: 40px;
								border-top: 1px solid #E5E0EB;
								border-bottom: 1px solid #E5E0EB;
							}
							
							.td_name {
								border-left: none;
								font-style: normal;
								font-weight: 600;
								font-size: 12px;
								color: #1C282D;
							}
							
							.td_vals {
								align-items: end;
							}
						}
					}
				}
				
				.def-btn {
					width: 276px;
					height: 36px;
					padding: 12px;
					
					img {
						width: 18px;
						height: 18px;
					}
					
					span {
						font-weight: 600;
						font-size: 16px;
					}
				}
				
				.payers {
					margin-bottom: 40px;
					
					.payers-header {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 24px;
						text-align: center;
						color: #1C282D;
					}
					
					.input-houseAccount {
						padding: 0 10px;
						border: none;
						border-bottom: 2px solid #3F8EC5;
						font-size: 20px;
						max-width: 150px;
						width: 100%;
					}
				}
			}
			
			&_3 {
				flex: 1;
				min-width: 310px;
				overflow-y: hidden;
				overflow-x: hidden;
				
				.btns {
					margin-top: 12px;
					display: flex;
					flex-direction: column;
					gap: 24px;
					
					.def-btn {
						width: 276px;
						height: 36px;
						padding: 12px;
						
						img {
							width: 22px;
							height: 22px;
						}
						
						span {
							font-weight: 600;
							font-size: 16px;
						}
					}
				}
				
				.cancel {
					background: #EB262B;
					border-color: #EB262B;
				}
			}
		}
	}
	
	.switch {
		--toggle-width: 108px;
		--toggle-height: 28px;
		--toggle-border: 0.125rem;
		--toggle-font-size: 0.75rem;
		--toggle-duration: 150ms;
		--toggle-bg-on: #3F8EC5;
		--toggle-bg-off: #e5e7eb;
		--toggle-bg-on-disabled: #d1d5db;
		--toggle-bg-off-disabled: #e5e7eb;
		--toggle-border-on: #3F8EC5;
		--toggle-border-off: #e5e7eb;
		--toggle-border-on-disabled: #d1d5db;
		--toggle-border-off-disabled: #e5e7eb;
		--toggle-ring-width: 3px;
		--toggle-ring-color: transparent;
		--toggle-text-on: #ffffff;
		--toggle-text-off: #374151;
		--toggle-text-on-disabled: #9ca3af;
		--toggle-text-off-disabled: #9ca3af;
		--toggle-handle-enabled: #ffffff;
		--toggle-handle-disabled: #f3f4f6;
	}
}

#check-details.loaded {
	margin-left: -100px;
	transition-property: margin-left;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
}
</style>
