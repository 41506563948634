<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" :customBot="true" :has-keyboard="true" :show-keyboard="!showOnlyInfo"
		                 @clickOnKey="click" @onModalClose="onModalClose">
			<div id="users-modal" class="modal-container">
				<div class="modal-header">
					{{intoxicationModal ? intoxicationModalTitle : modalTitleText }}
				</div>
				<div class="selected-user" v-if="!selectedUser">
					<div class="img"><img :src="require('@/assets/img/bgs/nophoto.svg')" alt="user-image"></div>
					<div class="not-selected-user-text">Not specified yet</div>
				</div>
				<div class="selected-user" v-else>
					<div class="img"><img :src="selectedUser.photo ? 'data:image/png;base64,' + selectedUser.photo : require('@/assets/img/bgs/nophoto.svg')" alt="user-image"></div>
					<div class="info">
						<div class="info_param">
							<div class="label">NAME:</div>
							<div class="val">{{setWaiter ? selectedUser.name : `${selectedUser.first_name ? selectedUser.first_name : ''} ${selectedUser.last_name}`}}</div>
						</div>
						<div class="info_param">
							<div class="label">GENDER:</div>
							<div v-if="selectedUser.gender === 'M'" class="val">Male</div>
							<div v-if="selectedUser.gender === 'F'" class="val">Female</div>
						</div>
						<div :class="detailsMode ? 'info_param' : 'info_param info_param_last'">
							<div class="label">CABIN:</div>
							<div class="val">{{calcCabin}}</div>
						</div>
						<div v-if="detailsMode" class="info_param">
							<div class="label">POSTING STATUS:</div>
							<div v-if="selectedUser.posting_allowed === 1" class="val">Yes</div>
							<div v-if="selectedUser.posting_allowed === 2" class="val">No</div>
						</div>
						<div v-if="detailsMode" class="info_param intox">
							<div class="label">INTOX STATUS:</div>
							<div v-if="!selectedUser.intoxication_state" class="val">Off</div>
							<div v-if="selectedUser.intoxication_state" class="val">Intoxicated - {{ selectedUser.intoxication_log[selectedUser.intoxication_log.length - 1].date }}</div>
							<button v-if="!showOnlyInfo" @click="intoxic" class="def-btn cancel-action-btn uppercase medium border-1">{{intoxicationModalSubmitBtn}}</button>
						</div>
						<div v-if="detailsMode" class="info_param info_param_last">
							<div class="label">TIME OF LAST TRANSACTION:</div>
							<div class="val">transDate</div>
						</div>

						<div class="not-a-payer" v-if="!setWaiter && !selectedUser.posting_allowed">
							<icon-component iconName="intoxInfo" />
							Not a payer
						</div>

						<div class="age-restrictions" v-if="selectedUser.age < (legalAge.ADDITION_LEGAL_AGE_ALCOHOL || legalAge.ADDITION_LEGAL_AGE_TABACCO) && selectedUser.person_type !== 'A' && selectedUser.person_type !== 'G' ">
							<icon-component iconName="intoxInfo" />
							Age restrictions on the purchase of certain products
						</div>
					</div>
					<!-- && detailsList && detailsList.length -->
					<button v-if="!showOnlyInfo" @click="toggleUserIntoxicationDetails()" class="show-details-btn icon left">
						<icon-component :class="{'open': detailsMode}" iconName="backTo" />
						<span>more details</span>
					</button>
					<button v-if="!showOnlyInfo" @click="clearSelectedUser()" class="clear-btn">CLEAR</button>
				</div>

				<div v-if="!detailsMode" class="search_wrapper">
					<div class="search">
						<div class="search-header" :style="[orderPayerModes === 'both' || !intoxicationModal ?
						{'justify-content': 'center'} : null]">
							<div v-if="orderPayerModes !== 'card' || intoxicationModal" class="search-type">Search
								the passenger by name or cabin </div>
							<div v-if="showScannerButton && (orderPayerModes === 'both' || intoxicationModal)"
							     class="or"> -- or -- </div>
							<div v-if="showScannerButton && (orderPayerModes !== 'manual' || intoxicationModal)"
							     class="scan" @click="openBarcodeScannerModal">
								<icon-component iconName="barcode"/>
								<div class="scan-text"> Scan/tap Passenger’s card</div>
							</div>
						</div>
						<div v-if="orderPayerModes !== 'card' || intoxicationModal" class="search-input">
							<!--@input="filterPersons"-->
							<input v-model="searchInput" class="search-person" placeholder="Name, Cabin number, etc.">
							<icon-component v-if="searchInput.length >= 1" iconName="clear" class="clear-field" @click="clearSearchInput" />
							<label>
								<button @click="searchUsers">
									<icon-component iconName="search" />
									<span>Search</span>
								</button>
							</label>
						</div>
					</div>
				</div>

				<div v-if="!detailsMode" class="pas-list">
					<div class="nores" v-if="!usersList.length">{{orderPayerModes === 'card' && !intoxicationModal ?
							'User should be scanned' :
							'No search results'}}</div>
					<div class="users-wrapper" v-else>
						<!-- <div class="user users-list-header bold">
							<div class="cabin" @click="sortBy('cabin')">cabin</div>
							<div class="name" @click="sortBy('name')">name</div>
							<div class="date" @click="sortBy('date')" v-if="!setWaiter"></div>
						</div> -->
						<div class="scroll-wrapper" @scroll="infiniteScrollHandle">
							<table class="table table_u">
								<thead>
									<tr>
										<th class="th_n" @click="sortBy('name')">NAME</th>
										<th class="th_g">GENDER</th>
										<th class="th_c" @click="sortBy('cabin')">CABIN</th>
										<th class="th_ps">POSTING STATUS</th>
										<th class="th_is">INTOX STATUS</th>
										<th class="th_t">GUEST TYPE</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="i in usersList" @click="selectPerson(i)" :class="{'is-selected': selectedUser !== null && (i.id === selectedUser.id)}">
										<td class="td_">{{ `${i.first_name ? i.first_name : ''} ${i.last_name}` }}</td>
										<td class="td_">{{ i.gender }}</td>
										<td class="td_">{{ i.cabin }}</td>
										<td v-if="i.posting_allowed === 1" class="td_">Yes</td>
										<td v-if="i.posting_allowed === 2" class="td_">No</td>
										<td v-if="i.intoxication_state" class="td_">On</td>
										<td v-if="!i.intoxication_state" class="td_">Off</td>
										<td v-if="i.person_type === 'P'" class="td_">Passenger</td>
										<td v-if="i.person_type === 'R'" class="td_">Resident</td>
										<td v-if="i.person_type === 'C'" class="td_">Crew</td>
										<td v-if="i.person_type === 'S'" class="td_">Staff</td>
										<td v-if="i.person_type === 'V'" class="td_">Visitor</td>
										<td v-if="i.person_type === 'G'" class="td_">Group</td>
										<td v-if="i.person_type === 'A'" class="td_">Account</td>
									</tr>
								</tbody>
							</table>

							<!-- <div class="user user-block" v-for="i in usersList" @click="selectPerson(i)" :class="{'is-selected': selectedUser !== null && (i.id === selectedUser.id)}">
								<div class="cabin">{{ i.cabin || '-' }}</div>
								<div class="name">{{ setWaiter ? i.name : `${i.first_name ? i.first_name : ''} ${i.last_name}` }}</div>
								<div class="date" v-if="!setWaiter && i.intoxication_log.length > 0 && i.intoxication_state">{{isToday(parseISO('2022-06-29 09:16:17')) ? `Today, ${format(parseISO('2022-06-29 09:16:17'), 'hh:mm')}` : format(parseISO('2022-06-29 09:16:17'), 'MM-dd hh:mm')}}</div>
							</div> -->
						</div>
					</div>
				</div>

				<div v-if="detailsMode" class="details-list">
					<div class="switch-block">
						<button
							:class="(detailsSwitch === 1) ? 'def-btn main-action-btn big medium icon left uppercase' + design : 'def-btn secondary-action-btn big medium icon left uppercase' + design"
							@click="setDetailsSwitch(1)"
						>
							<span>Guest transactions list</span>
						</button>

						<button
							:class="(detailsSwitch === 2) ? 'def-btn main-action-btn big medium icon left uppercase' + design : 'def-btn secondary-action-btn big medium icon left uppercase' + design"
							@click="setDetailsSwitch(2)"
						>
							<span>Intoxication history</span>
						</button>
					</div>

					<div class="details-wrapper">
						<div class="scroll-wrapper" @scroll="infiniteScrollHandle">
							<!-- <div class="details-listing details-block" v-for="i in detailsList">
								<div class="date">{{ i.date }}</div>
								<div class="state">{{ i.state ? 'ON' : 'OFF' }}</div>
								<div class="department">{{ i.department }}</div>
								<div class="waiter">{{ i.causer }}</div>
							</div> -->
							<table v-if="detailsSwitch === 1" class="table table_1">
								<thead>
									<tr>
										<th class="th_id">CHECK ID</th>
										<th class="th_dep">DEPARTMENT</th>
										<th class="th_time">TIME</th>
										<th class="th_ig">ID GET</th>
										<th class="th_w">WAITER</th>
										<th class="th_subtotal">SUBTOTAL</th>
										<th class="th_disc">DISCOUNT</th>
										<th class="th_tax">TAX</th>
										<th class="th_g">GRATUITY</th>
										<th class="th_total">TOTAL</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="t in userTransactionsList">
										<td class="td_">{{ t.order_id }}</td>
										<td class="td_"></td>
										<td class="td_">{{ t.created_at }}</td>
										<td class="td_"></td>
										<td class="td_">{{ t.closed_by_name }}</td>
										<td class="td_">{{ t.subtotal }}</td>
										<td class="td_">{{ t.payer_discount_sum }}</td>
										<td class="td_">{{  }}</td>
										<td class="td_">{{  }}</td>
										<td class="td_">{{ t.total }}</td>
									</tr>
								</tbody>
							</table>
							
							<table v-if="detailsSwitch === 2" class="table table_2">
								<thead>
									<tr>
										<th class="th_time">TIME</th>
										<th class="th_state">STATE</th>
										<th class="th_dep">DEPARTMENT</th>
										<th class="th_op">OPERATOR</th>
										<th class="th_com">COMMENT</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="set in selectedUser.intoxication_log">
										<td class="td_">{{ set.date }}</td>
										<td v-if="!set.state" class="td_">Off</td>
										<td v-if="set.state" class="td_">On</td>
										<td class="td_">{{ set.department }}</td>
										<td class="td_">{{ set.causer }}</td>
										<td class="td_">{{ set.comment }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div v-if="!showOnlyInfo" class="modal-controls">
					<button @click="close" class="def-btn cancel-action-btn uppercase medium width100 border-2">CANCEL</button>
					<button :class="{'disabled': submitBtnDisabledCalc}" @click="submit" class="def-btn main-action-btn uppercase medium width100 border-2">CONFIRM</button>
				</div>
				<div v-if="showOnlyInfo" class="modal-controls">
					<button :class="{'disabled': submitBtnDisabledCalc}" @click="close" class="def-btn main-action-btn uppercase medium width100 border-2">OK</button>
				</div>
			</div>
		</modal-component>
		
		<comment-modal v-if="showCommentModal" ref="comment-modal" @submitComment="markUserIntoxication" @onModalClose="onCommentModalClose" :title="!selectedUser.intoxication_state ? 'Comment' : 'Unmark the intoxication'" :sub-title="!selectedUser.intoxication_state ? 'you can add a commment to the intoxication mark' : 'you can add a commment to the cancellation the intoxication mark'" :sub-title-info="'(not required)'" :submit-btn-text="!selectedUser.intoxication_state ? 'Proceed' : 'unmark'"/>
		
		<confirmation-modal-component ref="confirmation-modal" :has-cancel-btn="false"/>
		
		<intoxication-mark-info-modal-component ref="intoxication-mark-info-modal-component" v-if="detailsList && detailsList.length" :intoxication-mark-info="detailsList[0]"></intoxication-mark-info-modal-component>
		<!--v-if="showBarcodeScannerModal"-->
		<barcode-scanner-modal ref="barcode-scanner-modal" @submitBarcode="handleBarcode" @onModalClose="onBarcodeScannerModalHidden"></barcode-scanner-modal>
		
		<barcode-scan-interceptor
				v-if="innerBarcodeScanInterceptor"
				@submitBarcode="handleBarcode"
				:openedFromLogin="false"/>
		<!--<barcode-scan-interceptor @submitBarcode="handleBarcode" :openedFromLogin="false"></barcode-scan-interceptor>-->
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {orderService} from "@/_api/order.api";
import {usersService} from "@/_api/users.api";
import {settingsService} from "@/_api/settings.api";
import Keyboard from "@/components/_shared/Keyboard";
import BarcodeScannerModal from "@/components/_modals/BarcodeScannerModal";
import CommentModal from "@/components/_modals/CommentModal";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import IntoxicationMarkInfoModalComponent from "@/components/_modals/IntoxicationMarkInfoModal";
import {format, parseISO, isToday} from 'date-fns'
import BarcodeScanInterceptor from "@/components/_shared/BarcodeScanInterceptor";
import {authService} from "@/_api/auth.api";
import store from "@/store";
import {constants} from "@/constants/constants";
import getPosHelper from "@/_helpers/get-pos-helper";
import login from "@/views/auth/Login.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'UsersModal',
	components: {
		BarcodeScanInterceptor,
		IntoxicationMarkInfoModalComponent,
		ConfirmationModalComponent,
		CommentModal,
		Keyboard,
		ModalComponent,
		BarcodeScannerModal,
		iconComponent
	},
	props: {
		intoxicationModal: {
			type: Boolean,
			required: false,
			default: false
		},
		newOrderCreation: {
			type: Boolean,
			required: false,
			default: false
		},
		setWaiter: {
			type: Boolean,
			required: false,
			default: false
		},
		selectedOrder: {
			type: Object,
			default: null
		},
		innerBarcodeScanInterceptor: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			opened: false,
			detailsSwitch: 1,
			format,
			parseISO,
			isToday,
			modalTitleText: '',
			modalSubmitBtnText: '',
			usersList: [],
			selectedUser: null,
			page: 0,
			per_page: 50,
			sort: 'desc',
			order_by: 'name',
			search: '',
			searchInput:'',
			showBarcodeScannerModal: false,
			showCommentModal: false,
			detailsMode: false,
			detailsList: [],
			// ADDITION_LEGAL_AGE_TABACCO: 0,
			// ADDITION_LEGAL_AGE_ALCOHOL: 0,
			showScannerButton: false,
			showOnlyInfo: false,
			userTransactionsList: [],
			gettingTransactions: false
		}
	},
	emits: ['setPayer', 'addPayer', 'onModalClose'],
	computed: {
		authData() {
			return this.$store.state.auth
		},
		orderPayerModes() {
			const mods = this.$store.state.auth.posData.department.order_payer_addition_modes;
			if (mods.length === 2) {
				return 'both'
			}
			if (mods.length < 2 && mods[0].id === 2) {
				return 'card'
			} else {
				return 'manual'
			}
			// return this.$store.state.auth.posData.department.order_payer_addition_modes
		},
		design() {
			return this.$store.state.UI
		},
		calcCabin() {
			if (this.selectedUser.cabin) {
				return this.selectedUser.cabin
			} else {
				return '-'
			}
		},
		intoxicationModalTitle() {
			return this.detailsMode ? 'Intoxication history' : 'Mark intoxication'
		},
		intoxicationModalSubmitBtn() {
			return this.selectedUser ? (!this.selectedUser.intoxication_state ? 'mark' : 'unmark') : 'mark'
		},
		hasAgeRestrictedItems() {
			return this.selectedOrder.items.some((o) => {return o.legal_age_class})
		},
		submitBtnDisabledCalc() {
			if (!this.selectedUser) {
				return true
			}
			if (this.setWaiter) {
				return false
			}
			if (this.newOrderCreation && this.selectedUser.posting_allowed) {
				return false
			}
			if (!this.setWaiter && (!this.selectedUser.posting_allowed || (this.selectedUser.intoxication_state &&
					(this.selectedOrder.items && this.selectedOrder.items.length && this.selectedOrder.items.some((o) =>
					{return o.legal_age_class === 1}))))) {
				return true
			}
		},
		modalResponseType() {
			return this.modalTitleText === 'Set the payer' ? 'replace' : 'add'
		},
		legalAge() {
			return this.$store.state.settings.legalAge;
		},
	},
	watch: {
		orderPayerModes() {}
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		});
		this.emmiter.on('barcodeScannedHID', (data) => {
			this.handleBarcode(data.barcode);
		});
		// this.emmiter.emit('toggleModals', {opened: false});
		this.opened = false;
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.off('barcodeScannedHID');
		this.emmiter.emit('toggleModals', {opened: false, type: 'UsersModal'});
	},
	methods: {
		onModalClose() {
			// this.close();
			this.$emit('onModalClose');
		},
		openModal(data) {
			if (!this.opened) {
				this.getSettings();
				// this.getPos();
				this.getUsers(true, false);
				if (!this.intoxicationModal) {
					this.selectedUser = null;
					if (!this.newOrderCreation) {
						if (this.selectedOrder.payers.length) {
							this.selectPerson(this.selectedOrder.payers[0]);
						}
					}
				}
				if (data) {
					this.modalTitleText = data.modalTitleText;
					this.modalSubmitBtnText = data.modalSubmitBtnText;
				}
				if (data?.onlyInfo) {
					this.showOnlyInfo = true;
					this.detailsMode = true;
					this.selectedUser = data.user;
				}
				
				this.opened = true;
				this.$refs['modal'].show();
				this.emmiter.emit('toggleModals', {opened: true, type: 'UsersModal'});
			}
		},
		close() {
			if (this.$refs['modal']) {
				this.showCommentModal = false;
				this.$refs['modal'].close();
				this.usersList = [];
				this.selectedUser = null;
				this.page = 0;
				this.per_page = 50;
				this.sort = 'desc';
				this.order_by = 'name';
				this.search = '';
				this.searchInput = '';
				this.showBarcodeScannerModal = false;
				this.detailsMode = false;
				this.showScannerButton = false;
				this.emmiter.emit('toggleModals', {opened: false, type: 'UsersModal'});
				this.opened = false;
			}
		},
		setDetailsSwitch(switchNum) {
			this.detailsSwitch = switchNum;
		},
		getSettings() {
			if (navigator.mediaDevices === undefined) {
				this.showScannerButton = false;
			} else {
				navigator.mediaDevices.getUserMedia({video: true})
				.then((stream) => {
					this.showScannerButton = true;
					stream.getTracks().forEach( (track) => {
						track.stop();
					});
				}, (err) => {
					this.showScannerButton = false;
				})
			}
		},
		getUsers(reset, reset_user, user_scanned) {
			let method = this.setWaiter ? orderService.getPosUsers : orderService.getUsers;
			if (reset) {
				this.page = 0;
				this.usersList = [];
			}
			if (reset_user) {
				this.selectedUser = null;
			}
			method({
				search: this.search,
				order_by: this.order_by,
				sort: this.sort,
				page: !this.page ? this.page += 1 : this.page,
				per_page: this.per_page
			}).then((res) => {
				if (!user_scanned) {
					if (this.orderPayerModes !== 'card' || this.intoxicationModal) {
						res.data.forEach((obj) => {
							this.usersList.push(obj);
						});
					}
				} else {
					if (res.data.length) {
						this.usersList[0] = {...res.data[0]};
						this.selectPerson(this.usersList[0], user_scanned);
					}
				}
				if (res.data.length > 0) {
					this.page += 1;
				}
			})
		},
		getSelectedUserTransactions() {
			usersService.getUserTransactions(this.selectedUser.id).then((res) => {
				this.userTransactionsList = res.data.data;
			});
		},
		selectPerson(data, user_scanned) {
			if (!this.selectedUser || (data.id !== this.selectedUser.id)) {
				this.gettingTransactions = false;
				this.userTransactionsList = [];
				usersService.getSinglePosUser(data.id).then((res) => {
					this.selectedUser = {
						...res,
						addition_mode: user_scanned ? constants.payerAddedModes[1] : constants.payerAddedModes[0]
					};
					this.detailsList = res.intoxication_log;
					if (this.detailsList && this.detailsList.length) {
						this.detailsList.sort((a, b) => {return b.id - a.id})
					};
				});
			}
		},
		clearSelectedUser() {
			this.selectedUser = null;
		},
		searchUsers() {
			this.search = this.searchInput;
			this.getUsers(true, false);
		},
		clearSearchInput() {
			this.search = '';
			this.searchInput = '';
			this.getUsers(true, false);
		},
		sortBy() {
			this.getUsers(true, false);
		},
		click(e) {
			if (e === undefined) {
				this.searchInput = this.searchInput.substring(0, this.searchInput.length - 1);
				if (!this.searchInput.length) {
					this.search = '';
					this.getUsers(true, false);
				}
			} else {
				this.searchInput += e;
			}
		},
		infiniteScrollHandle(e) {
			if (!this.detailsMode) {
				if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
					this.getUsers(false, false);
				}
			}
		},
		openBarcodeScannerModal() {
			this.showBarcodeScannerModal = true;
			this.$nextTick(() => {
				this.$refs['barcode-scanner-modal'].openModal();
			})
		},
		onBarcodeScannerModalHidden() {
			this.showBarcodeScannerModal = true;
		},
		handleBarcode(data) {
			if (this.orderPayerModes !== "manual") {
				this.searchInput = data;
				this.search = data;
				this.getUsers(true, true, true);
			}
		},
		toggleUserIntoxicationDetails() {
			this.detailsMode = !this.detailsMode;
			if (this.detailsMode && !this.gettingTransactions) {
				this.gettingTransactions = true;
				this.getSelectedUserTransactions();
			};
		},
		openCommentModal() {
			if (this.selectedUser) {
				this.showCommentModal = true;
				this.$nextTick(() => {
					this.$refs['comment-modal'].openModal();
				})
			}
		},
		submit() {
			let df = () => {
				if (this.newOrderCreation) {
					// this.$refs['modal'].close();
				}
				this.$emit('addPayer', this.selectedUser, this.modalResponseType);
			}
			
			if (this.intoxicationModal) {
				this.openCommentModal();
			} else {
				// if ((!this.setWaiter && !this.selectedUser.posting_allowed) || (!this.setWaiter && this.selectedUser.intoxication_state)) {
				if (this.newOrderCreation && this.selectedUser.posting_allowed) {
					df();
					return
				}
				if (!this.setWaiter && (!this.selectedUser.posting_allowed || (this.selectedUser.intoxication_state && (this.selectedOrder.items.length && this.selectedOrder.items.some((o) => {return o.legal_age_class === 1}))))) {
				
				} else {
					df()
				}
			}
		},
		intoxic() {
			this.openCommentModal();
		},
		onCommentModalClose() {
			// this.showCommentModal = false;
		},
		markUserIntoxication(comment) {
			usersService.toggleIntoxication({
				state: !this.selectedUser.intoxication_state,
				comment: comment
			}, this.selectedUser.id).then((res) => {
				if (res) {
					this.selectPerson(res);
					// this.emmiter.emit('closeModal');
					if (this.selectedOrder.payers && this.selectedOrder.payers.length && this.selectedOrder.payers.filter((o) => {return o.id === res.id}).length) {
						const index = this.selectedOrder.payers.findIndex((o) => {
							if (o.id === res.id) {
								return o
							}
						})
						if (index !== -1) {
							const dc = JSON.parse(JSON.stringify(this.selectedOrder));
							dc.payers[index] = res;
							this.emmiter.emit('updateOrder', dc);
						}
					}
					
					if (this.intoxicationModal) {
						this.$refs['confirmation-modal'].show({
							message: `Intoxication has been ${res.intoxication_state ? 'marked' : 'unmarked'} successfully`,
							okButton: 'OK'
						});
						this.close();
					} else {
						this.selectedUser = res;
						this.$refs['confirmation-modal'].show({
							message: `Intoxication has been ${res.intoxication_state ? 'marked' : 'unmarked'} successfully`,
							okButton: 'OK'
						});
						this.$refs['comment-modal'].close();
					}
				}
			})
		},
		showIntoxicationMarkInfo() {
			this.$refs['intoxication-mark-info-modal-component'].openModal();
		}
	}
}
</script>

<style lang="less" scoped>
.keyboard {
	z-index: 99999;
	bottom: 0;
	margin: 0 auto;
	left: 0;
	right: 0;
}

#modal-wrapper {
	:deep(#modal) {
		.c-modal {
			align-items: flex-start;
		}
	}
}


#modal-wrapper {
	// margin-bottom: -30px;
	#users-modal {
		width: 950px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		.header {
			margin-bottom: 10px;
			width: 100%;
			&-title{
				font-size: 42px;
				font-weight: normal;
				line-height: 56px;
				// color: var(--theme_color);
				margin: 0;
			}
		}
		.modal-header {
			margin-bottom: 12px;
			font-style: normal;
			font-weight: 300;
			font-size: 42px;
			line-height: 56px;
			text-align: center;
			color: #3F8EC5;
		}
		.selected-user {
			// height: 160px;
			display: flex;
			align-items: flex-start;
			width: 100%;
			position: relative;
			// margin-bottom: 10px;

			.not-selected-user-text {
				margin-top: 50px;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 32px;
				color: #8DC7E1;
				margin-left: 18px;
			}
			.img {
				width: 108px;
				height: 144px;
				img {
					width: 108px;
					height: 144px;
				}
			}
			.info {
				// width: 240px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: self-start;
				margin-left: 18px;
				width: 100%;

				&_param {
					width: calc(100% - 144px);
					height: 40px;
					display: flex;
					flex-direction: row;
					align-items: center;
					border-top: 1px solid #E5E0EB;

					&_last {
						border-bottom: 1px solid #E5E0EB;
					}
				}
				.intox {
					position: relative;
					.def-btn {
						position: absolute;
						right: 0;
						top: 6px;
						width: 138px;
						height: 27px;
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						line-height: 16px;
						text-transform: uppercase;
						color: #EB262B;
					}

				}
				div {
					text-align: left;
					&.label {
						width: 132px;
						font-weight: 600;
						font-size: 12px;
						line-height: 18px;
						color: #1C282D;
					}
					&.val {
						margin-left: 30px;
						font-weight: normal;
						font-size: 14px;
						line-height: 18px;
						color: #1C282D;
						// text-transform: uppercase;
					}
				}
				.not-a-payer, .age-restrictions {
					color: red;
					font-size: 12px;
					margin-bottom: 0;
					font-weight: 600;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 14px;
						height: 14px;
						margin-right: 5px;
					}
				}
				.intoxication-indicator-wrapper {
					display: flex;
					justify-content: space-between;
					align-content: center;
					flex-wrap: nowrap;
					width: 100%;
					.intoxication-indicator {
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						img {
							width: 14px;
							height: 14px;
							margin-right: 5px;
						}
						.highlighted {
							color: red;
							margin-right: 10px;
						}
						font-size: 12px;
						margin-bottom: 0;
						font-weight: 600;
					}
					.unmark-intoxication {
						position: relative;
					}
				}
			}
			.clear-btn, .show-details-btn, .unmark-intoxication {
				width: 138px;
				height: 36px;
				background: #FFFFFF;
				box-sizing: border-box;
				border-radius: 8px;
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				text-transform: uppercase;
				position: absolute;
				right: 0;
				top: 0;
				cursor: pointer;
			}
			.show-details-btn {
				right: 0;
				top: 46px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 12px;

				img {
					width: 16px;
					height: 20px;
					transform: rotate(-90deg);
					transition-property: transform;
					transition-timing-function: ease-in-out;
					transition-duration: 200ms;
				}
				.open {
					transform: rotate(90deg);
					transition-property: transform;
					transition-timing-function: ease-in-out;
					transition-duration: 200ms;
				}
			}
		}

		.search_wrapper {
			width: 100%;
			border-top: 1px solid #E5E0EB;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			.search {
				margin-bottom: 12px;
				// padding-top: 12px;
				width: 506px;
				display: flex;
				flex-direction: column;
				align-items: center;
				&-header{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					min-height: 35px;
					.search-type {
						font-weight: 600;
						font-size: 12px;
						line-height: 16px;
						color: #1C282D;
					}
					.or {
						max-width: max-content;
						font-weight: 600;
						font-size: 12px;
						line-height: 16px;
						color: #777E81;
						padding: 0 10px;
						// margin: 0 34px;
					}
					.scan {
						display: flex;
						align-items: center;
						justify-content: space-between;
						// border: 2px solid;
						// border-radius: 8px;
						// padding: 5px 10px;
						cursor: pointer;
						&-text{
							margin-left: 12px;
							font-weight: 600;
							font-size: 12px;
							line-height: 16px;
							color: #1C282D;
						}
					}
				}
				&-input{
					display: flex;
					// margin-top: 12px;
					width: 492px;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: nowrap;
					position: relative;
					.search-person{
						border: 1px solid #3F8EC5;
						box-sizing: border-box;
						border-radius: 4px 0 0 4px;
						font-weight: normal;
						font-size: 16px;
						align-items: center;
						color: #777E81;
						width: 100%;
						height: 40px;
						padding-left: 13px;
					}
					.clear-field {
						position: absolute;
						right: 130px;
						cursor: pointer;
					}
					label{
						button{
							cursor: pointer;
							border: none;
							width: 120px;
							height: 40px;
							//background: var(--theme_color);
							border-radius: 0 8px 8px 0;
							font-style: normal;
							font-weight: 600;
							font-size: 16px;
							line-height: 21px;
							display: flex;
							align-items: center;
							text-align: center;
							text-transform: uppercase;
							color: #FFFFFF;
							img{
								width: 24px;
								height: 24px;
								color: white;
								margin:0 8px;
							}
						}
					}
				}
			}
		}
		.pas-list{
			height: 244px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 20px 0;
			width: 100%;
			.nores{
				font-weight: normal;
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				color: #8DC7E1;
			}
			.users-wrapper{
				width: 100%;
				// .users-list-header {
				// 	div {
				// 		cursor: pointer;
				// 	}
				// }
				.scroll-wrapper{
					overflow: scroll;
					border: none;
					height: 244px;

					&::-webkit-scrollbar {
						display: none;
					}

					table {
						width: 100%;
						table-layout: fixed;
						border-collapse: collapse;
						box-sizing: border-box;
						cursor: pointer;
						thead {
							font-style: normal;
							font-weight: 600;
							font-size: 12px;
							line-height: 15px;
							tr {
								th {
									height: 36px;
									border: 1px solid #E5E0EB;
									padding: 2px;
									text-align: left;
									&:first-child {
										border-left: none;
									}
									&:last-child {
										border-right: none;
									}
								}
								.th_n {
									width: 50%;
								}
								.th_g, .th_c {
									width: 7%;
								}
								.th_ps, .th_is, .th_t {
									width: 12%;
								}
							}
						}
						tbody {
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 18px;
							tr {
								td {
									height: 36px;
									border: 1px solid #E5E0EB;
									padding: 2px;
									text-align: left;
									&:first-child {
										border-left: none;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							.is-selected {
								background-color: #efefef;
							}
						}
					}
				}
				.bold{
					font-weight: bold;
					font-size: 12px;
					line-height: 148%;
					text-transform: uppercase;
				}
				.user{
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					border-top:  1px solid rgba(53, 131, 197,.2);
					min-height: 40px;
					cursor: pointer;
					transition: all 250ms;
					&.is-selected {
						background-color: #efefef;
					}
					&:last-child{
						border-bottom: 1px solid rgba(53, 131, 197,.2);
					}
					// &:active{
					// 	// background: #8DC7E1;
					// }
					.cabin {
						width: 20%;
						text-align: left;
						padding: 0 5px;
					}
					.date {
						width: 25%;
						text-align: right;
						padding: 0 10px;
					}
					.name {
						text-transform: uppercase;
						padding: 0 5px;
						width: 55%;
						text-align: left;
					}
				}
				
				.printer-block{
					cursor: pointer;
				}
			}
		}
		.details-list {
			// height: 290px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			// margin: 0 0 20px 0;
			width: 100%;

			.switch-block {
				margin-top: 12px;
				width: 540px;
				height: 44px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 24px;
				background: #F5F9FC;
				box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
				border-radius: 8px;
				margin-bottom: 12px;

				.def-btn {
					width: 234px;
					height: 36px;
					padding: 0;
						span {
							font-style: normal;
							font-weight: 600;
							font-size: 16px;
							line-height: 21px;
							text-transform: uppercase;
						}
				}
				.secondary-action-btn {
					background-color: transparent;
					border-color: transparent;
				}
			}
			.details-wrapper{
				width: 100%;
				.details-list-header {
					div {
						cursor: pointer;
						font-weight: bold;
						font-size: 12px;
						text-transform: uppercase;
					}
				}
				.scroll-wrapper{
					overflow: scroll;
					border: none;
					height: 180px;
					&::-webkit-scrollbar {
						display: none;
					}

					.table {
						width: 100%;
						table-layout: fixed;
						border-collapse: collapse;
						box-sizing: border-box;
						text-align: left;

						thead {
							font-style: normal;
							font-weight: 600;
							font-size: 12px;
							line-height: 15px;
							tr {
								th {
									height: 36px;
									border: 1px solid #E5E0EB;
									padding: 2px;
									text-align: center;
									&:first-child {
										border-left: none;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
						}

						tbody {
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							color: #1C282D;
							tr {
								td {
									height: 44px;
									border: 1px solid #E5E0EB;
									padding: 2px;
									&:first-child {
										border-left: none;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
						}

						&_1 {
							.th_ig {
								width: 5%;
							}
							.th_id, .th_dep, .th_time, .th_w {
								width: 15%;
							}
							.th_subtotal, .th_disc, .th_tax, .th_g, .th_total {
								width: 7%;
							}

						}
						&_2 {
							.th_time {
								width: 15%;
							}
							.th_state {
								width: 5%;
							}
							.th_dep {
								width: 20%;
							}
							.th_op {
								width: 20%;
							}
							.th_com {
								width: 40%;
							}
						}
					}
				}
			}
		}

		.modal-controls {
			margin-bottom: -18px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 12px;

			button {
				width: 180px;
				height: 48px;
			}
		}
	}
}

@media (max-width: 1200px) {
	#modal-wrapper {
		#users-modal {
			.search {
				&-input {
					label {
						button {
							font-size: 12px;
							img{
								width: 18px;
								height: 18px;
								color: white;
								margin:0 8px;
							}
						}
					}
				}
			}
		}
	}
}

</style>
