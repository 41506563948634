<template>
	<transition name="fade-scale">
		<div id="confirmation-modal" class="confirmation-modal c-modal-backdrop-wr" v-if="isVisible">
			<div class="c-modal">
				<div class="window">
					<icon-component iconName="close" class="close-btn" @click="close" />
					<div class="title" v-if="title">{{ title }}</div>
					<div class="message" :class="{'not-a-title': title}">{{ message }}</div>
					<div class="btns" :class="{'printers': isPrinters}">
						<base-button v-if="hasCancelBtn" :text="isPrinters ? 'cancel' : cancelButton" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="_cancel"/>
						<base-button :text="okButton" classes="def-btn main-action-btn uppercase medium border-2" @onClick="_confirm"/>
					</div>
				</div>
			</div>
			<div class="c-modal-backdrop"></div>
		</div>
	</transition>
</template>

<script>
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'ConfirmationModalComponent',
	emits: ['onConfirmationModalClose', 'onConfirmationModalConfirm'],
	props: {
		hasCancelBtn: {
			default: true,
			required: false
		},
		isPrinters: {
			default: false,
			required: false
		}
	},
	components: {BaseButton, iconComponent},
	data() {
		return {
			isVisible: false,
			title: undefined,
			message: undefined,
			okButton: undefined,
			cancelButton: 'Close',
			
			// Private variables
			resolvePromise: undefined,
			rejectPromise: undefined,
		}
	},
	computed: {},
	created() {},
	mounted() {},
	beforeUnmount() {
		this.emmiter.emit('toggleModals', {opened: false, type: 'ConfirmationModal'});
	},
	methods: {
		escListener(e) {
			if (e.code === 'Escape') {
				this.close();
			}
		},
		open() {
			this.isVisible = true;
			this.emmiter.emit('toggleModals', {opened: true, type: 'ConfirmationModal'});
			document.addEventListener('keydown', this.escListener);
		},
		close() {
			this.isVisible = false;
			document.removeEventListener('keydown', this.escListener);
			this.emmiter.emit('toggleModals', {opened: false, type: 'ConfirmationModal'});
			// this.emmiter.emit('closeModal');
			setTimeout(() => {
				this.$emit('onConfirmationModalClose');
			}, 300)
		},
		show(opts = {}) {
			this.title = opts.title;
			this.message = opts.message;
			this.okButton = opts.okButton;
			if (opts.cancelButton) {
				this.cancelButton = opts.cancelButton
			}
			// Once we set our config, we tell the popup modal to open
			this.open();
			// Return promise so the caller can get results
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			})
		},
		
		_confirm() {
			this.$emit('onConfirmationModalConfirm');
			this.close();
			this.resolvePromise(true);
		},
		
		_cancel() {
			this.close();
			this.resolvePromise(false);
		},
	},
	watch: {
		isVisible: function(res) {
			if (res) {
				document.body.classList.add("modal-open");
			} else {
				setTimeout(() => {
					document.body.classList.remove("modal-open");
				}, 300)
			}
		},
	}
}
</script>

<style lang="less" scoped>
/* css class for the transition */
.fade-scale-enter-active, .fade-scale-leave-active {
	transition: all 0.3s;
	.window {
		transition: all 0.3s;
	}
}
.fade-scale-enter-from,
.fade-scale-leave-to {
	opacity: 0;
	.window {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}

.c-modal-backdrop-wr {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	.c-modal {
		overflow-x: auto;
		display: flex;
		flex-direction: column;
		z-index: 1041;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		.window {
			background: #fff;
			border-radius: 5px;
			box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
			width: 500px;
			height: auto;
			min-height: 150px;
			margin-left: auto;
			margin-right: auto;
			padding: 1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			flex-wrap: wrap;
			position: relative;
			box-sizing: border-box;
			.close-btn {
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
			}
			.title {
				margin-bottom: 15px;
				color: #3F8EC5;
				font-size: 42px;
				text-align: center;
				font-weight: 400;
			}
			.message {
				margin-bottom: 30px;
				color: #3F8EC5;
				font-size: 42px;
				text-align: center;
				font-weight: 400;
				&.not-a-title {
					margin-bottom: 30px;
					color: #1C282D;
					font-size: 12px;
					text-align: center;
					font-weight: 600;
				}
			}
			.btns {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				justify-content: center;
				width: 100%;
				gap: 12px;
				button {
					width: 100%;
				}
			}

			.printers {
				.cancel-action-btn {
					border-color: #3F8EC5;
					color: #3F8EC5;
					
				}
				.main-action-btn {
					background-color: #EB262B;
					border-color: #EB262B;
				}
			}
		}
	}
	.c-modal-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1040;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0;
	}
}
</style>
