import {createRouter, createWebHistory} from 'vue-router'
import PosRootComponent from '@/views/pos/Pos';
import store from "@/store";

const routes = [
	{
		path: '/',
		name: 'Pos',
		alias: '/pos',
		component: PosRootComponent,
		meta: {
			requiresAuth: true,
			requiresPosId: true
		}
	},
	{
		path: '/auth/login',
		name: 'Login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login'),
		meta: {
			requiresAuth: false,
			requiresPosId: false
		}
	},
	{
		path: '/auth/terminal-setup',
		name: 'TerminalSetup',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/auth/TerminalSetup'),
		meta: {
			requiresAuth: true,
			requiresPosId: false
		}
	},
	{
		path: '/settings',
		name: 'Settings',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/settings/Settings'),
		meta: {
			requiresAuth: true,
			requiresPosId: true
		}
	},
	{
		path: '/checks',
		name: 'Checks',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/checks/Checks'),
		meta: {
			requiresAuth: true,
			requiresPosId: true
		}
	},
	{
		path: '/checks/:id',
		name: 'CheckDetails',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../components/pos/SelectedOrder.vue'),
		props: {
			section: 'CHECKS'
		},
		meta: {
			requiresAuth: true,
			requiresPosId: true
		}
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Dashboard'),
		meta: {
			requiresAuth: true,
			requiresPosId: true
		}
	},
	{
		path: '/reports',
		name: 'Reports',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/reports/Reports'),
		meta: {
			requiresAuth: true,
			requiresPosId: true
		}
	},
	{
		path: '/printers',
		name: 'Printers',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/printers/Printers'),
		meta: {
			requiresAuth: true,
			requiresPosId: true
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		redirect: '/'
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	let ud = localStorage.getItem('ud');
	let posId = localStorage.getItem('posId');
	
	if (to.matched.some(record => record.meta.requiresAuth) && ud === null) {
		next({path: '/auth/login', replace: true}); // IF USER IS NOT LOGGED AND TRIES TO ENTER PAGES WITH requiresAuth FLAG SO WE REDIRECT HIM TO /auth/login
	} else if (to.matched.some(record => !record.meta.requiresAuth && !record.meta.requiresPosId) && ud === null) {
		next(); // ONLY FIRES WHEN next({path: '/auth/login', replace: true}); IS TRIGGERED BECAUSE ONLY /auth/login HAS requiresAuth AND requiresPosId FLAGS TO 'FALSE'
	} else if (to.matched.some(record => !record.meta.requiresPosId) && ud !== null && posId !== null) {
		next({path: '/pos', replace: true}); // IF USER IS LOGGED AND POS ID IS SET AND USER TRIES TO REDIRECT TO LOGIN OR TERMINAL SETUP PAGES
	} else if (to.matched.some(record => record.meta.requiresAuth && !record.meta.requiresPosId) && ud !== null && posId === null) {
		next(); // IF USER IS LOGGED IN BUT POS ID IS EMPTY WE REDIRECT HIM WITH next({path: '/auth/terminal-setup', replace: true});
	} else if (to.matched.some(record => record.meta.requiresAuth) && ud !== null && posId !== null) {
		next(); // IF USER IS LOGGED IN AND HAS POS ID
	} else if (to.matched.some(record => record.meta.requiresAuth) && ud !== null && posId === null) {
		next({path: '/auth/terminal-setup', replace: true}); // IF USER IS LOGGED IN BUT POS ID IS EMPTY
	}
	
});
router.afterEach(to => {
	let cl = document.body.classList;
	cl.add(`design-${store.state.UI}`);
	if (to.href.includes('auth')) {
		cl.contains(`auth-ui`,) ? cl.replace(`auth-ui`, `unauth-ui`) : cl.add(`unauth-ui`)
	} else {
		cl.contains(`unauth-ui`) ? cl.replace(`unauth-ui`, `auth-ui`) : cl.add(`auth-ui`)
	}
})

router.beforeResolve((to, from) => {
	if (to.path === '/printers' && store.state.auth.user.user.role !== 'manager') {
		store.dispatch('alert/error', 'User does not have access privileges');
		return false;
	}
})

export default router
