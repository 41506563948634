<template>
	<!--v-click-outside="blur"-->
    <div class="custom-select" :tabindex="tabindex" >
        <div class="selected" :class="[open ? 'selected-active' : '', !options.length ? 'no-items' : '']" :style="border" @click="options.length ? open = !open : null">
            <input v-if="hasInput" class='dropdown-input' :value="terminalInput" @input="onInput" :placeholder="selected">
	        <div v-else class="selected-output">{{selected ? selected.name : placeholder}}</div>
            <icon-component v-if="showArray" iconName="dropdown" class='dropdown-icon' />
        </div>
        <div class="items" :class="[!open ? 'select-hide': '', borderColor ? 'customBorder' : '']" :style="{maxHeight: maxHeight+'px'}">
            <div
	            class="item"
                v-for="(option, i) of options"
                :key="i"
                @click="() => onItemClick(option)"
            >
	            <div class="text">
                    <p>{{ option.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: 'Dropdown',
    components: {iconComponent},
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        terminalInput: {
            type: String,
            required: false,
            default: '',
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
	    hasInput: {
			type: Boolean,
		    required: false,
		    default: true
	    },
	    showArray: {
			type: Boolean,
		    required: false,
		    default: true
	    },
	    borderColor: {
			type: String,
		    required: false,
		    default: 'lightgray'
	    },
	    hasPreselectedValue: {
			type: Boolean,
		    required: false,
		    default: true
	    },
	    placeholder: {
			type: String,
		    required: false,
		    default: 'Not specified yet'
	    },
	    maxHeight: {
			type: String,
		    required: false,
		    default: '100'
	    }
    },
    data() {
        return {
            selected: null,
            open: false,
        };
    },
    created() {
		if (this.hasPreselectedValue) {
			this.selected = this.default ? this.default : this.options.length > 0 ? this.options[0] : null
		}
    },
    watch: {
	    terminalInput: function (val) {
		    if (!val.length) {
				this.selected = this.default;
			}
	    },
	    options: function (val) {
			// this.open = false;
		    this.selected = this.default ? this.default : this.options.length > 0 ? this.options[0] : null
	    }
    },
    methods: {
        onInput(e) {
            this.selected = null;
            this.open = true;
            this.$emit('searchTerminal', e.target.value);
        },
	    openDD() {
			this.open = true;
	    },
        blur() {
            this.open = false;
        },
        onItemClick(e) {
            this.$emit('onItemClick', e.name);
            this.selected = e;
            this.open = false;
            this.$emit('onInput', e);
        },
	    refresh() {
		    this.selected = this.default ? this.default : this.options.length > 0 ? this.options[0] : null
	    }
    },
    computed: {
        border() {
            if (this.borderColor) {
                return {
                    'border-radius': this.open ? '4px 4px 0 0' : '4px'
                }
            }
            return {
                'border-radius': this.open ? '4px 4px 0 0' : '4px'
            }
        }
    },
    mounted() {
    },
};
</script>

<style scoped lang="less">
.custom-select {
    z-index: 1;
    position: relative;
    margin: 0 auto;
    max-width: 322px;
    max-height: 32px;
    text-align: left;
    outline: none;
    line-height: 32px;
    .selected {
        height: 36px;
        z-index: 9999;
        background: #FFFFFF;
        border: 1px solid lightgray;
        box-sizing: border-box;
        border-radius: 4px;
        color: #777E81;
        padding-left: 11px;
        cursor: pointer;
        user-select: none;
        font-size: 18px;
	    position: relative;

        &:active {
            border-bottom: none;
            
        }
	    &.selected-active {
            border-color: #3F8EC5;
            border-bottom-color: #E5E0EB;
		    .dropdown-icon {
				transform: rotate(180deg);
		    }
	    }
        .dropdown-input {
            height: 32px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #1C282D;
        }
	    .dropdown-icon {
		    position: absolute;
		    right: 7px;
		    width: 7px;
		    height: 7px;
		    top: 50%;
		    margin-top: -3.5px;
	    }
        /*&:after {
            position: absolute;
            content: "▾";
            color: black;
	        // right: 8px;
	        right: 1em;
	        width: 6px;
	        height: 6px;
	        top: 0;
	        font-size: 16px;
	        // top: 50%;
	        // margin-top: -16px;
        }
        &.selected-active:after {
            content: "▴";
            // max-height: 32px;
        }*/
	    .selected-output {
		    color: #1C282D;
	    }
    }

    .items {
        color: #777E81;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        border: 1px solid lightgray;
        border-top: none;
        box-sizing: border-box;
        position: absolute;
        background: #FFFFFF;
        left: 0;
        right: 0;
        z-index: 1;
	    overflow-y: scroll;
        div {
            cursor: pointer;
            user-select: none;
        }
    }

    .item {
        height: 48px;
        border-bottom: 1px solid #E5E0EB;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #1C282D;

        &:active, &:hover {
            background: #3F8EC5;
            color: #FFFFFF;
        }

        .text {
            padding-left: 11px;
            height: 100%;
            display: flex;
            align-items: center;
            p {
                margin: 0;
                padding: 0;
            }
        }
    }

    .select-hide {
        display: none;
    }

    input, textarea {
        outline: none;
        border: none;
        width: 300px;
        height: 47px;
    }

    .customBorder {
        border-color: #3F8EC5;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
    }
    // ::-webkit-scrollbar-thumb {
    //     border-radius: 5px;
    //     background-color: rgba(0,0,0,.3);
    //     -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    // }
}
</style>
