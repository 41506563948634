import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import httpInterceptor from "@/_helpers/http-interceptor";
// import {BarcodeScanInterceptor} from "@/_helpers/barcode-scan-interceptor";
httpInterceptor();
import clickOutside from "@/_directives/click-outside";
import rippleEffect from "@/_directives/ripple-effect";
import mitt from "mitt";
import currencyFilter from "@/filters/currency.filter";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

const app = createApp(App).use(store).use(router).use(VueAwesomePaginate);
app.directive('click-outside', clickOutside);
app.config.globalProperties.$filters = {
    cur:currencyFilter
}
app.directive('ripple-effect', rippleEffect);
window.emmiter = mitt();
app.config.globalProperties.emmiter = mitt();
app.config.globalProperties.config = window.___env;

// BarcodeScanInterceptor(null, app.config.globalProperties.emmiter);
app.mount('#blast');
