<template>
	<div class="marg-logo" v-if="design === 'margaritaville'">
		<icon-component iconName="logoRainbow" class="logo" />
	</div>
	<div class="pos-t">
		<div :id="'root-wr'">
			<div v-if="design === 'ecruise'" class="img-wrapper">
				<icon-component iconName="mainLogo" class="logo" />
			</div>
			<div class="flex-row">
				<div class="auth-type crew-id">
					<div class="auth-form-wrapper">
						<h2 class="title">Authorize</h2>
						<!--<div class="sub-title">with your Crew ID</div>-->
						<form id="1111">
							<label for="authorize" class="label">Barcode/Crew ID<span v-if="design === 'ecruise'">/PIN code</span></label>
							<input id="authorize" v-model="dataField" @keypress="isNumber($event)" :placeholder="(inFocus === 'barcode') ? 'Barcode' : (inFocus === 'crewid') ? 'Crew ID' : 'PIN code'"
								class="authorize-input" :class="{'active': inFocus === 'crewId'}">
							<div class="type-radio">
								<div class="radio-wrapper" @click="inFocus = 'barcode'">
									<icon-component :iconName="inFocus === 'barcode' ? 'radioOn' : 'radioOff'" />
									<span>Barcode</span>
								</div>
								<div class="radio-wrapper" @click="inFocus = 'crewid'">
									<icon-component :iconName="inFocus === 'crewid' ? 'radioOn' : 'radioOff'" />
									<span>Crew ID</span>
								</div>
								<div class="radio-wrapper" @click="inFocus = 'pincode'" v-if="design === 'ecruise'">
									<icon-component :iconName="inFocus === 'pincode' ? 'radioOn' : 'radioOff'" />
									<span>PIN code</span>
								</div>
							</div>
							<base-button text="AUTHORIZE" :isDisabled="!dataField.length" classes="def-btn main-action-btn uppercase medium border-2" @onClick="login"/>
						</form>
					</div>
					<div v-if="showScannerButton" class="scan-wrapper">
						<div class="barcode" @click="openBarcodeScannerModal">
							<icon-component iconName="barcode" class="barcode_img" />
							<p>Scan crew card with camera</p>
						</div>
					</div>
				</div>
			</div>
		</div >
		<Keypad
			class="keypad"
			:dot="false"
			@clickOnKey="clickOnKey"
			@clear="clear"
		/>
	</div>
	<!--Employee ID-->
	<!--<div class="marg-t" v-if="design==='margaritaville'">
		<div id="marg-login-root" class="marg-login-root">
			<div class="flex-row">
				<div class="auth-type">
					<div class="auth-form-wrapper">
						<h2 class="title">Authorize</h2>
						<div class="sub-title">with your Employee ID</div>
						<form>
							<label for="auth" class="label">Employee ID</label>
							<input id="auth" @click="inFocus = 'crewId';" v-model="crewId" placeholder="Employee ID" class="authorize-input">
							<button class="def-btn main-action-btn uppercase border-2" @click.prevent="login('crewId')">AUTHORIZE</button>
						</form>
					</div>
					<div class="divider"></div>
					<div class="scan-wrapper">
						<div class="barcode" @click="openBarcodeScannerModal">
							<img v-if="design === 'ecruise'" class="barcode_img" src="../../assets/img/barcode.svg" alt="barcode">
							<img v-if="design === 'margaritaville'" class="barcode_img" src="../../assets/img/barcode.svg" alt="barcode">
							<p>Scan crew card for login</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Keypad
				class="keypad"
				@clickOnKey="clickOnKey"
				@clear="clear"
		/>
	</div>-->
	<barcode-scanner-modal v-if="showBarcodeScannerModal" ref="barcode-scanner-modal" @submitBarcode="handleBarcode" @onModalClose="showBarcodeScannerModal = false" :openedFromLogin="true"></barcode-scanner-modal>
	<barcode-scan-interceptor @submitBarcode="handleBarcode" :openedFromLogin="true"></barcode-scan-interceptor>
</template>

<script>
import Keypad from "@/components/_shared/Keypad";
import {authService} from "@/_api/auth.api";
import {printersService} from "@/_api/printers.api";
import store from "@/store";
import BarcodeScannerModal from "@/components/_modals/BarcodeScannerModal";
import BarcodeScanInterceptor from "@/components/_shared/BarcodeScanInterceptor";
import BaseButton from "@/components/_shared/Button.vue";
import {settingsService} from "@/_api/settings.api";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
    name: 'LoginRootComponent',
    props: {},
    components: {Keypad, BarcodeScannerModal, BarcodeScanInterceptor, BaseButton, iconComponent},
    data() {
        return {
            crewId: '',
            pinCode: '',
            barcode: '',
            inFocus: 'barcode',
	        dataField: '',
	        showBarcodeScannerModal: false,
	        showScannerButton: false
        }
    },
    computed: {
        posId(){
            return this.$store.state.auth.posId
        },
        design(){
            return this.$store.state.UI
        }
    },
    created() {},
    mounted() {
	    /*this.emmiter.on('loginViaBarcode', (data) => {
		    // this.barcode = data;
		    this.dataField = data;
			this.login();
	    });*/
	    if (navigator.mediaDevices === undefined) {
		    this.showScannerButton = false;
	    } else {
		    navigator.mediaDevices.getUserMedia({video: true})
		    .then((stream) => {
			    this.showScannerButton = true;
			    stream.getTracks().forEach( (track) => {
				    track.stop();
			    });
		    }, (err) => {
			    this.showScannerButton = false;
		    })
	    }
    },
    beforeUnmount() {
    },
    methods: {
			
			isNumber: function(evt) {
				evt = (evt) ? evt : window.event;
				var charCode = (evt.which) ? evt.which : evt.keyCode;
				if (charCode < 48 || charCode > 57) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			login() {
				let obj = {}
				if (this.inFocus === 'crewid') {
					obj = {
						crewId: this.dataField,
						posId: this.posId
					}
				} else if (this.inFocus === 'pincode') {
									obj = {
											pinCode: this.dataField,
						posId: this.posId
					}
				} else if (this.inFocus === 'barcode') {
									obj = {
						posId: this.posId,
						barcode: this.dataField
					}
				}
				if (this.dataField.length) {
					authService.login(obj).then(res => {
						if (res) {
							store.dispatch('loginUser', res.data)
							this.$router.push('/pos');
						}
					})
				}
			},
			clickOnKey(e) {
				this.dataField += e;
			/*if (this.design === 'margaritaville') {
				//this.inFocus = 'crewId';
				this.crewId += e;
			} else {
				if (this.inFocus === 'crewId') {
					this.crewId += e
				} else if (this.inFocus === 'pinCode') {
					this.pinCode += e
				}
			}*/
			},
			clear() {
				this.dataField = this.dataField.slice(0, -1)
					/*if (this.inFocus === 'crewId') {
							this.crewId = this.crewId.slice(0, -1)
					} else if (this.inFocus === 'pinCode') {
							this.pinCode = this.pinCode.slice(0, -1)

					}*/
			},
	    openBarcodeScannerModal() {
				this.showBarcodeScannerModal = true;
				this.$nextTick(() => {
					this.$refs['barcode-scanner-modal'].openModal();
				})
	    },
	    handleBarcode(data) {
				this.inFocus = 'barcode';
				this.dataField = data.toString();
				// this.barcode = data.toString();
					this.showBarcodeScannerModal = false;
				this.login();
	    }
    },
    watch: {}
}
</script>

<style lang="less" scoped>
.marg-logo {
	margin-top: 50px;
	display: flex;
	justify-content: center;
}

.pos-t {
	position: relative;
	top: 100px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: nowrap;
	#pos-login-root {
		margin: 0;
	}
	.keypad {
		margin: 0;
		z-index: 1;
	}
}
/*.marg-t {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: nowrap;
	#marg-login-root {
		margin: 0;
	}
	.keypad {
		!*width: 400px;
		bottom: 66px;*!
		margin: 0;
		z-index: 1;
	}
}*/

#root-wr {
	position: relative;
	background: #FFFFFF;
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	width: 450px;
	// height: 516px;
	// margin: 70px auto 0 auto;
	padding: 24px;
	.img-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.logo {
			margin: 0 auto;
			width: 120px;
			height: 108px;
		}
	}
	.auth-type {
		flex: 1 1 50%;
		position: relative;
		&:first-child {
			padding-right: 24px;
		}
		&:last-child {
			// border-left: 1px rgba(0, 0, 0, 0.1) solid;
			padding-left: 24px;
		}
		.scan-wrapper {
			border-top: 1px rgba(0, 0, 0, 0.1) solid;
			padding-top: 15px;
			.barcode {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px solid;
				border-radius: 8px;
				padding: 5px 10px;
				cursor: pointer;
				p {
					margin: 0;
					margin-left: 15px;
				}
			}
		}
		.auth-form-wrapper {
			margin-bottom: 20px;
			.type-radio {
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: nowrap;
				margin-bottom: 30px;
				width: 100%;
				.radio-wrapper {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: nowrap;
					img {
						margin-right: 5px;
					}
				}
			}
			.title {
				font-style: normal;
				font-weight: normal;
				font-size: 42px;
				line-height: 56px;
				color: #3F8EC5;
				text-align: left;
				margin: 0 0 30px 0;
			}
			.sub-title {
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
				text-align: left;
				color: #1C282D;
				margin: 0 0 44px 0;
			}
			form {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				flex-wrap: wrap;
				.label {
					font-weight: 600;
					font-size: 14px;
					line-height: 16px;
					color: #1C282D;
					text-align: left;
					display: block;
					width: 100%;
				}
				input {
					width: 100%;
					border: none;
					background: #ECF4F9;
					border-radius: 4px 4px 2px 2px;
					height: 30px;
					margin: 4px 0 10px 0;
					border-bottom: #fff 0 solid;
					transition: all 200ms;
					font-size: 18px;
					line-height: 20px;
					align-items: center;
					color: #1C282D;
					padding-left: 11px;
					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						color: grey;
						font-size: 12px;
						display: flex;
						justify-content: center;
						align-items: center;
						line-height: 20px;
					}
					&::-moz-placeholder { /* Firefox 19+ */
						color: grey;
						font-size: 12px;
						display: flex;
						justify-content: center;
						align-items: center;
						line-height: 20px;
					}
					&:-ms-input-placeholder { /* IE 10+ */
						color: grey;
						font-size: 12px;
						display: flex;
						justify-content: center;
						align-items: center;
						line-height: 20px;
					}
					&:-moz-placeholder { /* Firefox 18- */
						color: grey;
						font-size: 12px;
						display: flex;
						justify-content: center;
						align-items: center;
						line-height: 10px;
					}
					&.active {
						background: #FFFFFF;
						border-radius: 4px 4px 2px 2px;
						border: none;
						border-bottom: #3F8EC5 2px solid;
					}
				}
			}
			.def-btn {
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
				width: 100%;
				padding: 5px 20px;
				height: 40px;
			}
		}
	}
}

.pos-login-root {
    /*position: relative;
    background: #FFFFFF;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 450px;
    // height: 516px;
    margin: 70px auto 0 auto;
    padding: 24px;*/
	.img-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.logo {
			margin: 0 auto;
			width: 120px;
			height: 108px;
		}
	}
    .flex-row {
        display: flex;
        margin-top: 36px;
        //height: 324px;
        .auth-type {
            flex: 1 1 50%;
            position: relative;
	        &:first-child {
		        padding-right: 24px;
	        }
	        &:last-child {
		       // border-left: 1px rgba(0, 0, 0, 0.1) solid;
		        padding-left: 24px;
	        }
            .scan-wrapper {
	            border-top: 1px rgba(0, 0, 0, 0.1) solid;
	            padding-top: 15px;
                .barcode {
                    display: flex;
                    align-items: center;
	                justify-content: center;
	                border: 2px solid;
	                border-radius: 8px;
	                padding: 5px 10px;
	                cursor: pointer;
	                color: #3F8EC5;
	                border-color: #3F8EC5;
                    p {
	                    margin: 0;
                        margin-left: 15px;
                    }
                }
            }
	        .auth-form-wrapper {
		        margin-bottom: 20px;
		        .type-radio {
			        display: flex;
			        justify-content: space-between;
			        align-items: center;
			        flex-wrap: nowrap;
			        margin-bottom: 30px;
			        width: 100%;
			        .radio-wrapper {
				        display: flex;
				        justify-content: space-between;
				        align-items: center;
				        flex-wrap: nowrap;
				        img {
					        margin-right: 5px;
				        }
			        }
		        }
		        .title {
			        font-style: normal;
			        font-weight: normal;
			        font-size: 42px;
			        line-height: 56px;
			        color: #3F8EC5;
			        text-align: left;
			        margin: 0 0 30px 0;
		        }
		        .sub-title {
			        font-weight: 600;
			        font-size: 12px;
			        line-height: 16px;
			        text-align: left;
			        color: #1C282D;
			        margin: 0 0 44px 0;
		        }
		        form {
			        display: flex;
			        justify-content: flex-start;
			        align-items: flex-end;
			        flex-wrap: wrap;
			        .label {
				        font-weight: 600;
				        font-size: 14px;
				        line-height: 16px;
				        color: #1C282D;
				        text-align: left;
				        display: block;
				        width: 100%;
			        }
			        input {
				        width: 100%;
				        border: none;
				        background: #ECF4F9;
				        border-radius: 4px 4px 2px 2px;
				        height: 30px;
				        margin: 4px 0 10px 0;
				        border-bottom: #fff 0 solid;
				        transition: all 200ms;
				        font-size: 18px;
				        line-height: 20px;
				        align-items: center;
				        color: #1C282D;
				        padding-left: 11px;
				        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					        color: grey;
					        font-size: 12px;
					        display: flex;
					        justify-content: center;
					        align-items: center;
					        line-height: 20px;
				        }
				        &::-moz-placeholder { /* Firefox 19+ */
					        color: grey;
					        font-size: 12px;
					        display: flex;
					        justify-content: center;
					        align-items: center;
					        line-height: 20px;
				        }
				        &:-ms-input-placeholder { /* IE 10+ */
					        color: grey;
					        font-size: 12px;
					        display: flex;
					        justify-content: center;
					        align-items: center;
					        line-height: 20px;
				        }
				        &:-moz-placeholder { /* Firefox 18- */
					        color: grey;
					        font-size: 12px;
					        display: flex;
					        justify-content: center;
					        align-items: center;
					        line-height: 10px;
				        }
				        &.active {
					        background: #FFFFFF;
					        border-radius: 4px 4px 2px 2px;
					        border: none;
					        border-bottom: #3F8EC5 2px solid;
				        }
			        }
		        }
		        .def-btn {
			        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			        width: 100%;
			        padding: 5px 20px;
			        height: 40px;
		        }
	        }
        }
    }
}
.marg-login-root {
    /*position: relative;
    background: #FFFFFF;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    //width: 312px;
    // height: 400px;
	width: 400px;
    margin: 10px auto 0 auto;
    padding: 44px;*/
    .flex-row {
        display: flex;
        // height: 324px;
        .auth-type{
	        width: 100%;
            .auth-form-wrapper {
	            .title{
		            font-style: normal;
		            font-weight: 400;
		            font-size: 42px;
		            line-height: 56px;
		            color: #017E8D;
		            text-align: left;
	            }
	            .sub-title{
		            font-style: normal;
		            font-weight: 600;
		            font-size: 12px;
		            line-height: 16px;
		            color: #304143;
		            text-align: left;
		            margin-top: -25px;
	            }
	            form{
		            margin-top: 44px;
		            .label {
			            font-weight: 600;
			            font-size: 14px;
			            line-height: 16px;
			            color: #1C282D;
			            text-align: left;
			            display: block;
			            margin-bottom: 5px;
		            }
		            input {
			            margin: 0 auto;
			            left: 0;
			            width: 100%;
			            height: 30px;
			            border: none;
			            font-size: 18px;
			            line-height: 20px;
			            align-items: center;
			            color: #1C282D;
			            margin-bottom: 20px;
		            }
		            .def-btn{
			            width: 100%;
			            height: 40px;
		            }
	            }
            }
        }
        .divider{
            margin: 24px auto;
            border: none;
            border-bottom: 1px solid #000000;
            opacity: 0.1;
            width: 240px;
        }
	    .scan-wrapper {
		    .barcode {
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    
			    border: 2px solid;
			    border-radius: 8px;
			    padding: 5px 10px;
			    cursor: pointer;
			    color: #017E8D;
			    border-color: #017E8D;
			    p {
				    margin: 0;
				    margin-left: 15px;
			    }
		    }
	    }
    }
}
.keypad {
    width: 400px;
    bottom: 66px;
    margin: 10px auto 0 auto;
}

@media (max-width: 1179px) {
	#pos-login-root {
		width: 60%;
	}
}


</style>
