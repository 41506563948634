<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" @onModalClose="onModalClose">
			<div class="scanner-wrapper" >
				<div class="loading" v-if="loading">
					<icon-component iconName="loader" />
				</div>
				<StreamBarcodeReader v-if="!noCertificate" ref="stream-barcode-reader" :style="!loading ? 'display:flex' : 'display:none'" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
				<!--<div class="usb-scanner-info" v-if="!loading" :class="{'with-camera': mediaDevices}">App is <span v-if="mediaDevices">also</span> waiting for barcode to be scanned (via HID protocol type scanner)</div>-->
			</div>
			
			<!--<div v-if="noCertificate" class="usb-scanner-info no-certificate" :class="{'with-camera': mediaDevices}">App is <span v-if="mediaDevices">also</span> waiting for barcode to be scanned (via HID protocol type scanner)</div>-->
			<!--<barcode-scan-interceptor v-if="showBarcodeScanInterceptor" @submitBarcode="onDecode" :openedFromLogin="openedFromLogin"></barcode-scan-interceptor>-->
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {StreamBarcodeReader} from "vue-barcode-reader";
import BarcodeScanInterceptor from "@/components/_shared/BarcodeScanInterceptor";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: "BarcodeScannerModal",
	props: ['openedFromLogin'],
	data() {
		return {
			loading: true,
			mediaDevices: null,
			noCertificate: false,
			showBarcodeScanInterceptor: false
		}
	},
	emits: ['submitBarcode', 'onModalClose'],
	components: {ModalComponent, StreamBarcodeReader, BarcodeScanInterceptor, iconComponent},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		onLoaded() {
			this.loading = false;
		},
		onDecode (result) {
			this.$emit('submitBarcode', result);
			this.close();
		},
		onModalClose() {
			this.$emit('onModalClose');
			this.loading = true;
			this.showBarcodeScanInterceptor = false;
		},
		openModal() {
			this.$refs['modal'].show();
			this.showBarcodeScanInterceptor = true;
			if (navigator.mediaDevices === undefined) {
				alert('WebRTC is not supported. Website should have a valid certificate.');
				this.mediaDevices = null;
				this.loading = true;
				this.noCertificate = true;
				// this.$refs['modal'].show();
			} else {
				navigator.mediaDevices.getUserMedia({video: true})
				.then((stream) => {
					this.loading = false;
					this.mediaDevices = true;
				}, (err) => {
					this.mediaDevices = null;
					this.loading = true;
				})
			}
		},
		close() {
			this.$refs['modal'].close()
		},
	}
}
</script>

<style scoped lang="less">

#modal-wrapper {
	:deep(#modal) {
		.window {
			border-radius: 20px;
			overflow: hidden;
			padding: 0;
			height: auto;
			width: auto;
			max-width: 90%;
			min-width: 320px;
			.close-btn {
				top: 0;
				right: 0;
				padding: 30px;
			}
		}
		.scanner-wrapper {
			border-radius: 20px;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			height: 100%;
			width: 100%;
			background-color: white;
			.usb-scanner-info {
				position: absolute;
				&.no-certificate {
					// position: relative;
					// display: block!important;
				}
				&.with-camera {
					bottom: 0;
					color: white;
				}
			}
		}
	}
	:deep(.scanner-container) {
		width: 100%;
		height: 100%;
		&>div {
			width: 100%;
			height: 100%;
			video {
				width: 100%;
				height: 100%;
			}
			.overlay-element {
				height: 99.4%;
			}
		}
	}
	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
}
</style>
