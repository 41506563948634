<template>
    <div id="alert">
        <div @mouseover="onAlertHover = true" @mouseleave="onAlertHover = false" :class="`global-alert alert ${i.type} ${i.fading}`" v-for="(i, index) in alert" :key="index" @click="clear(i, index)">
            <div class="alert-content">
                <icon-component v-if="i.icon === 'error-n.svg'" iconName="alertError" />
                <p>{{i.message}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import iconComponent from "@/components/_shared/Icon.vue";

export default {
    name: 'alert',
    components: {iconComponent},
    data() {
        return {
            count: 0,
            onAlertHover: false,
            closeTimeout: null,
            clearData: null
        }
    },
    computed: {
        alert() {
            return this.$store.state.alert
        }
    },
    methods: {
        clear(data, index) {
            if (data) {
                data['fading'] = 'fading';
                this.$forceUpdate();
                setTimeout(() => {
                    this.$store.dispatch('alert/clear', index);
                }, 0)
            }
        }
    },
    created() {},
    mounted() {},
    watch: {
        alert: {
            handler: function (res) {
                this.onAlertHover = false;
                res.forEach((t, index) => {
                    switch (t.type) {
                        case 'alert-success':
                            t.icon = 'success-n.svg';
                            break;
                        case 'alert-danger':
                            t.icon = 'error-n.svg';
                            break;
                        case 'alert-warning':
                            t.icon = 'warning-n.svg';
                            break;
                    }
                    setTimeout(() => {
                        t.fading = 'show';
                        this.$forceUpdate();
                    }, 10);
                });
                if (res.length && (this.count === 0 || this.count < res.length)) {
                    this.clearData = res[0];
                    this.closeTimeout = setTimeout(() => {
                        this.clear(this.clearData, 0);
                    }, 3000);
                }
                this.count = res.length;
            },
            deep:true
        },
        onAlertHover: function (onAlertHover) {
            if (onAlertHover && this.closeTimeout) {
                clearTimeout(this.closeTimeout);
            };
            if (!onAlertHover && this.closeTimeout) {
                this.closeTimeout = setTimeout(() => {
                    this.clear(this.clearData, 0);
                }, 1000);
            }
        }
    }

}
</script>

<style scoped lang="less">
#alert {
    position: fixed;
    top: 20px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0);
    z-index: 999999999;
    max-width: 272px;
    min-width: 272px;
    word-break: break-word;
    .alert {
        position: relative;
        background: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        padding: 12px 44px 12px 12px;
        border-radius: 6px;
        // box-shadow: 0 0 8px 1px #cecece;
        box-shadow: 1px -2px 2px rgba(0,0,0,0.15), 1px 2px 2px rgba(0,0,0,0.15);
        max-width: 300px;
        min-width: 300px;
        margin-top: 10px;
        cursor: pointer;
        // opacity: 0;
        // transition: opacity 250ms;
        &:hover {
            animation: glow 1s infinite;
        }
        &.alert-success {
            &:before {
                background-color: #4caf50;
            }
        }
        &.alert-danger {
            &:before {
                background-color: #f44336;
            }
        }
        &.alert-warning {
            &:before {
                background-color: #f0a92e;
            }
        }
        &:before {
            content: '';
            width: 20px;
            height: 100%;
            top: 50%;
            left: -6px;
            z-index: -1;
            position: absolute;
            border-radius: 10px;
            transform: translate(0, -50%);
            // opacity: 0;
            // transition: opacity 250ms;
        }
        &:after {
            content: '\2715';
            position: absolute;
            right: 16px;
            text-transform: inherit;
            top: 50%;
            color: #999999;
            transform: translate(0, -50%);
        }
        img {
            width: 20px;
            margin-right: 8px;
            height: 20px;
            overflow: hidden;
            min-width: 20px;
        }
        .alert-content {
            display: flex;
            align-items: center;
            h5 {
                font-size: 15px;
                font-weight: 500;
                color: #666;
                text-align: left;
                margin: 0;
            }
            p {
                text-align: left;
                margin: 0;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #1C282D;
                word-break: break-word;
            }
        }
        &.show {
            opacity: 1;
        }
        &.fading {
            opacity: 0;
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1200px) {}
// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {}
// Small devices (landscape phones, less than 768px)
@media (max-width: 768px) {
    #alert {
        right: 50%;
        margin-right: -150px;
    }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 576px) {

}

@keyframes glow {
    from {
        box-shadow: 1px -2px 2px #cecece, 1px 2px 2px #cecece;
    }
    to {
        box-shadow: 1px -2px 2px #dcdcdc, 1px 2px 2px #dcdcdc;
    }
}

</style>
