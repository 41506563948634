<template>
    <div id="keyboard" class="keyboard-wrapper" :class="{'external': !showByDefault}">
	    <div class="container" v-if="show" v-click-outside="blur">
		    <div class="cross" v-if="hasCloseBtn">
					<icon-component iconName="close" class="close" @click="closeKeyboard" />
				</div>
		    <div class="keyboard">
			    <div class="row first-row">
				    <button @click="handleOnButtonClick('q', '1', false)" class="letter ">{{handleOnButtonText('q', 1)}}</button>
				    <button @click="handleOnButtonClick('w', '2', false)" class="letter ">{{handleOnButtonText('w', 2)}}</button>
				    <button @click="handleOnButtonClick('e', '3', false)" class="letter ">{{handleOnButtonText('e', 3)}}</button>
				    <button @click="handleOnButtonClick('r', '4', false)" class="letter ">{{handleOnButtonText('r', 4)}}</button>
				    <button @click="handleOnButtonClick('t', '5', false)" class="letter ">{{handleOnButtonText('t', 5)}}</button>
				    <button @click="handleOnButtonClick('y', '6', false)" class="letter ">{{handleOnButtonText('y', 6)}}</button>
				    <button @click="handleOnButtonClick('u', '7', false)" class="letter ">{{handleOnButtonText('u', 7)}}</button>
				    <button @click="handleOnButtonClick('i', '8', false)" class="letter ">{{handleOnButtonText('i', 8)}}</button>
				    <button @click="handleOnButtonClick('o', '9', false)" class="letter ">{{handleOnButtonText('o', 9)}}</button>
				    <button @click="handleOnButtonClick('p', '0', false)" class="letter ">{{handleOnButtonText('p', 0)}}</button>
				    <button @click="handleOnButtonClick('clear', 'clear', true)" class="letter letter_del">
							<icon-component iconName="backSpace" />
						</button>
			    </div>
			    <div class="row row_second">
				    <button @click="handleOnButtonClick('a', '-', false)" class="letter">{{handleOnButtonText('a', '-')}}</button>
				    <button @click="handleOnButtonClick('s', '/', false)" class="letter">{{handleOnButtonText('s', '/')}}</button>
				    <button @click="handleOnButtonClick('d', ':', false)" class="letter">{{handleOnButtonText('d', ':')}}</button>
				    <button @click="handleOnButtonClick('f', ';', false)" class="letter">{{handleOnButtonText('f', ';')}}</button>
				    <button @click="handleOnButtonClick('g', '(', false)" class="letter">{{handleOnButtonText('g', '(')}}</button>
				    <button @click="handleOnButtonClick('h', ')', false)" class="letter">{{handleOnButtonText('h', ')')}}</button>
				    <button @click="handleOnButtonClick('j', '$', false)" class="letter">{{handleOnButtonText('j', '$')}}</button>
				    <button @click="handleOnButtonClick('k', '&', false)" class="letter">{{handleOnButtonText('k', '&')}}</button>
				    <button @click="handleOnButtonClick('l', '@', false)" class="letter">{{handleOnButtonText('l', '@')}}</button>
				    <button class="letter inactive"></button>
					<button class="letter inactive letter_enter"><!--<img src="@/assets/img/entericon.png ">--></button>
			    </div>
			    <div class="row row_third">
				    <button class="letter inactive"><!--<img src="@/assets/img/arrowShiftLeft.png ">--></button>
				    <button @click="handleOnButtonClick('z', false, false)" class="letter" :class="{'inactive': type !== 'keyboard'}">{{handleOnButtonText('z', '')}}</button>
				    <button @click="handleOnButtonClick('x', false, false)" class="letter" :class="{'inactive': type !== 'keyboard'}">{{handleOnButtonText('x', '')}}</button>
				    <button @click="handleOnButtonClick('c', '.', false)" class="letter">{{handleOnButtonText('c', '.')}}</button>
				    <button @click="handleOnButtonClick('v', ',', false)" class="letter">{{handleOnButtonText('v', ',')}}</button>
				    <button @click="handleOnButtonClick('b', '?', false)" class="letter">{{handleOnButtonText('b', '?')}}</button>
				    <button @click="handleOnButtonClick('n', '!', false)" class="letter">{{handleOnButtonText('n', '!')}}</button>
				    <button @click="handleOnButtonClick('m', '\'', false)" class="letter">{{handleOnButtonText('m', '\'')}}</button>
				    <button class="letter inactive"></button>
					<button class="letter inactive"></button>
					<button class="letter inactive"></button>
				    <button class="letter inactive letter_shift"><!--<img src="@/assets/img/arrowShiftLeft.png ">--></button>
			    </div>
			    <div class="row row_fourth">
				    <!--$emit('clickSymbol')-->
				    <button @click="changeType()" class="letter ">{{type === 'keyboard' ? '&123' : 'ABC'}}</button>
				    <button class="letter inactive"></button>
				    <button class="letter inactive"><!--<img src="@/assets/img/emojiIcon.png ">--></button>
				    <button class="letter letter_space" @click="handleOnButtonClick(' ', ' ', true)"></button>
				    <button class="letter" @click="handleOnButtonClick(false, false, false, 'left')"> &lt; </button>
					<button class="letter" @click="handleOnButtonClick(false, false, false, 'right')"> > </button>
					<button class="letter inactive"></button>
			    </div>
		    </div>
	    </div>
    </div>
</template>

<script>
import Keypad from "@/components/_shared/Keypad";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: "Keyboard",
	components: {Keypad, iconComponent},
	emits: ['clickOnKey', 'clear', 'cursorMove'],
	data() {
		return {
			show: false,
			blurCanBeTriggered: false,
			type: 'keyboard'  // keyboard / keypad
		}
	},
	props: {
		hasCloseBtn: {
			type: Boolean,
			default: true,
			required: false
		},
		hasBlurAction: {
			default: false,
			required: false
		},
		showByDefault: {
			default: true,
			required: false
		},
		itemToFocus: {
			required: false
		}
	},
	created() {
		if (this.showByDefault) {
			this.show = this.showByDefault;
		}
	},
	methods: {
		showKeyboard() {
			this.show = false;
			setTimeout(() => {
				this.show = true;
				this.blurCanBeTriggered = true;
			}, 0)
		},
		changeType() {
			if (this.type === 'keyboard') {
				this.type = 'keypad';
			} else {
				this.type = 'keyboard';
			}
		},
		closeKeyboard() {
			this.show = false;
			this.blurCanBeTriggered = false;
		},
		blur() {
			if (this.hasBlurAction) {
				if (this.show && this.blurCanBeTriggered) {
					this.closeKeyboard();
				}
			}
		},
		handleOnButtonText(keyboardValue, keypadValue, both) {
			if (this.type === 'keyboard') {
				return keyboardValue
			} else {
				return keypadValue
			}
		},
		handleOnButtonClick(keyboardValue, keypadValue, both, cursorMove) {
			if (!keyboardValue && !keypadValue && !both) {
				this.$emit('cursorMove', cursorMove);
			} else {
				if (both) {
					if (keyboardValue === 'clear') {
						this.$emit('clear')
					} else {
						this.$emit('clickOnKey', keyboardValue)
					}
				} else {
					if (this.type === 'keyboard') {
						this.$emit('clickOnKey', keyboardValue)
					} else {
						if (keypadValue) {
							this.$emit('clickOnKey', keypadValue)
						}
					}
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
#keyboard {
	width: 100%;
	z-index: 1;
	position: absolute;
	&.external {
		bottom: 8px;
		left: 0;
		right: 0;
	}
	&.keypad-type {
		border: none;
		.container {
			box-shadow: none;
			border: none;
			background-color: unset;
			padding: 0;
			width: 100%;
			.keypad {
				margin: 0 auto;
			}
		}
	}
	.container{
		width: 1010px;
		padding: 10px;
		background: #FFFFFF;
		box-shadow: 0 0 10px 0 #bdbdbd;
		border-radius: 10px;
		margin: 0 auto;
		.cross {
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			.close{
				padding: 15px;
			}
		}
		.row {
			grid-template-columns: repeat(10,75px) 162px;
			display: grid;
			height: 63px;
			grid-column-gap: 6px;
			margin: 6px 8px 0 8px;
			.letter{
				border: none;
				width: 75px;
				height: 63px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: normal;
				font-size: 30px;
				line-height: 40px;
				color: #FFFFFF;
				&.inactive {
					background-color: #A3BFDC;
				}
				&_del{
					width: 162px;
				}
				&_enter{
					width: 136px;
				}
				&_space{
					width: 486px;
				}
				&_shift{
					width: 81px;
				}
				&:active{
					background: #95c7ec;
					
				}
			}
			&_second{
				margin:6px 8px 0 34px;
			}
			&_third{
				grid-template-columns: repeat(12,75px) ;
			}
			&_fourth{
				grid-template-columns: repeat(3,75px) 486px repeat(3,75px) ;
			}
		}
	}
}
// @media (max-width: 1200px) {
// 	#keyboard {}
// }
@media (max-width: 850px) {
	#keyboard {
		.container {
			width: unset;
			.row {
				grid-template-columns: repeat(10, 55px) 134px;
				
				.letter {
					height: unset;
					width: unset;
				}
				&.row_second {
					grid-template-columns: repeat(10, 55px) 108px;
				}
				&.row_third {
					grid-template-columns: repeat(12, 56.5px);
				}
				&.row_fourth {
					grid-template-columns: repeat(3, 55px) 376px repeat(3, 55px)!important;
				}
			}
		}
	}
}
</style>
