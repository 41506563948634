const ud = JSON.parse(localStorage.getItem('ud'))
const posId = JSON.parse(localStorage.getItem('posId'))
const posData = JSON.parse(localStorage.getItem('posData'))
const initialState = ud
	? {status: {loggedIn: true}, user: ud, posId: posId, posData: posData}
	: {status: {loggedIn: false}, user: null, posId: posId, posData: null}

export const auth = {
	namespace: true,
	state: initialState,
	actions: {
		setupPosId({commit, dispatch}, data) {
			localStorage.setItem('posId', JSON.stringify(data));
			commit('SETUP_POS_ID', data);
		},
		savePosData({commit, dispatch}, data) {
			localStorage.setItem('posData', JSON.stringify(data));
			commit('SAVE_POS_DATA', data);
		},
		loginUser({commit, dispatch}, data) {
			localStorage.setItem('ud', JSON.stringify(data));
			commit('LOGIN_USER', data);
		},
		logoutUser({commit, dispatch}) {
			localStorage.removeItem('ud');
			localStorage.removeItem('posData');
			localStorage.removeItem('checksTab');
			commit('LOGOUT_USER');
		},
		terminalReset({commit, state}) {
			localStorage.clear();
			commit('TERMINAL_RESET');
		}
	},
	mutations: {
		SETUP_POS_ID(state, data) {
			state.posId = data;
		},
		SAVE_POS_DATA(state, data) {
			state.posData = data;
		},
		LOGIN_USER(state, data) {
			state.status = {loggedIn: true};
			state.user = data;
		},
		LOGOUT_USER(state) {
			state.status = {loggedIn: false};
			state.user = null;
			state.posData = null;
		},
		TERMINAL_RESET(state) {
			state.posId = null;
			state.posData = null;
			state.status = {loggedIn: false};
			state.user = null;
		}
	}
}
