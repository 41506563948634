<template>
    <div id="modal-wrapper">
	    <modal-component ref="modal" @onModalClose="onModalClose">
		    <div id="order-options-modal" class="modal-container">
					<base-button text="COMBINE ORDER" classes="def-btn secondary-action-btn uppercase medium border-2" @onClick="combineOrderHandler"/>
					<base-button text="SPLIT ORDER" classes="def-btn secondary-action-btn uppercase medium border-2" @onClick="splitOrderHandler"/>
				<cancel-close-order :selectedOrder="data" :openFrom="'orderOptions'"/>
		    </div>
	    </modal-component>
    </div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import CancelCloseOrder from "@/components/_modals/CancelCloseOrder";
import BaseButton from "@/components/_shared/Button.vue";

export default {
    name: "OrderOptionsModal",
    data() {
        return {
            data: null
		}
    },
	emits: ['combineOrder', 'splitOrder', 'onModalClose', 'closeOrderOptionsModal'],
  components: {CancelCloseOrder, ModalComponent, BaseButton},
	computed: {
		isWaiterAssist() {
			return this.$store.state.auth.user.user.role === 'waiter assist';
		},
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close();
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
		this.emmiter.emit('toggleModals', {opened: false, type: 'OrderOptionsModal'});
	},
    methods: {
	    onModalClose() {
		    this.emmiter.emit('toggleModals', {opened: false, type: 'OrderOptionsModal'});
			this.$emit('onModalClose');
	    },
        openModal(data) {
            this.$refs['modal'].show();
            this.data = data;
	        this.emmiter.emit('toggleModals', {opened: true, type: 'OrderOptionsModal'});
        },
        close() {
            this.$refs['modal'].close();
			this.$emit('closeOrderOptionsModal');
        },
        combineOrderHandler() {
            this.$emit('combineOrder', this.data);
            this.close();
        },
        splitOrderHandler() {
            this.$emit('splitOrder', this.data);
            this.close();
        }
    }
}
</script>

<style scoped lang="less">
#modal-wrapper {
	#order-options-modal {
		padding: 42px;
		button{
			width: 373px;
			&:not(:last-child){
				margin-bottom: 24px;
			}
		}
	}
}


</style>
