<template>
  <Toggle 
    v-model="value"
    :id="id"
    :offLabel="offLabel"
    :onLabel="onLabel"
    class="switch"
    @change="switched(value)"
  />
</template>

<script>
import Toggle from '@vueform/toggle'
export default {
	name: 'Switch',
  components: {Toggle},
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: 'toggle'
    },
    name: {
      type: [String, Number],
      required: false,
      default: 'toggle'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    falseValue: {
      type: [String, Number, Boolean],
      required: false,
      default: false,
    },
    trueValue: {
      type: [String, Number, Boolean],
      required: false,
      default: true,
    },
    onLabel: {
      type: [String, Object],
      required: false,
      default: 'INCLUDED'
    },
    offLabel: {
      type: [String, Object],
      required: false,
      default: 'DISABLED'
    },
    classes: {
      type: Object,
      required: false,
      default: () => ({
        container: 'toggle-container',
        toggle: 'toggle',
        toggleOn: 'toggle-on',
        toggleOff: 'toggle-off',
        toggleOnDisabled: 'toggle-on-disabled',
        toggleOffDisabled: 'toggle-off-disabled',
        handle: 'toggle-handle',
        handleOn: 'toggle-handle-on',
        handleOff: 'toggle-handle-off',
        handleOnDisabled: 'toggle-handle-on-disabled',
        handleOffDisabled: 'toggle-handle-off-disabled',
        label: 'toggle-label',
      })
    },
    labelledby: {
      type: String,
      required: false,
    },
    describedby: {
      type: String,
      required: false,
    },
    val: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      value: false,
    };
  },
  emits: ['toggled'],
  created() {
    this.setValue();
  },
  watch: {},
  methods: {
    setValue() {
      this.value = this.val
    },
    switched(value) {
      this.$emit('toggled', value)
    }
  },
  computed: {},
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
