<template>
	<div :class='openFrom'>
		<base-button text="cancel order" @onClick="cancelOrder" classes="def-btn cancel-action-btn uppercase medium border-2" :is-disabled="isWaiterAssist && isWaiterAssist.user.role === 'waiter assist'"/>
		<base-button text="close order" @onClick="closeOrder"
		             classes="def-btn main-action-btn uppercase medium border-2 icon left" :is-disabled="isWaiterAssist && isWaiterAssist.user.role === 'waiter assist'"
		             img-src="close-order.svg" custom-styles="background-color: #EB262B; border-color: #EB262B;"/>
	</div>
	<cancel-order-modal
		:selectedOrder="selectedOrder"
		ref="cancel-order-modal"
		v-if="showCancelOrderModal"
		@cancelOrder="cancelOrderCallback"
	/>
	<close-order-modal
		:selectedOrder="selectedOrder"
		ref="close-order-modal"
		v-if="showCloseOrderModal"
		@closeModal="close"
		@onModalClose="closeModal"
	/>
</template>

<script>
import CancelOrderModal from "@/components/_modals/CancelOrderModal";
import CloseOrderModal from "@/components/_modals/CloseOrderModal";
import BaseButton from "@/components/_shared/Button.vue";

export default {
    name: "CancelCloseOrder",
    emits:['closeOrder', 'closeModal', 'onModalClose', 'cancelOrder'],
    components: {BaseButton, CancelOrderModal, CloseOrderModal, },
    props:{
        selectedOrder: {
            type: Object,
	        required: true
        },
		openFrom: {
			type: String,
			required: true
		}
    },
    data() {
        return {
			showCloseOrderModal: false,
	        showCancelOrderModal: false
		}
    },
	computed: {
	    isWaiterAssist() {
		    return this.$store.state.auth.user;
	    }
	},
	mounted() {},
	beforeUnmount() {},
	methods: {
		closeModal() {
			this.showCloseOrderModal = false;
		},
		close() {
            this.$refs['modal'].close();
        },
		cancelOrderCallback(data) {
			this.$emit('cancelOrder', data);
		},
		cancelOrder() {
			if (this.isWaiterAssist && this.isWaiterAssist.user.role === 'waiter assist') {} else {
				if (!this.selectedOrder.items.length) {
					this.emmiter.emit('cancelOrder', this.selectedOrder);
					// this.$emit('cancelOrder', this.selectedOrder);
					// this.cancelOrder();
				} else {
					this.showCancelOrderModal = true;
					this.$nextTick(() => {
						this.$refs['cancel-order-modal'].openModal(this.selectedOrder);
					})
				}
			}
		},
        closeOrder() {
	        if (this.isWaiterAssist && this.isWaiterAssist.user.role === 'waiter assist') {} else {
		        if (this.selectedOrder.items.length > 0) {
			        this.showCloseOrderModal = true;
			        this.$nextTick(() => {
				        this.$refs['close-order-modal'].openModal();
			        })
		        }
	        }
        }
    }
}
</script>

<style scoped lang="less">
	.orderOptions {
		button{
			width: 373px;
			&:not(:last-child){
				margin-bottom: 24px;
			}
		}
	}

	.selectedOrder {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
			button {
				box-sizing: border-box;
				width: 49%;
					@media (max-width: 1200px) {
						height: 40px;
						font-size: 11px;
					}
			}
	}
</style>
