import store from "@/store";
import {orderService} from "@/_api/order.api";

export const formatDataService = {
	
	formatItemsForAPI(items, createOrder) {
		let obj1 = JSON.parse(JSON.stringify(items));
		return obj1.map((obj) => {
			let objToSend = {};
			const condiments = [];
			const modifiers = [];
			const we = [];
			if (obj.combo || obj.orderable_type === "App\\Models\\MenuCombo") {
				obj.items.forEach((oq) => {
					let temp = {
						menu_combo_item_id: oq.menu_combo_item_id ? oq.menu_combo_item_id : oq.id,
						// menu_combo_item_id: oq.menu_combo_item ? oq.menu_combo_item.id : oq.id,
						modifiers: [],
						condiments: []
					};
					if (oq.menu_combo_item_id) { // it means that this is going on with the item that was already added
						temp['id'] = oq.id
					}
					if (obj.remove_id) {
						delete temp['id'];
					}
					we.push(temp)
				})
			}
			if (obj.condiments_parsed && obj.condiments_parsed.length) {
				obj.condiments_parsed.forEach((o) => {
					if (o.type === 1) {
						o.items.forEach((oo) => {
							if (oo.checked) {
								let ob = {
									menu_item_condiment_id: oo.menu_item_condiment_id,
									count: obj.count * oo.count,
									menu_combo_item_id: o.combo_item_id // gonna be deleted
								};
								if (o.position_id) {
									ob['id'] = o.position_id
								}
								condiments.push(ob);
							}
						})
					}
				})
			}
			if (obj.modifiers_parsed && obj.modifiers_parsed.length) {
				obj.modifiers_parsed.forEach((o) => {
					let shouldObjectBeAdded = true;
					let objToPush = {
						menu_combo_item_id: o.combo_item_id, // gonna be deleted
						menu_item_modifier_id: o.menu_item_modifier_id ? o.menu_item_modifier_id : o.id,
						free_text: null
					};
					if (o.menu_item_modifier_id) {
						objToPush['id'] = o.id;
					}
					if (o.is_required) {
						objToPush['options'] = [];
						let checkedIndex = o.options.filter((oo) => {return oo.checked});
						if (checkedIndex.length) {
							checkedIndex.forEach((pp) => {
								if (pp.id === null) {
									objToPush['free_text'] = pp.name;
								}
								if (pp.id !== null) {
									objToPush['options'].push(pp.id);
								}
							})
						}
					} else {
						let checkedIndexes = o.options.filter((oo) => {return oo.checked});
						let isFreeTextOption = checkedIndexes.findIndex((oo) => {return oo.id === null});
						let idsFiltered = checkedIndexes.filter(oo => {return oo.id !== null}).map(oo => oo.id);
						if (o.options.filter(o => o.id !== null).length) {
							objToPush['options'] = idsFiltered;
						}
						if (isFreeTextOption !== -1) {
							objToPush['free_text'] = checkedIndexes[isFreeTextOption].name;
						}
						
						if ((!o.has_free_text && !o.options.length && !o.checked) || (o.has_free_text && !checkedIndexes.length) || (!o.has_free_text && o.options.length && !checkedIndexes.length)) { // CUSTOM OPTION WHEN MODIFIER IS OPTIONAL, HAS NO OPTIONS AND NO FREE TEXT AND IS NOT CHECKED THEN WE SHOULD NOT ADD THIS MODIFIER AT ALL
							shouldObjectBeAdded = false;
						}
					}
					
					if (shouldObjectBeAdded) {
						modifiers.push(objToPush);
					}
				})
			}
			
			condiments.forEach(n => {
				const tempId = n.menu_combo_item_id;
				delete n.menu_combo_item_id;
				we.forEach(nn => {
					if (tempId === nn.menu_combo_item_id) {
						nn.condiments.push(n);
					}
				})
			});
			modifiers.forEach(n => {
				const tempId = n.menu_combo_item_id;
				delete n.menu_combo_item_id;
				we.forEach(nn => {
					if (tempId === nn.menu_combo_item_id) {
						nn.modifiers.push(n);
					}
				})
			});
			
			objToSend = {
				id: obj.id,
				seat: obj.seat ? obj.seat : 1,
				count: obj.count,
				status: obj.status,
				condiments: !obj.combo ? condiments : [],
				modifiers: !obj.combo ? modifiers : [],
				items: we,
				orderable_id: obj.orderable_id,
				orderable_type: obj.orderable_type,
				print_in_min: obj.print_in_min ? obj.print_in_min : null
			}
			
			if (obj.remove_id) {
				delete objToSend['id'];
			}
			/*if (!we.length) {
				delete objToSend['items'];
			}
			if (createOrder || obj.from_menu) {
				delete objToSend['id'];
			}*/
			return objToSend;
		})
	},
	formatItemCondiments(el, menu_item, is_combo) {
		const arrParser = (data) => {
			let arr = [];
			let cond_arr = menu_item ? data.condiments : data.available_condiments;
			if (cond_arr && cond_arr.length) {
				cond_arr.forEach((o) => { // if condiments are added to this item i create temp array "condiments_parsed" to work with in the future. I compare names of the array items (for required condiment items they are the same) and place these items into an array to be able to show them properly in the UI
					o.menu_item['orderable_type'] = 'App\\Models\\MenuItem';
					let index = arr.findIndex((i) => {return i.name === o.name});
					if (index === -1) {
						let q = {
							type: o.type,
							name: o.name,
							combo_item_id: (menu_item ? data.id : (is_combo ? data.menu_combo_item_id : data.id)),
							// combo_item_id: (menu_item ? data.id : (is_combo ? data.menu_combo_item.id : data.id)),
							items: [],
							price: o.price,
							count: o.count,
							menu_item: o.menu_item
						};
						q.items = [{
							menu_item_condiment_id: o.id,
							menu_item_id: o.menu_item.id,
							count: o.count,
							price: o.price,
							name: o.menu_item.name,
							checked: false,
						}];
						arr.push(q);
					} else {
						arr[index].items.push({
							menu_item_condiment_id: o.id,
							menu_item_id: o.menu_item.id,
							count: o.count,
							price: o.price,
							name: o.menu_item.name,
							checked: false,
							discount: 55
						});
					}
				});
			}
			arr.forEach((o) => { // checking the first item of required condiments as by default
				if (o.type === 1) {
					o.items[0].checked = true;
				}
			});
			return arr
		}
		let parsed_condiments = [];
		let selectedParser = (selected) => {
			parsed_condiments.forEach((o) => {
				selected.condiments.forEach((oo) => {
					if (oo.menu_item_condiment.name === o.name) {
						o.items.forEach(ooo => {
							ooo.checked = false;
							if (ooo.menu_item_condiment_id === oo.menu_item_condiment.id) {
								ooo.checked = true;
							}
						});
						
						o['position_id'] = oo.id;
					}
				})
			})
		}
		if (is_combo) {
			el.items.forEach((o) => {
				parsed_condiments = [...parsed_condiments, arrParser(o)]
			});
			parsed_condiments = parsed_condiments.flat(1);
			if (!menu_item) { // THIS ITEM IS FROM ORDER WE HAVE TO COMPARE SELECTED MODIFIERS TO LIST OF ALL MODIFIERS AND IN THE LIST OF ALL MODIFIERS SELECT REQUIRED ONES
				el.items.forEach((o) => {
					o['menu_item_id'] = o.menu_combo_item_id;
					selectedParser(o);
				});
			}
		} else {
			parsed_condiments = arrParser(el);
			if (!menu_item) {
				selectedParser(el);
			}
		}
		return parsed_condiments;
	},
	formatItemModifiers(el, menu_item, is_combo) {
		const arrParser = (data) => {
			
			let cond_arr = menu_item ? (is_combo ? data.menu_item.modifiers : data.modifiers) : (is_combo ? data.available_modifiers : data.available_modifiers);
			return cond_arr.map((o) => {
				/*if (o.is_required && o.options.length) {
					o.options[0] = {...o.options[0], checked: true};
				}*/
				if (o.has_free_text && ((o.options.length && o.options[o.options.length - 1].id !== null) || !o.options.length)) {
					o.options.push({id: null, type: 'free_text', name: '', checked: false})
				}
				
				const objToTransform = {...o, checked: !!o.is_required};
				if (menu_item || !data.orderable_type) {
					return {...objToTransform, combo_item_id: (menu_item ? data.id : data.menu_combo_item_id)}
					// return {...objToTransform, combo_item_id: (menu_item ? data.id : data.menu_combo_item.id)}
				}
				return objToTransform
			})
		}
		
		// let arr = [];
		let parsed_modifiers = [];
		let selectedParser = (selected) => {
			parsed_modifiers.forEach((o) => {
				selected.modifiers.forEach((oo) => {
					if (oo.menu_item_modifier.id === o.id) {
						o['id'] = oo.id;
						o['menu_item_modifier_id'] = oo.menu_item_modifier.id;
						o['combo_item_position_id'] = selected.id;
						if (oo.menu_item_modifier.is_required) {
							if (oo.options.length) {
								o.options.forEach((t) => {
									t['checked'] = false;
									oo.options.forEach((tt) => {
										if (t.id === tt.id) {
											t['checked'] = true
										}
										// t['checked'] = t.id === tt.id;
									});
									if (t.id === null) {
										t['checked'] = !!oo.free_text;
										t['name'] = oo.free_text
									}
									// t['checked'] = t.id === oo.options[0].id;
								})
							} else {
								o.options.forEach((t) => {
									t['checked'] = false;
									if (t.id === null) {
										t['checked'] = true;
										t['name'] = oo.free_text
									}
								})
							}
						} else {
							o.options.forEach((t) => {
								oo.options.forEach((tt) => {
									if (t.id === tt.id) {
										t['checked'] = true;
									}
									// t['checked'] = t.id === tt.id;
								})
							});
							if (!oo.free_text && !oo.options.length) {
								o['checked'] = true;
							}
							
							/*this.$nextTick(() => {
								if (o.options.some((o) => o.checked)) {
									o.checked = true;
								}
							})*/
							
							if (o.options.some((o) => o.checked)) {
								o.checked = true;
							}
							
							if (oo.free_text) {
								o.options[o.options.length - 1]['name'] = oo.free_text;
								o.options[o.options.length - 1]['checked'] = true;
							}
						}
					}
				})
			})
		}
		if (is_combo) {
			el.items.forEach((o) => {
				parsed_modifiers = [...parsed_modifiers, arrParser(o)];
			});
			parsed_modifiers = parsed_modifiers.flat(1);
			if (!menu_item) { // THIS ITEM IS FROM ORDER WE HAVE TO COMPARE SELECTED MODIFIERS TO LIST OF ALL MODIFIERS AND IN THE LIST OF ALL MODIFIERS SELECT REQUIRED ONES
				el.items.forEach((o) => {
					// o['menu_item_id'] = o.menu_combo_item.id
					o['menu_item_id'] = o.menu_combo_item_id
					selectedParser(o);
				});
			}
		} else {
			parsed_modifiers = arrParser(el);
			if (!menu_item) {
				selectedParser(el);
			}
			
		}
		return parsed_modifiers
	},
	handleCondimentsModifiers(items) {
		items.forEach(el => {
			el['combo'] = el.orderable_type === "App\\Models\\MenuCombo";
			el['modifiers_parsed'] = formatDataService.formatItemModifiers(el, false, el['combo']);
			el['condiments_parsed'] = formatDataService.formatItemCondiments(el, false, el['combo']);
		})
		
		return items;
	},
};

