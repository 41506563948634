<template>
	<aside id="sidebar" :class="isChecksDetails ? 'hide' : design">
		<div class="menu">
			<router-link to="/">
				<icon-component iconName="sidebarPos" class="icon" />
				<span class="text">POS</span>
			</router-link>
			<router-link to="/checks">
				<icon-component iconName="sidebarChecks" class="icon" />
				<span class="text">Checks</span>
			</router-link>
			<router-link to="/dashboard">
				<icon-component iconName="sidebarDashboard" class="icon" />
				<span class="text">Dashboard</span>
			</router-link>
			<router-link to="/reports">
				<icon-component iconName="sidebarReports" class="icon" />
				<span class="text">Reports</span>
			</router-link>
			<router-link to="/printers" :class="{'no-bar-printer': (isCheckPrinterSetup && authData.user?.user.role === 'manager'), 'no-access': authData.user?.user.role !== 'manager'}" >
				<icon-component iconName="sidebarPrinters" class="icon" />
				<span class="text">Printers</span>
			</router-link>
			<router-link to="/settings">
				<icon-component iconName="sidebarSettings" class="icon" />
				<span class="text">Settings</span>
			</router-link>
		</div>
	</aside>
</template>

<script>
import {printersService} from "@/_api/printers.api";
import iconComponent from "../_shared/Icon.vue";

export default {
	name: 'SidebarComponent',
	props: {},
	components: {iconComponent},
	data() {
		return {
			printersList: [],
			isChecksDetails: false,
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
		isCheckPrinterSetup() {
			return !this.printersList.length || (this.printersList.length && !this.printersList.some((o) => o.type === 1))
		},
		authData() {
			return this.$store.state.auth
		},
	},
	created() {},
	mounted() {
		this.emmiter.on('checkPrinterAdded', () => {
			this.getPrinters();
		});
		if (this.authData.status.loggedIn && this.authData.user.user.role === 'manager') {
			this.getPrinters();
		}
	},
	beforeUnmount() {
		this.emmiter.off('checkPrinterAdded');
	},
	methods: {
		getPrinters() {
			printersService.getPrinters({
				page: 1,
				per_page: 1000
			}).then((res) => {
				this.printersList = res.data;
			})
		},
	},
	watch: {
		$route() {
			let currentUrl = window.location.pathname;
			let currentUrl_1 = currentUrl.split('/')[1];
			let currentUrl_2 = currentUrl.split('/')[2];
			if (currentUrl_1 === 'checks' && currentUrl_2) {
				this.isChecksDetails = true;
			} else {
				this.isChecksDetails = false;
			};
    },
	}
}
</script>

<style lang="less" scoped>
#sidebar {
	display: none;
	width: 96px;
	position: absolute;
	top: 84px;
	height: calc(100% - 108px);
	overflow-x: auto;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	padding: 12px 0;
	transition-property: width;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	&.ecruise {
		.menu {
			a {
				&.router-link-active {
					.icon {
						filter: invert(0%) sepia(6%) saturate(24%) hue-rotate(268deg) brightness(0%) contrast(107%);
					}
					.text {
						color: #000;
					}
				}
				.icon {
					filter: brightness(0) saturate(100%) invert(46%) sepia(89%) saturate(345%) hue-rotate(162deg) brightness(92%) contrast(91%);
				}
				.text {
					color: #3F8EC5;
				}
				transition-property: all;
				transition-timing-function: ease-in-out;
				transition-duration: 300ms;
			}
		}
	}
	&.margaritaville {
		.menu {
			a {
				&.router-link-active {
					.icon {
						filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(156deg) brightness(500%) contrast(108%);
					}
					.text {
						color: #fff;
					}
				}
				.icon {
					filter: invert(0%) sepia(97%) saturate(585%) hue-rotate(143deg) brightness(96%) contrast(99%);
				}
				.text {
					color: #017E8D;
				}
			}
		}
	}
	.menu {
		display: flex;
		justify-content: left;
		align-items: center;
		flex-wrap: wrap;
		&:first-child {
			a {
				&:not(:last-child) {
					margin-bottom: 18px;
				}
				
				&:last-child {
					margin-top: 96px;
				}
			}
		}
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 78px;
			height: 78px;
			font-size: 10px;
			font-weight: 600;
			text-align: center;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			text-decoration: none;
			transition: all 200ms;
			&.no-bar-printer {
				background-color: #FBD4D5!important;
			}
			&.disabled {
				pointer-events: none;
			}
			&.router-link-active {
				width: 90px;
				height: 78px;
				.text {
					color: #ffffff;
				}
			}
			.text {
				font-weight: 600;
				font-size: 14px;
				line-height: 120%;
			}
			.icon {
				margin-bottom: 4px;
				filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(124deg) brightness(108%) contrast(105%);
			}
		}
	}
}

#sidebar.hide {
	width: 0px;
	transition-property: width;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
}

@media (max-width: 1200px) {
	#sidebar {
		top: 80px;
		height: calc(100% - 100px);
	}
}
@media (max-width: 850px) {

}

</style>
