import {printersService} from "@/_api/printers.api";

let interval = null;
export const printingCheckLoop = () => {
	clearInterval(interval);
	interval = setInterval(() => {
		printersService.checkPrintFileExistence().then((response) => {
			if (response === "true") {
				printersService.printExistingFiles().then(res => {})
			}
		})
	}, 5000);
}
export const stopInterval = () => {
	clearInterval(interval);
}
