<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" :has-keyboard="true" @clickOnKey="click" @onModalClose="onModalClose">
			<div id="comment-modal" class="modal-container">
				<div class="modal-header">
					{{ title }}
				</div>
				<h2 class="sub-title">
					{{ subTitle }}
					<span v-if="subTitleInfo.length">{{ subTitleInfo }}</span>
				</h2>
				<div class="content">
					<textarea class="input" v-model="text" placeholder="Input your comment here"></textarea>
				</div>
				<div class="modal-controls x2">
					<base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
					<base-button :text="submitBtnText" classes="def-btn main-action-btn uppercase medium border-2" @onClick="submitComment"/>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import Keyboard from "@/components/_shared/Keyboard";
import BaseButton from "@/components/_shared/Button.vue";

export default {
	name: 'CommentModal',
	components: {Keyboard, ModalComponent, BaseButton},
	props: {
		title: {
			required: false,
			type: String,
			default: 'Comment'
		},
		subTitle: {
			required: false,
			type: String,
			default: ''
		},
		subTitleInfo: {
			required: false,
			type: String,
			default: ''
		},
		submitBtnText: {
			required: false,
			type: String,
			default: 'Proceed'
		},
	},
	emits: ['submitComment', 'onModalClose'],
	data() {
		return {
			text: ''
		}
	},
	computed: {},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	methods: {
		onModalClose() {
			this.$emit('onModalClose');
		},
		openModal() {
			this.$refs['modal'].show();
		},
		close() {
			this.text = '';
			this.$refs['modal'].close();
		},
		submitComment() {
			this.$emit('submitComment', this.text);
			this.close();
		},
		click(e) {
			if (e === undefined) {
				this.text = this.text.substring(0, this.text.length - 1);
				if (!this.text.length) {
					this.text = '';
				}
			} else {
				this.text += e;
			}
		}
	}
}
</script>

<style lang="less">
#modal-wrapper {
	#comment-modal {
		width: 400px;
		.modal-header {
			margin: 0 0 10px 0;
		}
		.sub-title {
			// color: var(--theme_color);
			font-weight: 600;
			color: #1C282D;
			font-size: 12px;
			span {
				display: flex;
				font-weight: 400;
				justify-content: center;
				width: 100%;
			}
		}
		.content {
			.input {
				width: 100%;
				border: 1px solid gainsboro;
				resize: none;
				height: 120px;
				border-radius: 4px;
				font-size: 16px;
				color: #202020;
				box-sizing: border-box;
				margin-bottom: 20px;
				padding: 10px;
				&::placeholder {
					color: #777E81;
					font-size: 12px;
				}
				&:focus {
					outline: none;
				}
			}
		}
	}
}
</style>
