<template>
	<div id="modal-wrapper">
		<modal-component ref="modal" @onModalClose="this.$emit('onModalClose')">
			<div id="split-order-modal" class="modal-container">
				<div class="modal-header">Split order</div>
				<div class="modal-subtitle">select items you want to allocate to new order</div>
				<div class="payer-table-select-wrapper">
					<div class="payer-select-wrapper" :class="{'required': validation && getPosData.department.flow_ordering_guest && !newPayer}">
						<div class="payer-select-label">New payer <span v-if="getPosData.department.flow_ordering_guest">(required)</span></div>
						<div class="payer-select" :class="{'not-specified': !newPayer}" @click="openSetPayerModal">
							<span>{{ newPayer ? `${newPayer.first_name} ${newPayer.last_name}` : 'N/A' }}</span>
						</div>
					</div>
					<div class="table-select-wrapper">
						<div class="table-select-label">Table</div>
						<div class="table-select" @click="openSetTableModal" :class="{'not-specified': (!newTable ? (!selectedOrder.table) :  (!newTable.table))}">
							<span>{{ !newTable ? (selectedOrder.table ? selectedOrder.table : 'N/A') : (newTable.table ? newTable.table : 'N/A')}}</span>
						</div>
					</div>
				</div>
				<div class="items-list-wrapper">
					<table>
						<thead>
						<tr>
							<th></th>
							<th class="item">item</th>
							<th class="qty">qty</th>
							<th class="st">st.</th>
							<th class="seat">seat</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(i, index) in selectedOrder.items" :class="{'disabled': i.legal_age_class === 1 && (!newPayer || newPayer.intoxication_state)}">
							<td @click="i.checked = !i.checked">
								<icon-component :iconName="i.checked ? 'checkboxChecked' : 'checkboxUnchecked'" />
							</td>
							<td>{{i.name}}</td>
							<td>
								<div class="qty">
									<icon-component iconName="minusBg" @click="changeQty(i, 'minus')" />
									<span>{{ i.qty_to_split }}</span>
									<icon-component iconName="plusBg" @click="changeQty(i, 'plus')" />
								</div>
							</td>
							<td><div class="state"><span :class="`circle status status-${i.status}`"></span></div></td>
							<td>{{(selectedOrder.table) ? (i.seat ? i.seat : '1') : 'N/A'}}</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="modal-controls x2">
					<base-button text="CANCEL" classes="def-btn cancel-action-btn uppercase medium border-2" @onClick="close"/>
					<base-button text="split" :isDisabled="!areAnySelected.length || checkPayer" classes="def-btn main-action-btn uppercase medium border-2" @onClick="splitOrder"/>
				</div>
			</div>
		</modal-component>
		<new-order-modal-component
			v-if="showSetTableModal"
			ref="new-order-modal-component"
			:is-edit="true"
			:no-business-logic="true"
			@onTableSetup="onTableSetup"
		/>
		<users-modal
			v-if="usersModalRef"
			ref="usersModalRef"
			:new-order-creation="true"
			:selectedOrder="selectedOrder"
			@addPayer="onPayerSelect"
		/>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import NewOrderModalComponent from "@/components/_modals/NewOrderModal";
import UsersModal from "@/components/_modals/UsersModal";
import BaseButton from "@/components/_shared/Button.vue";
import iconComponent from "@/components/_shared/Icon.vue";

export default {
	name: "SplitOrderModal",
	props: {
		selectedOrder: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			newPayer: null,
			newTable: null,
			usersModalRef: false,
			showSetTableModal: false,
			items: [],
			validation: false
		}
	},
	emits: ['onModalClose', 'splitOrder'],
	components: {NewOrderModalComponent, ModalComponent, UsersModal, BaseButton, iconComponent},
	computed: {
		design() {
			return this.$store.state.UI
		},
		areAnySelected() {
			return this.selectedOrder.items.filter((o) => {return o.checked})
		},
		getPosData: function () {
			return this.$store.state.auth.posData
		},
		checkPayer() {
			if (this.getPosData.department.flow_ordering_guest && !this.newPayer) {
				return true;
			} else {
				return false;
			};
		},
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
	},
	beforeUnmount() {
		this.emmiter.emit('toggleModals', {opened: false, type: 'SplitOrderModal'});
		this.emmiter.off('closeModal');
	},
	methods: {
		// checkPayer() {
		// 	if (this.getPosData.department.flow_ordering_guest && !this.newPayer) {
		// 		return true;
		// 	} else {
		// 		return false;
		// 	};
		// },
		openModal() {
			this.$refs['modal'].show();
			this.selectedOrder.items.forEach((o) => {
				o['qty_to_split'] = 1;
			});
			this.items = JSON.parse(JSON.stringify(this.selectedOrder.items));
			this.emmiter.emit('toggleModals', {opened: true, type: 'SplitOrderModal'});
			/*if (this.selectedOrder.payers.length) {
				this.newPayer = this.selectedOrder.payers[0];
			}*/
		},
		openSetPayerModal() {
			this.usersModalRef = true;
			this.$nextTick(() => {
				this.$refs['usersModalRef'].openModal({
				    modalTitleText: 'Set the payer',
				    modalSubmitBtnText: 'OK'
			    });
				if (this.newPayer) {
					this.$refs['usersModalRef'].selectPerson(this.newPayer);
				}
			})
		},
		openSetTableModal() {
			this.showSetTableModal = true;
			this.$nextTick(() => {
				this.$refs['new-order-modal-component'].openModal(this.selectedOrder);
			})
		},
		close() {
			this.emmiter.emit('toggleModals', {opened: false, type: 'SplitOrderModal'});
			this.$refs['modal'].close();
		},
		onPayerSelect(data) {
			this.newPayer = data;
		},
		onTableSetup(data) {
			this.newTable = data;
		},
		changeQty(i, type) {
			if (type === 'minus') {
				if (i.qty_to_split > 1) {
					i.qty_to_split -= 1;
				} else {
					/*if (this.selectedOrder.items.length === 1) {
						this.$refs['selected-order-component'].openCancelOrderModal()
					}*/
				}
			} else {
				if (i.qty_to_split < i.count) {
					i.qty_to_split += 1;
				}
			}
		},
		splitOrder() {
			if ((this.areAnySelected.length && !this.getPosData.department.flow_ordering_guest) || (this.areAnySelected.length && this.getPosData.department.flow_ordering_guest && this.newPayer)) {
				//getPosData.department.flow_ordering_guest
				
				const objToSend = {
					selected: JSON.parse(JSON.stringify(this.areAnySelected)),
					newTable: this.newTable,
					newPayer: this.newPayer
				};
				this.$emit('splitOrder', objToSend);
				
				/*const itemsToSplit = JSON.parse(JSON.stringify(this.areAnySelected));
				itemsToSplit.forEach((o) => {
					o.count = o.qty_to_split
				});
				this.selectedOrder.items.forEach((o, index) => {
					itemsToSplit.forEach(oo => {
						if (oo.id === o.id) {
							if (o.count - o.qty_to_split === 0) {
								o.to_delete = true;
							} else {
								o.count -= o.qty_to_split
							}
						}
					})
				});
				
				this.selectedOrder.items = this.selectedOrder.items.filter((o, index, a2) => {if (!o.to_delete) {return o} })
				let orderSplitTo = {
					table: !this.newTable ? (this.selectedOrder.table ? this.selectedOrder.table : null) : (this.newTable ? this.newTable.table : null),
					is_split: true,
					payers: this.newPayer ? [this.newPayer] : [],
					seat: !this.newTable ? (this.selectedOrder.seat ? this.selectedOrder.seat : null) : (this.newTable ? this.newTable.seat : null),
					items: itemsToSplit
				};
				this.emmiter.emit('splitOrder', {
					update: this.selectedOrder,
					create: orderSplitTo
				});
				this.$emit('splitOrder', {
					update: this.selectedOrder,
					create: orderSplitTo
				})
				this.close();*/
			} else {
				this.validation = true;
			}
		},
	}
}
</script>

<style scoped lang="less">
#modal-wrapper {
	#split-order-modal {
		width: 500px;
		// background-color: #F5F9FC!important;
		.modal-subtitle {
			margin-bottom: 20px;
			color: #1C282D;
			font-size: 14px;
			font-weight: 600;
		}
		.payer-table-select-wrapper {
			margin-bottom: 20px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
			.payer-select, .table-select {
				position: relative;
				z-index: 2;
				border-radius: 4px;
				background: #ffffff;
				border: 1px solid lightgray;
				box-sizing: border-box;
				padding-left: 11px;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				width: auto;
				height: auto;
				line-height: 30px;
				padding-right: 20px;
				display: flex;
				span {
					font-size: 18px;
					color: #1c282d;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				
				&:after {
					position: absolute;
					content: "▾";
					color: black;
					right: 1em;
					width: 6px;
					height: 6px;
					top: 0;
				}
				&.not-specified {
					span {
						font-size: 10px;
					}
				}
			}
			.payer-select-label, .table-select-label {
				text-align: left;
				color: #1C282D;
				font-size: 14px;
				font-weight: 600;
			}
			.payer-select-wrapper {
				width: 75%;
				&.required {
					.payer-select {
						border-color: red;
					}
				}
			}
			.table-select-wrapper {
				width: 20%;
			}
		}
		.items-list-wrapper {
			width: 100%;
			background-color: #ffffff;
			margin-bottom: 40px;
			table {
				width: 100%;
				table-layout: fixed;
				margin: 0;
				border-collapse: collapse;
				box-sizing: border-box;
				position: relative;
				tbody {
					overflow-y: scroll;
					max-height: 250px;
					display: block;
				}
				thead {
					display: table;
					width: 100%;
					table-layout: fixed;
				}
				tr {
					display: table;
					width: 100%;
					table-layout: fixed;
					&:nth-child(odd) {
						background-color: #FAF9FB;
					}
					&.disabled {
						background: repeating-linear-gradient(45deg, #b1b1b1, #b3b3b3 10px, #afafaf 10px, #9f9f9f 20px);
						cursor: not-allowed;
						pointer-events: none;
					}
					th {
						border-top: 2px solid #e9ecef;
						border-bottom: 1px solid #e9ecef;
						vertical-align: middle;
						height: 30px;
						padding: 5px 10px;
						overflow-wrap: break-word;
						color: #14191f;
						font-size: 10px;
						text-align: center;
						text-transform: uppercase;
						&:nth-child(1) {
							width: 8%!important;
						}
						&:nth-child(2) {
							width: 42%!important;
							text-align: left;
						}
						&:nth-child(3) {
							width: 30%!important;
						}
						&:nth-child(4) {
							width: 10%!important;
						}
						&:nth-child(5) {
							width: 10%!important;
						}
					}
					td {
						vertical-align: middle;
						height: 30px;
						padding: 5px 10px;
						overflow-wrap: break-word;
						color: #1a141f;
						font-size: 14px;
						text-align: center;
						&:nth-child(1) {
							width: 8%!important;
						}
						&:nth-child(2) {
							width: 42%!important;
							text-align: left;
						}
						&:nth-child(3) {
							width: 30%!important;
						}
						&:nth-child(4) {
							width: 10%!important;
						}
						&:nth-child(5) {
							width: 10%!important;
						}
						.state {
							display: flex;
							align-items: center;
							justify-content: center;
							.status {
								background-color: red;
								&.status-1 {
									background-color: #EB262B;
								}
								&.status-2 {
									background-color: #FFCB05;
								}
								&.status-3 {
									background-color: #82B941;
								}
							}
							.circle {
								height: 12px;
								width: 12px;
								border-radius: 100%;
							}
						}
						.qty {
							display: flex;
							justify-content: center;
							align-items: center;
							img {
								cursor: pointer;
							}
							span {
								padding: 0 5px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
