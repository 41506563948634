<template>
	<span></span>
</template>

<script>
export default {
	name: 'BarcodeScanInterceptor',
	emits: ['loginViaBarcode', 'submitBarcode'],
	props: ['openedFromLogin'],
	components: {},
	data() {
		return {
			barcode: '',
			interval: null
		}
	},
	computed: {},
	created() {},
	mounted() {
		document.addEventListener('keydown', this.scan, true);
	},
	beforeUnmount() {
		document.removeEventListener('keydown', this.scan, true);
		this.interval = null;
		this.barcode;
		this.emmiter.off('loginViaBarcode');
	},
	methods: {
		stopListener() {
			document.removeEventListener('keydown', this.scan, true);
			this.interval = null;
			this.barcode;
			this.emmiter.off('loginViaBarcode');
		},
		scan(evt) {
			if (this.interval) {
				clearInterval(this.interval);
			}
			if (evt.code === 'Enter') {
				if (this.barcode) {
					this.handleBarcode(this.barcode);
				}
				this.barcode = '';
				return
			}
			if (evt.key !== 'Shift') {
				this.barcode += evt.key;
			}
			this.interval = setInterval(() => this.barcode = '', 50);
		},
		handleBarcode(scanned_barcode) {
			/*if (this.openedFromLogin) {
				this.emmiter.emit('loginViaBarcode', scanned_barcode);
			} else {
				this.$emit('submitBarcode', scanned_barcode);
			}*/
			this.$emit('submitBarcode', scanned_barcode);
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped></style>
